import { useMutation, useQueryClient } from "@tanstack/react-query";
import { queryKey } from "../utils/QueryKey";
import { EditHarassmentManagerMailAddressData, TenantSettingService } from "../services/TenantSettingService";
import { useApiTokenForBrowser } from "./browser/useApiTokenForBrowser";

export const useEditHarassmentManagerEmailAddressMutation = () => {
  const { token } = useApiTokenForBrowser();
  const tenantSettingService = new TenantSettingService(token)
  const queryClient = useQueryClient();
  return  useMutation(async (data: EditHarassmentManagerMailAddressData) => {
      return  tenantSettingService.editHarassmentManagerMailAddress(data);
    }, {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKey.harassmentManagerEmailAddress])
      }}
  );
}