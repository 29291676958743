import { useEffect, useState } from 'react';
import MeetingContainer from './MeetingContainer';
import { usePersonalMutation, usePersonal } from '../hooks/usePersonal';
import { useTeamsContext } from '../hooks/useTeamsContext';
import { css } from '@emotion/react';
import { Loading } from '../component/Loading';
import { useClientToken } from '../hooks/useClientToken';
import { useNavigate } from 'react-router';
import { useSubscription } from '../hooks/useSubscription';
import { useServiceContext, ServiceContext } from '../contexts/serviceContext';
import {
  DisplayNameModal,
  LOCAL_STORAGE_KEY,
} from '../component/DisplayNameModal';
import { generateUuid, DUMMY_GUID } from '../utils/Uuid';

const styles = {
  sidePanel: css({
    backgroundColor: '#1f1f1f',
    minHeight: '100vh',
  }),
  devText: css({
    color: 'ffffff !important',
  }),
};

export const USER_ROLE = {
  member: 'Member',
  guest: 'Guest',
  external: 'External',
  anonymous: 'Anonymous',
  initial: 'Initial',
};

export type fetchStatus = true | false | 'notExecute';

const SidePanel = () => {
  const [account, setAccount] = useState({
    localAccountId: '',
    name: '',
    userRole: USER_ROLE.initial,
  });
  const [roomId, setRoomId] = useState('');
  const [tenantId, setTenantId] = useState('');
  const [userPrincipalName, setUserPrincipalName] = useState('');
  const [subscriptionStatus, setSubscriptionStatus] =
    useState<fetchStatus>('notExecute');
  const [storedName, setStoredName] = useState('');
  // const { decodedToken } = useClientToken();
  const { personalData, personalError } = usePersonal(userPrincipalName);
  const { subscriptionData, subscriptionError, roomData } = useSubscription(
    tenantId,
    roomId
  );
  const navigate = useNavigate();
  const { context, teamsContextError } = useTeamsContext();
  const serviceContext = useServiceContext();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    const storedName = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (storedName) {
      setStoredName(storedName);
    }
  }, []);

  //contextに変更があったときに実行
  useEffect(() => {
    if (context?.user?.licenseType === 'Anonymous') {
      const _account = account;
      const uuid = generateUuid();
      _account.localAccountId = uuid;
      _account.userRole = USER_ROLE.anonymous;
      setAccount(_account);
      setSubscriptionStatus(false);
      setModalIsOpen(true);
    }

    setRoomId(context?.meeting?.id || '');
    setTenantId(context?.user?.tenant?.id || DUMMY_GUID);
    setUserPrincipalName(context?.user?.userPrincipalName || '');

    if (
      context?.user?.userPrincipalName !== null &&
      context?.user?.userPrincipalName !== undefined
    ) {
      const _account = account;

      if (_account.name === '') {
        _account.localAccountId = context?.user?.id || account.localAccountId;
        if (_account.userRole === USER_ROLE.initial) {
          _account.userRole = USER_ROLE.external;
        }
      }
      setAccount(_account);
    }
  }, [context as any]);

  useEffect(() => {
    console.log(teamsContextError);
  }, [teamsContextError]);

  useEffect(() => {
    if (personalData) {
      const _account = {
        localAccountId: personalData.id,
        name: personalData.displayName,
        userRole: personalData.userType,
      };
      setAccount(_account);
    }
  }, [personalData]);

  //getpersonalに失敗してるときの処理
  //サブスクリプション取得APIの呼び出しがキックされないのでここでsubscriptionStatusをfalseに変更してる
  useEffect(() => {
    const _account = account;
    if (_account.userRole === USER_ROLE.initial) {
      _account.localAccountId = context?.user?.id || '';
      _account.name = context?.user?.displayName || '';
      _account.userRole = USER_ROLE.external;
      setAccount(_account);
      if (_account.name === '' && context !== undefined) {
        setModalIsOpen(true);
        setSubscriptionStatus(false);
      }
    }
  }, [personalError]);

  useEffect(() => {
    if (serviceContext.servicesError === null) {
      return;
    }
    if (account.userRole === USER_ROLE.initial) {
      const _account = {
        localAccountId: context?.user?.id || '',
        name: context?.user?.userPrincipalName || '',
        userRole: USER_ROLE.external,
      };
      setAccount(_account);
      if (account.name === '') {
        setModalIsOpen(true);
      }
    } else if (
      account.userRole === USER_ROLE.anonymous ||
      account.userRole === USER_ROLE.external
    ) {
      if (account.name === '') {
        setModalIsOpen(true);
        setSubscriptionStatus(false);
      }
    }
  }, [serviceContext.servicesError]);

  //サブスクリプション取得APIの呼び出しが終わったときに発火する
  useEffect(() => {
    if (subscriptionData !== undefined) {
      setSubscriptionStatus(subscriptionData ? true : false);
    }
  }, [subscriptionData]);

  useEffect(() => {
    if (subscriptionError?.response.status === 401) {
      if (account.userRole === USER_ROLE.initial) {
        const _account = account;
        _account.userRole = USER_ROLE.external;
        setAccount(_account);
      }
    }
    if (subscriptionError?.response.status === 404) {
      //      navigate('/external-user');
      //TODO サブスクリプションが無効になってることをこのルームの参加者全員に伝えることができる仕組みを導入する
    }
  }, [subscriptionError]);

  useEffect(() => {
    if (roomData !== undefined) {
      const _account = account;
      _account.userRole = USER_ROLE.external;
      setAccount(_account);
    }
  }, [roomData]);

  const closeModal = () => {
    if (storedName) {
      setModalIsOpen(false);
    }
  };

  const handleSubmitModal = () => {
    const storedName = localStorage.getItem(LOCAL_STORAGE_KEY);
    const _account = account;
    if (storedName) {
      _account.name = storedName;
      setAccount(_account);
    }
    setModalIsOpen(false);
  };

  return (
    <div css={styles.sidePanel}>
      {!roomId ||
      subscriptionStatus === 'notExecute' ||
      !account.localAccountId ? (
        <Loading />
      ) : null}
      {roomId &&
        account.localAccountId &&
        account.name &&
        subscriptionStatus !== 'notExecute' && (
          <MeetingContainer
            roomId={roomId}
            tenantId={tenantId}
            account={account}
            isSidePanel={true}
            subscription={subscriptionStatus}
          />
        )}
      <DisplayNameModal
        isOpen={modalIsOpen}
        onClose={() => closeModal()}
        onSubmit={() => handleSubmitModal()}
      />
    </div>
  );
};

export default SidePanel;
