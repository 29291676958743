import React from 'react';
import { Col, Row } from "react-bootstrap";
import { JizoBalloon } from "./JizoBalloon";
import Modal from "react-modal";
import { BsXLg } from "react-icons/bs";
import { css, Theme, useTheme } from "@emotion/react";
import { TextButton } from "./TextButton";
import { useMediaQuery } from "@mui/material";

interface Props {
  isOpen?: boolean,
  onClose?: () => void,
}

const makeStyles = (theme: Theme) => ({
  root: css({
    marginTop:30
  }),
  helpModal: css({
    position: 'absolute',
    borderRadius: 15,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    overflow: 'scroll',
    width: '90%',
    height: '90%',
    backgroundColor: theme.colors.lightGray,
    padding: 50,
    transition: '800ms'
  }),
  closeModalButton: css({
    position: 'absolute',
    top: 10,
    right: 10,
    fontSize: 30,
  }),
  explanation: css({
    marginBottom: 30,
    justifyContent: 'center'
  }),
  balloon: css({
    width: '100%',
    maxWidth: 'unset',
    paddingRight: 40,
    paddingLeft: 40,
    paddingTop: 45,
    marginBottom: 40,
    '@media (min-width: 600px)': {
      marginBottom: 0,
    }
  }),
  balloonLeft: css({
    paddingTop: 30,
  }),
  imageAndBalloonWrapper: css({
    maxWidth: 450,
    minWidth: 200
  }),
  oddWrapper: css({
    flexDirection: 'column-reverse',

    '@media (min-width: 600px)': {
      flexDirection: 'row',
    }
  }),
  zizouWrapper: css({
    textAlign: 'center',
    position: 'relative'
  }),
  howtoImage: css({
    width: '100%',
  }),
  zizou: css({
    width: 100,
    height: 100,
    position: 'relative',
    zIndex: 2,
    marginLeft: 0,
    '@media (min-width: 600px)': {
      marginLeft: 30,
    }
  }),
  highlighted: css({
    color: theme.colors.primary,
    fontWeight: 'bold'
  }),
  rightBalloonRoot: css({
    marginTop: -40
  }),
  leftBalloonRoot: css({
    alignItems: 'center',
    height: '100%',
  })
})

export const HelpModal = ({ isOpen = false, onClose = () => {} }: Props) => {
  const theme = useTheme();
  const styles = makeStyles(theme);
  const isPhone = useMediaQuery('(max-width:600px');
  return (
    <Modal
      preventScroll={false}
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="ハラスメント通報"
      css={styles.helpModal}
      overlayClassName="modalOverlay">
      <div css={styles.root}>
        <TextButton onClick={onClose} css={styles.closeModalButton}>
          <BsXLg/>
        </TextButton>
        <Row css={[styles.explanation, styles.oddWrapper]}>
          <Col css={styles.imageAndBalloonWrapper}>
            <img css={styles.howtoImage} alt="howto1" src={`${process.env.PUBLIC_URL}/images/tab/howto_1.png`}/>
          </Col>
          <Col css={[styles.imageAndBalloonWrapper, styles.zizouWrapper]}>
            <img
              alt="zizou_1"
              src={`${process.env.PUBLIC_URL}/images/tab/zizou_1.png`}
              css={styles.zizou}
            />
            <JizoBalloon
              direction={isPhone ? 'down' : 'right'}
              message={<>
                <span css={styles.highlighted}>リアクションボタン</span>を押すと自分の地蔵が動きます！
              </>}
              isBalloonOnly={true}
              styles={{ balloon: styles.balloon, root: styles.rightBalloonRoot }}
            />
          </Col>
        </Row>
        <Row css={styles.explanation}>
          <Col css={[styles.imageAndBalloonWrapper, styles.zizouWrapper]}>
            <JizoBalloon
              direction={isPhone ? 'down' : 'left'}
              isBalloonOnly={true}
              styles={{ balloon: css([styles.balloon, styles.balloonLeft]), root: styles.leftBalloonRoot}}
              message={<>
                <span css={styles.highlighted}>スタンプボタン</span>を押すと対応する<span css={styles.highlighted}>スタンプ</span>が出ます。<br/>
                誰が押したかは分からないようになっています。<br/>
                偉い人に向けて遠慮せずに使えます。
              </>}
            />
          </Col>
          <Col css={styles.imageAndBalloonWrapper}>
            <img css={styles.howtoImage} alt="howto2" src={`${process.env.PUBLIC_URL}/images/tab/howto_2.png`}/>
          </Col>
        </Row>
        <Row css={[styles.explanation, styles.oddWrapper]}>
          <Col css={styles.imageAndBalloonWrapper}>
            <img css={styles.howtoImage} alt="howto3" src={`${process.env.PUBLIC_URL}/images/tab/howto_3.png`}/>
          </Col>
          <Col css={[styles.imageAndBalloonWrapper, styles.zizouWrapper]}>
            <img
              alt="zizou_2"
              src={`${process.env.PUBLIC_URL}/images/tab/zizou_2.png`}
              css={styles.zizou}
            />
            <JizoBalloon
              message={<>
                簡易投票で<span css={styles.highlighted}>アンケート</span>を取れるよ！<br/>
                「賛成の人は青・反対の人は
                赤・どちらでもない人は緑」<br/>
                のように使ってね！
              </>}
              isBalloonOnly={true}
              styles={{ balloon: styles.balloon, root: styles.rightBalloonRoot }}
              direction={isPhone ? 'down' : 'right'}
            />
          </Col>
        </Row>
        <Row css={styles.explanation}>
          <Col css={[styles.imageAndBalloonWrapper, styles.zizouWrapper]}>
            <JizoBalloon
              direction={isPhone ? 'down' : 'left'}
              isBalloonOnly={true}
              styles={{ balloon: css([styles.balloon, styles.balloonLeft]), root: styles.leftBalloonRoot}}
              message={<>
                会議に対する<span css={styles.highlighted}>フィードバック</span>を
                【匿名】で送ることが出来ます。<br/>
                ☆5段階のほかに自由記述も
                可能です。
              </>}
            />
          </Col>
          <Col css={styles.imageAndBalloonWrapper}>
            <img css={styles.howtoImage} alt="howto4" src={`${process.env.PUBLIC_URL}/images/tab/howto_4.png`}/>
          </Col>
        </Row>
        <Row css={[styles.explanation, styles.oddWrapper]}>
          <Col css={styles.imageAndBalloonWrapper}>
            <img css={styles.howtoImage} alt="howto5" src={`${process.env.PUBLIC_URL}/images/tab/howto_5.png`}/>
          </Col>
          <Col css={[styles.imageAndBalloonWrapper, styles.zizouWrapper]}>
            <img
              alt="zizou_3"
              src={`${process.env.PUBLIC_URL}/images/tab/zizou_3.png`}
              css={styles.zizou}
            />
            <JizoBalloon
              message={<>
                「ハラスメントを受けた」と
                感じたら、「<span css={styles.highlighted}>ハラスメント通報</span>」
                ボタンを押しましょう。<br/>
                自由記述で匿名で送信できます。
              </>}
              isBalloonOnly={true}
              direction={isPhone ? 'down' : 'right'}
              styles={{ balloon: styles.balloon, root: styles.rightBalloonRoot }}
            />
          </Col>
        </Row>
      </div>
    </Modal>
  )
};