import React from 'react';
import { css } from "@emotion/react";
import { BrowserLayout } from "../BrowserLayout";
import { PageTitle } from "../PageTitle";
import { TenantData } from "./TenantData";
import { Row } from "react-bootstrap";
import { ImageLink } from "./ImageLink";

const styles = {
  root: css({
    textAlign: "center",
    marginBottom: 60
  }),
  row: css({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: "center",
    columnGap: 20,
    rowGap: 20,
    marginTop: 50
  }),
  tenantData: css({
    marginBottom: 20
  }),
  button: css({
    margin: '10px 0'
  }),
  pageTitle: css({
    width: 1000,
  }),
  titleWrapper: css({
    display: 'flex',
    justifyContent: 'center',
  }),
  layout: css({
    maxWidth: '100%'
  }),
}

type Props = {
  isTenantAdministrator: boolean
}

export const TopPage = ({ isTenantAdministrator }: Props) => {
  return (
    <BrowserLayout classes={{content: styles.layout}}>
      <div css={styles.root}>
        <div css={styles.titleWrapper}>
          <PageTitle classes={{root: styles.pageTitle}}>
            {isTenantAdministrator ? "ワカチエ管理者様ページTop" : "ワカチエ担当者様ページTop"}
          </PageTitle>
        </div>
        <TenantData css={styles.tenantData}/>
        <Row css={styles.row}>
          <ImageLink
            to={"/subscribe"}
            css={styles.button}
            disabled={isTenantAdministrator}
            src={"/images/web/sub.png"}
            disabledSrc={"/images/web/sub_gray.png"}
            alt={"サブスクリプションの有効化"}
          >
            サブスクリプションの有効化
          </ImageLink>
          <ImageLink
            to={"/tenant-administrator-management-page"}
            css={styles.button}
            disabled={isTenantAdministrator}
            src={"/images/web/kanri.png"}
            disabledSrc={"/images/web/kanri_gray.png"}
            alt={"テナント管理者の登録・管理"}
          >
            テナント管理者の登録・管理
          </ImageLink>
          <ImageLink
            to={"/admin-consent"}
            disabled={!isTenantAdministrator}
            css={styles.button}
            src={"/images/web/access.png"}
            disabledSrc={"/images/web/access_gray.png"}
            alt={"アクセス権の設定"}
          >
            アクセス権の設定
          </ImageLink>
          <ImageLink
            to={"/representative-management-page"}
            css={styles.button}
            disabled={isTenantAdministrator}
            src={"/images/web/tantou.png"}
            disabledSrc={"/images/web/tantou_gray.png"}
            alt={"担当者追加・編集"}
          >
            担当者追加・編集
          </ImageLink>
          <ImageLink
            to={"/harassment-manager-email-address-management-page"}
            css={styles.button}
            disabled={isTenantAdministrator}
            src={"/images/web/mail.png"}
            disabledSrc={"/images/web/mail_gray.png"}
            alt={"ハラスメントメールアドレス"}
          >
            ハラスメントメール設定
          </ImageLink>
        </Row>
      </div>
    </BrowserLayout>
  )
};