import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { css, Theme, useTheme } from '@emotion/react';
import { AppButton } from './AppButton';
import { mergeStyles } from '../utils/style';

const ReactStarsRating = require('react-awesome-stars-rating').default;

const makeStyles = (theme: Theme) => ({
  ratingStarWrapper: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    p: {
      paddingTop: 10,
      fontWeight: 'bold',
      display: 'inline-block',
      marginTop: 'revert',
      color: theme.colors.darkGray,
    },
  }),
  ratingStars: css({
    display: 'block',
    margin: 10,
  }),
  feedbackFormWrapper: css({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    '& > div': css({
      marginTop: 16,
    }),
  }),
  feedbackInputWrapper: css({
    textAlign: 'right',
  }),
  roomNameLabel: css({
    marginBottom: 0,
    width: '100%',
  }),
  roomNameInput: css({
    height: 48,
    background: '#ffffff 0% 0% no-repeat padding-box;',
    border: '1px solid #afafaf',
    borderRadius: 6,
    opacity: 1,
    width: '100%',

    '@media (min-width: 600px)': css({
      width: 352,
    }),
  }),
  feedbackSendButton: css({
    width: 126,
    marginBottom: 0,
    marginLeft: 10,
  }),
});

export type FeedbackFormStyle = ReturnType<typeof makeStyles>;

interface Props {
  onFeedbackSubmit: (values: FeedbackFormValues) => Promise<void>;
  defaultValue?: Partial<FeedbackFormValues>;
  starSize?: number;
  styles?: Partial<FeedbackFormStyle>;
}

export type FeedbackFormValues = {
  feedbackText: string;
  ratingValue: number;
  selectedRatingValue: number;
};

interface State extends FeedbackFormValues {
  isEdit: boolean;
}

export const FeedbackForm = ({
  onFeedbackSubmit,
  defaultValue,
  starSize,
  styles,
}: Props) => {
  const [state, setState] = useState<State>({
    feedbackText: '',
    ratingValue: 1,
    selectedRatingValue: 1,
    isEdit: true,
  });
  const theme = useTheme();
  const mergedStyles = mergeStyles(makeStyles(theme), styles);

  useEffect(() => {
    setState({ ...state, ...defaultValue });
  }, [defaultValue?.feedbackText, defaultValue?.ratingValue]);

  const onRatingStarChange = (value: any) => {
    setState({
      ...state,
      ratingValue: value,
      isEdit: true,
      selectedRatingValue: value,
    });
  };

  return (
    <div>
      <div css={mergedStyles.ratingStarWrapper}>
        <p>悪い</p>
        <ReactStarsRating
          onChange={onRatingStarChange}
          isEdit={state.isEdit}
          value={state.ratingValue}
          selectedValue={state.selectedRatingValue}
          size={starSize ?? 25}
          isHalf={false}
          primaryColor="#F15A24"
          secondaryColor="#D8D8D8"
          css={mergedStyles.ratingStars}
        />
        <p>良い</p>
      </div>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          await onFeedbackSubmit(state);
        }}
      >
        <Row css={mergedStyles.feedbackFormWrapper}>
          <div css={mergedStyles.feedbackInputWrapper}>
            <label css={mergedStyles.roomNameLabel}>
              <input
                type="text"
                value={state.feedbackText}
                css={mergedStyles.roomNameInput}
                placeholder="その他ご意見がありましたらご記入ください(匿名)"
                onChange={(e) =>
                  setState({ ...state, feedbackText: e.target.value })
                }
              />
            </label>
          </div>
          <div>
            <AppButton
              color={'orange'}
              type={'submit'}
              css={mergedStyles.feedbackSendButton}
            >
              送信
            </AppButton>
          </div>
        </Row>
      </form>
    </div>
  );
};
