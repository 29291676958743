import React, { ReactNode } from 'react';
import { css } from "@emotion/react";

interface Props {
  children: ReactNode;
}

const styles = {
  root: css({
    textAlign: 'center',
    fontSize: 24,
    marginBottom: 45,
  })
}

export const PageDescription = ({ children }: Props) => {
  return (
    <div css={styles.root}>
      <p>{children}</p>
    </div>
  )
};