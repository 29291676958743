import { useMutation, useQueryClient } from "@tanstack/react-query";
import { queryKey } from "../utils/QueryKey";
import { CreateTenantAdministratorData, TenantAdministratorService } from "../services/TenantAdministratorService";
import { useApiTokenForBrowser } from "./browser/useApiTokenForBrowser";

export const useCreateTenantAdministratorMutation = () => {
  const { token } = useApiTokenForBrowser()
  const tenantAdministratorService = new TenantAdministratorService(token)
  const queryClient = useQueryClient();
  return  useMutation(async (data: CreateTenantAdministratorData) => {
      return  tenantAdministratorService.createTenantAdministrator(data);
    }, {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKey.tenantAdministrator])
      }},
  );
}