import React, { ReactNode } from 'react';
import { Link } from "react-router-dom";
import { css } from "@emotion/react";
import { SerializedStyles } from "@emotion/serialize";
import { ButtonProps } from "react-bootstrap";

interface Props extends ButtonProps {
  children: ReactNode;
  to?: string;
  css?: SerializedStyles;
  src: string;
  alt: string;
  disabledSrc?: string;
  disabled?: boolean;
}

const styles = {
  disabledImg: css({
    cursor: "not-allowed",
  })
}

export const ImageLink = ({ to, src, alt, disabledSrc, disabled, css }: Props) => {
  return (
    <Link to={disabled ? "#!" : to ?? "#!"} css={css}>
      {disabled && disabledSrc ? (
        <img src={disabledSrc} alt={alt} css={styles.disabledImg} />
      ) : (
        <img src={src} alt={alt} />
      )}
    </Link>
  );
};
