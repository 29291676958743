import { ApiService } from "./ApiService";

export type EditHarassmentManagerMailAddressData = {
  mailAddress: string[]
}

export type GetAllHarassmentManagerMailAddressData = {
  mailAddress: string[]
}

export class TenantSettingService extends ApiService {
  public fetchAllHarassmentManagerMailAddress(): Promise<GetAllHarassmentManagerMailAddressData> {
    const endpoint = `TenantSetting/GetHarassmentManagerMailAddress`;
    return this.get(endpoint);
  }

  public editHarassmentManagerMailAddress(data: EditHarassmentManagerMailAddressData): Promise<any> {
    const endpoint = 'TenantSetting/UpdateHarassmentManagerMailAddress';
    return this.post(endpoint, data);
  }
}
