import { useGraphTokenWithMsal } from "./useGraphTokenWithMsal";
import { useClientForBrowser } from "./useClientForBrowser";
import { useEffect, useState } from "react";
import { GraphService } from "../services/graphService";
import { useQuery } from "@tanstack/react-query";
import { queryKey } from "../utils/QueryKey";

export const useTenantIdForBrowser = (): [ string | undefined ] => {
  const token = useGraphTokenWithMsal();
  const [client] = useClientForBrowser(token);
  const [graphService, setGraphService] = useState<GraphService>()

  useEffect(() => {
      if(!client){
        return
      }
        setGraphService(new GraphService(client))
  }, [client])

  const { data } = useQuery(
    [queryKey.tenantId],
    async () => {
      const res = await graphService?.getTenantId()
      console.log(res)
      return res
    },
    {enabled: graphService != null}
  )

  return [ data ]
}