import { useMyFeedback } from './useMyFeedback';
import { useAllHarassment } from './useAllHarassment';
import { useMyStarRatings } from './useMyStarRatings';
import { useAverageRating } from './useAverageRating';
import { parseISO } from 'date-fns';
import { useAllFeedbacks } from './useAllFeedbacks';

export const useContentPage = (roomId: string) => {
  const allFeedback = useAllFeedbacks(roomId);
  const myFeedback = useMyFeedback(roomId);
  const allHarassment = useAllHarassment(roomId);
  const myStarRatings = useMyStarRatings(roomId);
  const averageRating = useAverageRating(roomId);
  const includedDate: Date[] = allFeedback?.data?.map((feedback: any) =>
    parseISO(feedback.creatDate)
  );

  return {
    allFeedback,
    myFeedback,
    allHarassment,
    averageRating,
    myStarRating: myStarRatings?.data?.[0],
    includedDate,
  };
};
