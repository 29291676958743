import React from 'react';
import { PageTitle } from "../component/PageTitle";
import { BrowserLayout } from "../component/BrowserLayout";
import { css } from "@emotion/react";

const styles = {
  root: css({
    textAlign: 'center'
  }),
  message: css({
    fontSize: 20
  })
}

export const AccessDenied = ({}) => {
  return (
    <BrowserLayout>
      <div css={styles.root}>
        <PageTitle withBackground={false}>
          アクセス権がありません
        </PageTitle>
        <p css={styles.message}>担当の方にご確認ください</p>
      </div>
    </BrowserLayout>
  )
};