import React, { ReactNode } from 'react';
import { WindowContextProvider } from '../contexts/WindowContext';
import { ServiceContextProvider } from "../contexts/serviceContext";
import { ThemeProvider } from "@emotion/react";
import { themeDark } from "../theme/theme";
import { msalConfig } from "../authConfig";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from '@azure/msal-react';
import { createTheme, ThemeProvider as MuiThemeProvider } from "@mui/material";

interface providerProps {
  children: ReactNode;
}

const queryClient = new QueryClient()

const pca = new PublicClientApplication(msalConfig);
const muiTheme = createTheme();

const Providers = (props: providerProps) => {
  return (
    <WindowContextProvider>
      <MsalProvider instance={pca}>
        <QueryClientProvider client={queryClient}>
          <ServiceContextProvider>
            <ThemeProvider theme={themeDark}>
              <MuiThemeProvider theme={muiTheme}>
                {props.children}
              </MuiThemeProvider>
            </ThemeProvider>
          </ServiceContextProvider>
        </QueryClientProvider>
      </MsalProvider>
    </WindowContextProvider>)
};

export default Providers;
