import { css } from "@emotion/react";
import { SerializedStyles } from "@emotion/serialize";

type Style = {
  [key: string]: SerializedStyles
}

export const mergeStyles = <T extends Style, U extends T>(defaultStyle: T , customStyle: Partial<U> | undefined): T => {
  if(customStyle == undefined) {
    return defaultStyle;
  }
  const merged: any = {};
  (Object.keys(defaultStyle)).map((k: keyof T) => merged[k] = css([defaultStyle[k], customStyle[k]]));
  return merged;
}