import { useCallback, useEffect, useState } from 'react';
import { Client } from '@microsoft/microsoft-graph-client';
import { useWindowContext } from '../contexts/WindowContext';
import {
  getApiToken,
  getCachedApiToken,
  getCachedGraphToken,
  getClientToken,
  getGraphToken,
  setCachedApiToken,
  setCachedGraphToken,
} from '../utils/TokenManager';

export const useClient = (): [Client | undefined, any] => {
  const [client, setClient] = useState<Client>();
  const [token, setToken] = useState(null);
  const [error, setError] = useState(null);
  const _window = useWindowContext();

  const getAccessToken = useCallback(async () => {
    try {
      let token = getCachedGraphToken();
      let apiToken = getCachedApiToken();
      if (!_window) {
        console.log('_window is undefined');
        return;
      }
      if (!token) {
        const clientToken = await getClientToken();
        const serverToken: any = await getGraphToken(clientToken).catch(
          (error) => {
            throw error;
          }
        );
        console.log('get graphToken success!');
        setCachedGraphToken(serverToken);
        token = serverToken;
      }
      if (!apiToken) {
        const clientToken = await getClientToken().catch((error) => {
          throw error;
        });
        const apiToken: any = await getApiToken(clientToken).catch((error) => {
          throw error;
        });
        console.log('get apiToken success!');
        setCachedApiToken(apiToken);
      }

      return token;
    } catch (error: any) {
      console.log(error.message);
      setError(error);
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (!_window) {
        console.log('_window is undefined');
        return;
      }
      try {
        await getAccessToken();
        setClient(
          Client.initWithMiddleware({
            authProvider: {
              getAccessToken: getAccessToken as any,
            },
          })
        );
        setToken(getAccessToken as any);
      } catch (error) {
        const message: any =
          error instanceof Error
            ? error.message
            : Object.prototype.toString.call(error);
        setError(message);
      }
    })();
  }, [getAccessToken]);

  return [client, error];
};
