import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FeedbackService, SendFeedbackData } from "../services/feedbackService";
import { queryKey } from "../utils/QueryKey";

export const useMyFeedbackMutation = (roomId: string) => {
  const feedbackService = new FeedbackService({
    roomId,
  })
  const queryClient = useQueryClient();
  return  useMutation(async (data: SendFeedbackData) => {
    return  feedbackService.sendFeedback(data);
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKey.myFeedback])
      queryClient.invalidateQueries([queryKey.feedbacks])
    }}
  );
}
