import React, { createContext, ReactNode, useContext, useState, } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import * as microsoftTeams from '@microsoft/teams-js';
import { useAsync, useUpdateEffect } from "react-use";

interface ContextInterface {
  readonly window: string;
  readonly colorMode: ColorMode;
}

type ColorMode = 'default' | 'dark' | 'contrast' | undefined;

interface windowContextProps {
  children: ReactNode;
}

const WindowContext = createContext<ContextInterface | undefined>(undefined);

export const useWindowContext = () => {
  const value = useContext(WindowContext);
  return value;
};

export const WindowContextProvider = (props: windowContextProps) => {
  const navigate = useNavigate();
  const [window, setWindow] = useState('unsupported');
  const [colorMode, setColorMode] = useState<ColorMode>(undefined);
  const location = useLocation();

  useAsync(async () => {
    const getTeamsContext = async () => {
      const _context = await microsoftTeams.app.getContext();
      if (!_context) {
        setWindow('unsupported');
        // navigate('/unsupported');
      } else {
        setColorMode(_context.app.theme as ColorMode);
        let frameContext = _context.page.frameContext || '';
        if (frameContext === 'content') {
          setWindow('content');
          navigate('/content', { replace: true });
        } else if (frameContext === 'sidePanel') {
          setWindow('sidepanel');
          navigate('/sidepanel', { replace: true });
        } else if (frameContext === 'settings') {
          setWindow('settings');
          navigate('/configuration');
        }
      }
    };
    await getTeamsContext();
  }, []);

  useUpdateEffect(() => {
    if (window === 'loading') {
      navigate('/');
    } else if (window === 'unsupported') {
      const paths = [
        'configuration',
        'access-request',
        'admin-consent',
        'consent',
        'access-denied',
        'admin-page',
        'representative-page',
        'representative-management-page',
        'tenant-administrator-management-page',
        'harassment-manager-email-address-management-page',
        'initial-setting',
        'initialize-completed',
        'subscribe'
      ];

      if (!paths.some(p => location.pathname.includes(p))) {
        navigate('/unsupported');
      }
    }
  }, [window]);

  const value = { window, colorMode };
  return (
    <WindowContext.Provider value={value}>
      {props.children}
    </WindowContext.Provider>
  );
};
