import { css, Theme, useTheme } from '@emotion/react';
import { ContactButton } from '../component/ContactButton';

const makeStyles = (theme: Theme) => ({
  loadingContainer: css({
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: theme.colors.loadingBackground,
    textAlign: 'center',
    h3: {
      color: theme.colors.white,
    },
    h4: {
      color: theme.colors.gray,
    },
  }),
});

export const Loading = () => {
  const theme = useTheme();
  const styles = makeStyles(theme);
  return (
    <div css={styles.loadingContainer}>
      <div>
        <img
          src={process.env.PUBLIC_URL + 'images/loadings/dance_black.gif'}
          alt="ロード中"
        />
      </div>
      <br />
      <div>
        <h3>読み込み中......</h3>
      </div>
      <br />
      <br />
      <div>
        <h4>
          この画面から切り替わらないときは、以下のリンクからお問い合わせください。
        </h4>
        <ContactButton />
      </div>
    </div>
  );
};
