import { useQuery } from "@tanstack/react-query";
import { FeedbackService } from "../services/feedbackService";
import { useClient } from "./useClient";
import { queryKey } from "../utils/QueryKey";

export const useAllStarRatings = (roomId: string) => {
  const feedbackService = new FeedbackService({
    roomId
  });

  const client = useClient()
  const { data, error } = useQuery(
    [queryKey.allStarRating],
    async () => feedbackService.fetchAllStarRatings(),
    { enabled: roomId != undefined && roomId != "" && client != undefined }
  )
  return { data, error };
}