import React from 'react';
import { Container } from 'react-bootstrap';
import EmoteArea from './EmoteArea';
import StampArea from './StampArea';
import { Account, VoteCountElement } from '../container/MeetingContainer';
import { css } from "@emotion/react";

interface Props {
  currentVotes: VoteCountElement[];
  account: Account;
  roomId: string;
  ownStampId: number;
  isCooling: boolean;
  sendVote: (value: string) => void;
  sendReaction: (value: number) => void;
  sendStamp: (value: number) => void;
}

const styles = {
  controlArea: css({
    position: 'relative',
    paddingLeft: '0 !important',
    paddingRight: '0 !important',
  })
}

class ControlPanel extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      radioWrapper: null,
      resetRemind: false,
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.currentVotes != this.props.currentVotes) {
      if (
        this.props.currentVotes.filter((vote) => vote.count === 0).length ==
        this.props.currentVotes.length
      ) {
        this.setState({ radioWrapper: null });
      }
    }
  }

  render() {
    return (
      <div>
        <Container fluid css={styles.controlArea}>
          <EmoteArea sendReaction={(i) => this.sendReaction(i)} />
          <StampArea
            sendStamp={(o) => this.sendStamp(o)}
            isCooling={this.props.isCooling}
          />
        </Container>
      </div>
    );
  }

  sendReaction(reactionNumber: any) {
    this.props.sendReaction(reactionNumber);
  }

  sendStamp(stampId: number) {
    if (this.props.ownStampId === stampId) {
      this.props.sendStamp(0);
    } else {
      this.props.sendStamp(stampId);
    }
  }
}

export default ControlPanel;
