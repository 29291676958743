import React, { useState } from 'react';
import { BrowserLayout } from "../component/BrowserLayout";
import { PageTitle } from "../component/PageTitle";
import { PageDescription } from "../component/web/PageDescription";
import { User, UserManagementTable } from "../component/web/UserManagementTable";
import { UserManagementModal } from "../component/web/UserManagementModal";
import { useAllRepresentatives } from "../hooks/useAllRepresentatives";
import { useCreateRepresentativeMutation } from "../hooks/useCreateRepresentativeMutation";
import { useEditRepresentativeMutation } from "../hooks/useEditRepresentativeMutation";
import { useDeleteRepresentativeMutation } from "../hooks/useDeleteRepresentativeMutation";
import { Row } from "react-bootstrap";
import { css } from "@emotion/react";
import { useGraphApiToken } from "../hooks/browser/useGraphApiToken";
import { ManagementPageTextButton } from "../component/web/ManagementPageTextButton";

interface State {
  selectedUserId?: number;
  modalState: 'close' | 'edit' | 'create';
}

const styles = {
  root: css({
    textAlign: 'center'
  }),
  button: css({
    width: 300,
    margin: '0 20px'
  }),
  tableWrapper: css({
    justifyContent: 'center',
    marginBottom: 30
  }),
  buttonWrapper: css({
    display: 'flex',
    justifyContent: 'flex-start',
    gap: 60,
    marginBottom: 10
  })
}

export const RepresentativeManagementPage = ({}) => {
  const [state, setState] = useState<State>({
    modalState: 'close'
  })

  const { data } = useAllRepresentatives();
  const createRepresentative = useCreateRepresentativeMutation();
  const editRepresentative = useEditRepresentativeMutation();
  const deleteRepresentative = useDeleteRepresentativeMutation();
  const { token } = useGraphApiToken();

  const handleSelectUser = (user: User) => {
    setState( { ...state, selectedUserId: user.id })
  }

  const handleShowEditModal = () => {
    setState({ ...state, modalState: 'edit'})
  }

  const handleShowCreateModal = () => {
    setState({ ...state, modalState: 'create'})
  }

  const handleCloseModal = () => {
    setState({ ...state, modalState: 'close'})
  }

  const handleCreate = async (user: User) => {
    await createRepresentative.mutateAsync({ ...user, token });
    handleCloseModal();
  }

  const handleEdit = async (user: User) => {
    await editRepresentative.mutateAsync(user)
    handleCloseModal();
  }

  const handleDelete = async () => {
    if(state.selectedUserId === undefined){
      return
    }
    await deleteRepresentative.mutateAsync(state.selectedUserId);
  }

  return (
    <BrowserLayout css={styles.root}>
      <PageTitle>
        担当者登録・編集ページ
      </PageTitle>
      <PageDescription>ワカチエの管理ページにアクセス可能なユーザーの管理を行うページです</PageDescription>
      <div css={styles.buttonWrapper}>
        <ManagementPageTextButton
          onClick={handleShowCreateModal}
        >
          担当者を追加する
        </ManagementPageTextButton>
        <ManagementPageTextButton
          color="blue"
          onClick={handleShowEditModal}
          disabled={state.selectedUserId === undefined}
        >
          担当者を編集する
        </ManagementPageTextButton>
        <ManagementPageTextButton
          color="red"
          onClick={handleDelete}
          disabled={state.selectedUserId === undefined}
        >
          担当者を削除する
        </ManagementPageTextButton>
      </div>
      <Row css={styles.tableWrapper}>
        <UserManagementTable
          users={data}
          onClickRadio={handleSelectUser}
          selectedUserId={state.selectedUserId}
        />
      </Row>
      <UserManagementModal
        onSubmit={handleCreate}
        onClose={handleCloseModal}
        show={state.modalState === 'create'}
      />
      <UserManagementModal
        onSubmit={handleEdit}
        onClose={handleCloseModal}
        show={state.modalState === 'edit'}
        initialValues={data?.find((u: any) => u.id === state.selectedUserId)}
      />
    </BrowserLayout>
  )
};