import * as React from 'react';
import { RefObject } from 'react';
import * as signalR from '@microsoft/signalr';
import { Col, Container, Row } from 'react-bootstrap';
import * as _ from 'underscore';
import ControlPanel from '../component/ControlPanel';
import AnimationManager from '../component/AnimationManager';
import { StampManager } from '../component/StampManager';
import Stamps from '../data/Stamps.json';
import { ReviewModal } from '../component/ReviewModal';
import { VoteModal } from '../component/VoteModal';
import { SidePanelHarassmentModal } from '../component/SidePanelHarassmentModal';
import { css } from '@emotion/react';
import { Questionnaire } from '../component/VoteTitleForm';
import { Loading } from '../component/Loading';
import { USER_ROLE } from './SidePanel';

export type Account = {
  name: string;
  localAccountId: string;
  userRole: string;
};

export type Audience = {
  displayName: string;
  audienceId: string;
  clientId: string;
};

interface Props {
  roomId: string;
  tenantId: string;
  account: Account;
  isSidePanel: Boolean;
  meetingTitle?: string | null | undefined;
  subscription: Boolean;
}

type ModalType = undefined | 'review' | 'vote' | 'report';

interface State {
  meetingName: string;
  currentAudience: Audience[];
  connectedUsers: Audience[];
  currentModal: ModalType;
  ownStampId: number;
  currentVotes: VoteCountElement[];
  isCooling: boolean;
  currentQuestionnaire: Questionnaire;
  meetingRoomIsOpen: boolean;
}

const styles = {
  controlPanelButtonWrapper: css({
    paddingTop: 15,
    marginBottom: 124,
  }),
  controlPanelWrapper: css({
    paddingLeft: 0,
    paddingRight: 0,
  }),
  myRow: css({
    display: 'flex',
    flexWrap: 'wrap',
    marginRight: 0,
    marginLeft: 0,
  }),
  controlPanelButton: css({
    cursor: 'pointer',
  }),
  myCol: css({
    paddingLeft: 0,
    paddingRight: 0,
  }),
  iconDisable: css({
    filter: 'brightness(30%)',
  }),
};

class MeetingContainer extends React.Component<Props, State> {
  private readonly roomId: string;
  private readonly tenantId: string;
  private readonly animationRef: RefObject<any>;
  private readonly stampRef: RefObject<any>;
  private connectionUrl: string;
  private HubConnection;
  private stamps;
  private axiosService: any;

  constructor(props: Props) {
    super(props);

    this.connectionUrl =
      (process as any).env.REACT_APP_SIGNALR_SERVER_URL +
      process.env.REACT_APP_SIGNALR_HUB_NAME +
      '?roomid=';

    this.roomId = this.props.roomId;
    const tenantIdQuery = '&tenantid=';
    this.tenantId = this.props.tenantId;
    this.HubConnection = new signalR.HubConnectionBuilder()
      .withUrl(
        this.connectionUrl + this.props.roomId + tenantIdQuery + this.tenantId,
        {
          skipNegotiation: true,
          transport: signalR.HttpTransportType.WebSockets,
        }
      )
      .withAutomaticReconnect()
      .build();

    this.animationRef = React.createRef();
    this.stampRef = React.createRef();
    this.state = {
      currentAudience: [] as Audience[],
      connectedUsers: [] as Audience[],
      meetingName: 'meeting',
      currentVotes: DefaultVoteCount,
      ownStampId: 0,
      isCooling: false,
      currentModal: undefined,
      currentQuestionnaire: {
        id: 0,
        title: '',
      },
      meetingRoomIsOpen: false,
    };

    this.stamps = Stamps.stamps;
    this.axiosService = null;
  }

  componentDidMount() {
    this.hubInitialize();
  }

  closeModal() {
    this.setState({ ...this.state, currentModal: undefined });
  }

  openModal(modalType: ModalType) {
    if (
      this.props.account.userRole === USER_ROLE.member ||
      this.props.account.userRole === USER_ROLE.guest
    ) {
      this.setState({ ...this.state, currentModal: modalType });
    } else {
      if (modalType === 'vote') {
        this.setState({ ...this.state, currentModal: modalType });
      }
    }
  }

  render() {
    return (
      <div>
        {!this.state.meetingRoomIsOpen && <Loading />}
        {this.props.account && this.state.meetingRoomIsOpen && (
          <Container>
            <Row css={styles.myRow}>
              <Col md={8} css={styles.myCol}>
                <AnimationManager
                  animationWindows={this.state.currentAudience.length}
                  currentAudience={this.state.currentAudience}
                  isSidePanel={this.props.isSidePanel}
                  ref={this.animationRef as any}
                />
                <StampManager
                  account={this.props.account}
                  ownStampId={this.state.ownStampId}
                  eraseOwnStamp={() => this.eraseOwnStamp()}
                  ref={this.stampRef as any}
                />
              </Col>
              <Col md={4} css={styles.controlPanelWrapper}>
                <ControlPanel
                  sendReaction={(i) => this.sendReaction(i)}
                  sendVote={(VoteColor) => this.sendVote(VoteColor)}
                  sendStamp={(stampId) => this.sendStamp(stampId)}
                  account={this.props.account}
                  currentVotes={this.state.currentVotes}
                  ownStampId={this.state.ownStampId}
                  isCooling={this.state.isCooling}
                  roomId={this.roomId}
                />
              </Col>
              <Col md={8} css={styles.controlPanelButtonWrapper}>
                <Row className="justify-content-around">
                  <div
                    css={styles.controlPanelButton}
                    onClick={() => this.openModal('review')}
                  >
                    <img
                      alt="評価"
                      src={`${process.env.PUBLIC_URL}/images/meetingContainer/review.png`}
                      css={
                        this.props.account.userRole === USER_ROLE.member ||
                        this.props.account.userRole === USER_ROLE.guest
                          ? ''
                          : styles.iconDisable
                      }
                    />
                  </div>
                  <div
                    css={styles.controlPanelButton}
                    onClick={() => this.openModal('vote')}
                  >
                    <img
                      alt="投票"
                      src={`${process.env.PUBLIC_URL}/images/meetingContainer/vote.png`}
                    />
                  </div>
                  <div
                    css={styles.controlPanelButton}
                    onClick={() => this.openModal('report')}
                  >
                    <img
                      alt="通報"
                      src={`${process.env.PUBLIC_URL}/images/meetingContainer/report.png`}
                      css={
                        this.props.account.userRole === USER_ROLE.member ||
                        this.props.account.userRole === USER_ROLE.guest
                          ? ''
                          : styles.iconDisable
                      }
                    />
                  </div>
                </Row>
              </Col>
            </Row>
            <ReviewModal
              isOpen={this.state.currentModal == 'review'}
              onClose={() => this.closeModal()}
              roomId={this.roomId}
              localAccountId={this.props.account?.localAccountId}
            />
            <VoteModal
              isOpen={this.state.currentModal == 'vote'}
              currentVotes={this.state.currentVotes}
              currentQuestionnaire={this.state.currentQuestionnaire}
              onClose={() => this.closeModal()}
              onClickVoteButton={(VoteColor) => this.sendVote(VoteColor)}
              onClickResetButton={() => this.sendReset()}
              onSubmitVoteTitle={async (values) =>
                this.sendQuestionnaireTitle(values)
              }
            />
            <SidePanelHarassmentModal
              isOpen={this.state.currentModal == 'report'}
              localAccountId={this.props.account?.localAccountId}
              roomId={this.roomId}
              onClose={() => this.closeModal()}
            />
          </Container>
        )}
      </div>
    );
  }

  hubMethods() {
    this.HubConnection.on('onConnected', (clientId) => {
      console.log('onconnect function @container');
      const _connectedUsers = this.state.connectedUsers.slice();
      const _currentAudience = this.state.currentAudience.slice();

      _connectedUsers.unshift({
        displayName: this.props.account.name,
        audienceId: this.props.account.localAccountId,
        clientId: clientId,
      });

      if (
        !_currentAudience.some(
          (a: Audience) => a.audienceId === this.props.account.localAccountId
        )
      ) {
        _currentAudience.unshift({
          displayName: this.props.account.name,
          audienceId: this.props.account.localAccountId,
          clientId: clientId,
        });
      }

      this.setState({
        connectedUsers: _connectedUsers,
        currentAudience: _currentAudience,
      });
      this.sendProfile();
    });

    this.HubConnection.on('HandleDisconnect', (clientId) => {
      console.log('goodby ' + clientId);
      const _connectedUsers = this.state.connectedUsers.slice();

      if (_connectedUsers.some((user) => user.clientId === clientId)) {
        const filteredUser = _connectedUsers.filter(
          (user) => user.clientId !== clientId
        );

        const _currentAudience = filteredUser.filter(
          (user, i, users) =>
            users.findIndex((u) => u.audienceId === user.audienceId) === i
        );

        this.setState({
          connectedUsers: filteredUser,
          currentAudience: _currentAudience,
        });
      }
    });

    this.HubConnection.on(
      'addReactionItem',
      (reactionId, clientId, accountId) => {
        console.log('nankakita');
        this.reactionHandler(reactionId, clientId, accountId);
      }
    );

    this.HubConnection.on(
      'addRoomMember',
      (displayName, audienceId, clientId) => {
        console.log('join ' + displayName);
        const _currentAudience = this.state.currentAudience.slice();
        const _connectedUsers = this.state.connectedUsers.slice();

        if (
          !_connectedUsers.some((user: Audience) => user.clientId === clientId)
        ) {
          _connectedUsers.push({
            displayName: displayName,
            audienceId: audienceId,
            clientId: clientId,
          });
        }

        if (
          !_currentAudience.some((a: Audience) => a.audienceId === audienceId)
        ) {
          _currentAudience.push({
            displayName: displayName,
            audienceId: audienceId,
            clientId: clientId,
          });
        }
        try {
          if (_currentAudience.length > 0) {
            this.syncProfile();
            this.setState({
              currentAudience: _currentAudience,
              connectedUsers: _connectedUsers,
            });
          }
        } catch (e) {
          return;
        }
      }
    );

    this.HubConnection.on('syncRoomMember', (displayName, userId, clientId) => {
      console.log('syncRoomMember');
      this.setState((prevState) => {
        const _connectedUsers = prevState.connectedUsers.slice();

        if (!_connectedUsers.some((user) => user.clientId === clientId)) {
          _connectedUsers.push({
            displayName: displayName,
            audienceId: userId,
            clientId: clientId,
          });
        }

        const _currentAudience = _connectedUsers.filter(
          (user, i, users) =>
            users.findIndex((u) => u.audienceId === user.audienceId) === i
        );

        return {
          connectedUsers: _connectedUsers,
          currentAudience: _currentAudience,
        };
      });
    });

    this.HubConnection.on('voteResult', (VoteCount) => {
      console.log('on voteResult');

      const newVotes = this.state.currentVotes.map((votes) => ({
        ...votes,
      }));
      VoteCount.forEach((v: any) => {
        console.log(v.count);
        newVotes.filter((nv: any) => nv.color === v.color)[0].count = v.count;
      });

      this.setState({ currentVotes: newVotes });
    });

    this.HubConnection.on('voteReset', (questionnaireId) => {
      console.log('on reset vote');
      this.setState({
        currentVotes: DefaultVoteCount,
        currentQuestionnaire: {
          id: questionnaireId,
          title: '',
        },
      });
    });

    this.HubConnection.on('syncQuestionnaire', (questionnaire) => {
      console.log('on initialQuestionnaireId');
      console.log('questionnaireId');
      console.log(questionnaire.id);
      this.HubConnection.send('OnSyncVotes', questionnaire.id);
      this.setState({
        currentQuestionnaire: questionnaire,
      });
    });

    this.HubConnection.on('setStamp', (displayName, userId, stampId) => {
      console.log('on setStamp method');
      if (userId !== this.props.account.localAccountId) {
        this.stampHandler(stampId, displayName, userId);
        return;
      }
      if (this.state.ownStampId !== stampId) {
        this.setState({
          ownStampId: stampId,
          isCooling: true,
        });
      } else {
        this.setState({
          ownStampId: 0,
        });
      }
    });

    this.HubConnection.on('sendMeetingName', () => {
      console.log('sendMeetingTitle');
      if (this.props.meetingTitle == undefined) {
        return;
      }
      this.HubConnection.send('OnReceiveMeetingName', this.props.meetingTitle);
    });

    this.HubConnection.on('activateRoom', () => {
      console.log('activateRoom');
      this.setState({ meetingRoomIsOpen: true });
    });

    this.HubConnection.onclose(() => {
      this.HubConnection.stop();
    });
  }

  reactionHandler(reactionId: string, clientId: string, accountId: string) {
    const senders = this.state.connectedUsers.filter(
      (user) => user.audienceId === accountId
    );

    const senderIds = _.pluck(senders, 'clientId');

    if (this.props.account.localAccountId == accountId) {
      (this.animationRef as any).current.ownReactionStarter(
        parseInt(reactionId)
      );
      return;
    }

    (this.animationRef as any).current.reactionStarter(
      parseInt(reactionId),
      senderIds
    );
  }

  stampHandler(stampId: number, displayName: string, userId: string) {
    console.log('stampHandler @meetingcontainer' + stampId + ' ' + displayName);
    (this.stampRef as any).current.stampReceiveHandler(
      stampId,
      displayName,
      userId
    );
  }

  sendReaction(reactionNumber: number) {
    console.log('on send reaction');
    //kick own icon
    (this.animationRef as any).current.ownReactionStarter(reactionNumber);
    this.HubConnection.send(
      'sendReactionAsync',
      this.props.account.localAccountId,
      String(reactionNumber),
      this.props.account.userRole
    );
  }

  sendVote(VoteColor: string) {
    console.log('on send vote');
    console.log(VoteColor);
    this.HubConnection.send(
      'OnSendVoteAsync',
      this.state.currentQuestionnaire.id,
      this.props.account.localAccountId,
      VoteColor,
      this.props.account.userRole
    );
  }

  sendReset() {
    console.log('on send reset');
    this.HubConnection.send('OnResetVote', this.props.account.localAccountId);
  }

  sendProfile() {
    console.log('on send profile');
    this.HubConnection.send(
      'OnReceiveProfile',
      this.props.account.name,
      this.props.account.localAccountId,
      this.props.account.userRole,
      this.props.subscription
    );
  }

  sendStamp(stampId: number) {
    console.log('on send stamp');
    this.setState({
      isCooling: true,
    });
    this.HubConnection.send(
      'OnSendStamp',
      this.props.account.localAccountId,
      stampId,
      this.props.account.userRole
    );
  }

  sendQuestionnaireTitle(questionnaire: Questionnaire) {
    this.HubConnection.send(
      'OnReceiveEventQuestionnaireTitle',
      questionnaire.title
    );
  }

  syncProfile() {
    console.log('on sync profile');
    this.HubConnection.send(
      'OnSyncProfile',
      this.props.account.name,
      this.props.account.localAccountId
    );
  }

  hubInitialize() {
    if (this.HubConnection.state === 'Disconnected') {
      this.HubConnection.start().catch((err) => {
        console.log('connection error', err);
        alert('connection Fail');
      });
      this.hubMethods();
    }
  }

  eraseOwnStamp() {
    this.setState({
      ownStampId: 0,
      isCooling: false,
    });
  }
}

export type VoteCountElement = {
  color: 'blue' | 'red' | 'green' | 'yellow';
  count: number;
  style: 'BGBlue' | 'BGRed' | 'BGGreen' | 'BGYellow';
};

const DefaultVoteCount: VoteCountElement[] = [
  {
    color: 'blue',
    count: 0,
    style: 'BGBlue',
  },
  {
    color: 'red',
    count: 0,
    style: 'BGRed',
  },
  {
    color: 'green',
    count: 0,
    style: 'BGGreen',
  },
  {
    color: 'yellow',
    count: 0,
    style: 'BGYellow',
  },
];

export default MeetingContainer;
