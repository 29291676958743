import React, { ReactNode } from 'react';
import { css } from "@emotion/react";

interface Props {
  children?: ReactNode;
  onClick?: () => void | Promise<void>;
  className?: string;
};

const styles = {
  root: css({
    border: '1px solid transparent',
    padding: '0.375rem 0.75rem',
    backgroundColor: 'unset',
    '&:not(:disabled)': {
      cursor: 'pointer',
    }
  })
}

export const TextButton = ({onClick, children, className}: Props) => {
  return (
    <button onClick={onClick} css={styles.root} className={className}>
      {children}
    </button>
  )
};