import React from 'react';
import { css, Theme, useTheme } from '@emotion/react';
import { ContactButton } from './ContactButton';

const makeStyles = (theme: Theme) => ({
  root: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: theme.colors.backgroundColor,
    textAlign: 'center',
    height: '100vh',
    '@media (max-width: 400px)': css({
      backgroundColor: theme.colors.sidePanelBackgroundColor,
    }),
    img: css({
      '@media (max-width: 400px)': css({
        width: 200,
      }),
    }),
  }),
  caption: css({
    marginTop: '1rem',
    marginBottom: '1rem',
    color: theme.colors.blueGray,
    fontWeight: 'bold',
    '@media (max-width: 400px)': css({
      fontSize: 22,
    }),
  }),
  description: css({
    color: theme.colors.white,
    '@media (max-width: 400px)': css({
      fontSize: 13,
    }),
  }),
});

export const ExternalUserPage = ({}) => {
  const theme = useTheme();
  const styles = makeStyles(theme);

  return (
    <div css={styles.root}>
      <div>
        <img
          alt="zizou"
          src={`${process.env.PUBLIC_URL}/images/unsupported/zizou.png`}
        />
      </div>
      <h3 css={styles.caption}>
        ゲストユーザーのため
        <br />
        ワカチエを利用することができません。
      </h3>
      <p css={styles.description}>
        大変申し訳ありません。
        <br />
        ゲストユーザーの方もワカチエを
        <br />
        お楽しみいただけるよう現在開発中です。
        <br />
        <br />
        サービスについてのお問い合わせはこちらからからお願いします。
        <br />
        <ContactButton />
      </p>
    </div>
  );
};
