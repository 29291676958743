import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Account } from "../container/MeetingContainer";
import { css } from "@emotion/react";
import { FadeInFadeOutStamp } from "./FadeInFadeOutStamp";

interface Props {
  ownStampId: number;
  eraseOwnStamp: () => void;
  account: Account;
}

type Stamp = {
  displayName: string;
  stampId: number;
}

const styles = {
  stampArea: css({
    position: 'absolute',
    textAlign: 'center',
    width: '100%',
    left: '50%',
    top: 90,
    transform: 'translateX(-50%)',
  }),
  stampLine: css({
    display: 'inline-flex',
    position: 'relative'
  })
}
export const StampManager  = forwardRef((props: Props, ref) => {
  const [stampList, setStampList] = useState<Stamp[]>([]);
  const MeetingEl = useRef<HTMLDivElement>(null);
  const stampLimit = 3;
  const defaultWidth = 240;

  const calculateStampScale = () => {
    let stampScale = 1;

    if (
      MeetingEl.current &&
      MeetingEl.current.clientWidth < defaultWidth * stampLimit
    ) {
      stampScale =
        (MeetingEl.current.clientWidth - 16) /
        (defaultWidth * stampLimit);
    }
    return stampScale
  }

  const calculateStampList = () => {
    const _stampList: {stampId: number, displayName?: string, isOwn?: boolean, visible?: boolean}[] =
      stampList.slice().map(s =>
        ({
          stampId: s.stampId,
          displayName: s.displayName
        })
      );

    if (props.ownStampId !== 0) {
      _stampList.push({
        stampId: props.ownStampId,
        isOwn: true
      });
    }

    const visible = _stampList.slice(-stampLimit).map(
      s => ({
        ...s,
        visible: true
      })
    )

    _stampList.splice(-stampLimit, stampLimit, ...visible)

    return _stampList;
  }

  const stampReceiveHandler = (stampId: any, displayName: string, userId: string) => {
    console.log('handle receive @ stampmanager ' + stampId + ' ' + displayName);
    if (props.account.localAccountId !== userId) {
      if (stampId !== 0) {
        setStampList((state)=> [...state, {displayName: displayName, stampId: parseInt(stampId)}]);
      }
    }
  }

  useImperativeHandle(ref, () => ({
    stampReceiveHandler
  }))


  const stampErase = (displayName: string) => {
    setStampList((state) => {
      return state.filter((s) => s.displayName !== displayName);
    });
  }

  return (
    <div css={styles.stampArea} ref={MeetingEl}>
      {stampList.length > 0 || props.ownStampId !== 0 ? (
        <div css={styles.stampLine}>{
          calculateStampList().map((s) =>
            (
              <FadeInFadeOutStamp
                key={s.displayName || '' + s.stampId}
                stampId={s.stampId}
                stampScale={calculateStampScale()}
                isOwnStamp={s.isOwn || false}
                displayTime={5000}
                visible={s.visible}
                onFadeOutComplete={() => {
                  if (s.isOwn) {
                    props.eraseOwnStamp();
                  } else {
                    stampErase(s.displayName || '');
                  }
                }}
              />
            )
          )
        }</div>
      ) : (
        <></>
      )}
    </div>
  );
})
