import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { Outlet } from "react-router";
import React from "react";
import LoginAnnounce from "../LoginAnnounce";
import { BrowserLayout } from "../BrowserLayout";

export const LoginLayout = () => {
  return (
    <>
      <AuthenticatedTemplate>
        <Outlet />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <BrowserLayout>
          <LoginAnnounce usePopup={true} />
        </BrowserLayout>
      </UnauthenticatedTemplate>
    </>
  )
}