import { ApiService } from "./ApiService";

export type CreateTenantAdministratorData = {
  displayName: string;
  email: string;
  token: string;
}

export type EditTenantAdministratorData = {
  id: number
  displayName: string;
  email: string;
}

export type FetchMeData = {
  id: number;
  displayName: string;
  email: string;
}

export type CreateTenantAdministratorWithConfirmationData = {
  displayName: string;
  email: string;
}
export class TenantAdministratorService extends ApiService {
  public fetchAllTenantAdministrator(): Promise<any> {
    const endpoint = `TenantAdministrator`;
    return this.get(endpoint);
  }

  public createTenantAdministrator(data: CreateTenantAdministratorData): Promise<any> {
    const endpoint = 'TenantAdministrator/'
    return this.post(endpoint, data);
  }

  public editTenantAdministrator(data: EditTenantAdministratorData): Promise<any> {
    const endpoint = 'TenantAdministrator/'+ data.id;
    return this.put(endpoint, data);
  }

  public deleteTenantAdministrator(id: number): Promise<any> {
    const endpoint = 'TenantAdministrator/'+ id;
    return this.delete(endpoint);
  }

  public fetchMe(): Promise<FetchMeData> {
    const endpoint = 'TenantAdministrator/me';
    return this.get(endpoint);
  }

  public createTenantAdministratorWithConfirmation(data: CreateTenantAdministratorWithConfirmationData): Promise<any> {
    const endpoint = 'TenantAdministrator/createWithConfirmation'
    return this.post(endpoint, data);
  }

  public confirm(token: string): Promise<any> {
    const endpoint = 'TenantAdministrator/confirm/?confirmationToken=' + token;
    return this.get(endpoint);
  }
}
