import React, { useState } from 'react';
import { BrowserLayout } from "../component/BrowserLayout";
import { PageTitle } from "../component/PageTitle";
import { PageDescription } from "../component/web/PageDescription";
import { Row } from "react-bootstrap";
import { css } from "@emotion/react";
import { EmailManagementModal, FormValue } from "../component/web/EmailManagementModal";
import { useAllHarassmentManagerEmailAddress } from "../hooks/useAllHarassmentManagerEmailAddress";
import { HarassmentManagerManagementTable } from "../component/web/HarassmentManagerManagementTable";
import { useEditHarassmentManagerEmailAddressMutation } from "../hooks/useEditHarassmentManagerEmailAddressMutation";
import { ManagementPageTextButton } from "../component/web/ManagementPageTextButton";

interface State {
  selectedEmailAddress?: string;
  modalState: 'close' | 'edit' | 'create';
}

const styles = {
  root: css({
    textAlign: 'center'
  }),
  button: css({
    width: 300,
    fontSize: 20,
    margin: '0 20px'
  }),
  tableWrapper: css({
    justifyContent: 'center',
    marginBottom: 30
  }),
  buttonWrapper: css({
    display: 'flex',
    justifyContent: 'flex-start',
    gap: 60,
    marginBottom: 10
  })
}

export const HarassmentManagerMailAddressPage = ({}) => {
  const [state, setState] = useState<State>({
    modalState: 'close'
  })

  const { data } = useAllHarassmentManagerEmailAddress();
  const editHarassmentManagerEmailAddress = useEditHarassmentManagerEmailAddressMutation();

  const handleSelectEmailAddress = (value: string) => {
    setState({ ...state, selectedEmailAddress: value })
  }

  const handleShowEditModal = () => {
    setState({ ...state, modalState: 'edit' })
  }

  const handleShowCreateModal = () => {
    setState({ ...state, modalState: 'create' })
  }

  const handleCloseModal = () => {
    setState({ ...state, modalState: 'close' })
  }

  const handleCreate = async (value: FormValue) => {
    const address = [...(data?.mailAddress ?? [])];
    if(value.email == ''){
      return
    }
    address.push(value.email)
    editHarassmentManagerEmailAddress.mutate({
      mailAddress: address
    })
    handleCloseModal()
  }

  const handleEdit = async (value: FormValue) => {
    let addresses = [...(data?.mailAddress ?? [])]

    if(value.email === state.selectedEmailAddress){
      return
    }
    addresses = addresses.filter((a) => a !== state.selectedEmailAddress)
    addresses.push(value.email)
    addresses = addresses.filter((a) => a !== '');
    await editHarassmentManagerEmailAddress.mutateAsync({
      mailAddress: addresses
    })
    setState({ ...state, selectedEmailAddress: value.email, modalState: 'close' })
  }

  const handleDelete = async () => {
    let addresses = [...(data?.mailAddress ?? [])]
    addresses = addresses.filter((a) => a !== state.selectedEmailAddress)
    editHarassmentManagerEmailAddress.mutate({
      mailAddress: addresses
    })
    setState({ ...state, selectedEmailAddress: '', modalState: 'close' })
  }

  return (
    <BrowserLayout css={styles.root}>
      <PageTitle>
        ハラスメントメール送信先管理ページ
      </PageTitle>
      <PageDescription>ハラスメント通報機能に送られたメッセージを以下のメールアドレスに送信します</PageDescription>
      <div css={styles.buttonWrapper}>
        <ManagementPageTextButton
          onClick={handleShowCreateModal}
        >
          メールアドレスを追加する
        </ManagementPageTextButton>
        <ManagementPageTextButton
          color="blue"
          onClick={handleShowEditModal}
          disabled={state.selectedEmailAddress === undefined || state.selectedEmailAddress === ''}
        >
          メールアドレスを編集する
        </ManagementPageTextButton>
        <ManagementPageTextButton
          color="red"
          onClick={handleDelete}
          disabled={state.selectedEmailAddress === undefined || state.selectedEmailAddress === ''}
        >
          メールアドレスを削除する
        </ManagementPageTextButton>
      </div>
      <Row css={styles.tableWrapper}>
        <HarassmentManagerManagementTable
          onClickRadio={handleSelectEmailAddress}
          mailAddresses={data?.mailAddress}
          selectedEmailAddress={state.selectedEmailAddress ?? ''}
        />
      </Row>
      <EmailManagementModal
        onSubmit={handleCreate}
        onClose={handleCloseModal}
        show={state.modalState === 'create'}
      />
      <EmailManagementModal
        onSubmit={handleEdit}
        onClose={handleCloseModal}
        show={state.modalState === 'edit'}
        initialValues={{
          email: state.selectedEmailAddress ?? ''
        }}
      />
    </BrowserLayout>
  )
};