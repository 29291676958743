import React from 'react';
import { Container, Row } from "react-bootstrap";
import { css, Theme, useTheme } from "@emotion/react";
import { BrowserLayout } from "./BrowserLayout";
import { SubmitButton } from "./web/SubmitButton";
import { PageTitle } from "./PageTitle";
import { useSearchParams } from "react-router-dom";
import { useApiTokenForBrowser } from "../hooks/browser/useApiTokenForBrowser";
import { useAsync } from "react-use";
import { useConfirmTenantAdministrator } from "../hooks/useConfirmTenantAdministrator";

const makeStyles = (theme: Theme) => ( {
    root: css({
      textAlign: 'center',
      fontSize: 20
    }),
    consentButton: css({
      marginBottom: 60,
      marginTop: 30
    }),
    label: css({
      width: 150,
      textAlign: 'right',
      paddingRight: 0,
      marginRight: 10,
    }),
    redText: css({
      color: 'red',
    }),
    value: css({
      width: 400,
      textAlign: 'left'
    }),
    tableWrapper: css({
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    }),
    table: css({
      border: '1px solid #707070',
      padding: 20,
      margin: '50px 0',
      width: 850,
      'p': css({
        marginBottom: 5,
      }),
      backgroundColor: theme.colors.white,
    }),
    originalText: css({
      minWidth: 250,
      textAlign: 'right',
      marginRight: 10,
    }),
    description: css({
      minWidth: 500,
      textAlign: 'left'
    }),
    tableRow: css({
      justifyContent: 'center',
    }),
    explanation: css({
      backgroundColor: theme.colors.lightGray,
      width: '100%',
      padding: '50px 0',
    }),
    imageRow: css({
      justifyContent: 'center',
      display: 'flex',
      alignItems: 'center',
      margin: '50px 0',
    }),
    consentImage: css({
      padding: '0 40px',
    }),
  }
)

export const AdminConsent = ({}) => {
  const styles = makeStyles(useTheme());
  const [ searchParams] = useSearchParams();
  const confirmationToken = searchParams.get('confirmationToken');
  const { mutateAsync} = useConfirmTenantAdministrator();
  const { token: apiToken } = useApiTokenForBrowser();

  useAsync(async () => {
    if(apiToken == undefined || confirmationToken == undefined){
      return
    };
    await mutateAsync(confirmationToken).catch((e) => {
      if(e.response?.data?.detail){
        alert(e.response?.data?.detail);
      } else {
        alert("エラーが発生しました");
        console.log(e)
      }
    })
  }, [apiToken])


  return (
    <BrowserLayout>
      <div css={styles.root}>
        <Container>
          <PageTitle>アクセス権の設定</PageTitle>
        </Container>
        <div>
          <p>以下の「アクセス権設定」ボタンを押し、アクセス権をご設定ください。</p>
          <SubmitButton css={styles.consentButton} onClick={handleConsentButtonOnClick}>アクセス権設定</SubmitButton>
        </div>
        <div css={styles.explanation}>
          <div>
            <p>以下のように、アクセス許可ダイアログが<span css={styles.redText}>2回</span>表示されます。<br/>2回とも「承諾」をクリックしてください</p>
          </div>
          <Row css={styles.imageRow}>
            <img
              src={`${process.env.PUBLIC_URL}/images/web/consent.png`}
              alt="アクセス許可ダイアログ1"
              css={styles.consentImage}
            />
            <img
              src={`${process.env.PUBLIC_URL}/images/web/consent.png`}
              alt="アクセス許可ダイアログ1"
              css={styles.consentImage}
            />
          </Row>
          <div css={styles.tableWrapper}>
            ワカチエでは、以下のアクセス許可が必要です。
            <div css={styles.table}>
              <Row css={styles.tableRow}>
                <div css={styles.originalText}>
                  <p>View users' basic profile</p>
                </div>
                <div css={styles.description}>
                  <p>基本的なプロフィール情報(お名前等)の表示に使用します。</p>
                </div>
              </Row>
              <Row css={styles.tableRow}>
                <div css={styles.originalText}>
                  <p>Sign in and read user profile</p>
                </div>
                <div css={styles.description}>
                  <p>詳細プロフィール情報の表示に使用します。</p>
                </div>
              </Row>
              <Row css={styles.tableRow}>
                <div css={styles.originalText}>
                  <p>Sign useris in</p>
                </div>
                <div css={styles.description}>
                  <p>サインインに使用します。</p>
                </div>
              </Row>
            </div>
            <p>ワカチエで表示する情報の取得にアクセス許可が必要です。<br/>ワカチエではお客様のAzure AD上のデータを上書きすることはありません。</p>
          </div>
        </div>
      </div>
    </BrowserLayout>
  )
};

const handleConsentButtonOnClick = () => {
  const _ApiClientId = process.env.REACT_APP_API_CLIENT_ID;
  const _ApiRedirectUri = process.env.REACT_APP_API_CONSENT_URL;
  const _ApiScope = "https://graph.microsoft.com/.default";
  const _RedirectUri = process.env.REACT_APP_HOST_URL + "/consent";

  const adminConsentUrl =  "https://login.microsoftonline.com/organizations/v2.0/adminconsent?client_id=" + _ApiClientId
    + "&redirect_uri=" + _ApiRedirectUri
    + "&state=" + _RedirectUri + "&scope=" + _ApiScope;

  window.location.href = adminConsentUrl;
}
