import * as React from 'react';
import { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { useTeamsContext } from '../hooks/useTeamsContext';
import { useContentPage } from '../hooks/useContentPage';
import { useSubscription } from '../hooks/useSubscription';
import { Col, Row } from 'react-bootstrap';
import { BsExclamationTriangleFill } from 'react-icons/bs';
import { HelpModal } from '../component/HelpModal';
import { css, Theme, useTheme } from '@emotion/react';
import { Loading } from '../component/Loading';
import { useMediaQuery } from '@mui/material';
import { format } from "date-fns";
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Opinion } from "../component/Opinion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons/faCalendarDays";
import { ContentFeedbackForm } from "../component/ContentFeedbackForm";
import { Feedback } from "../services/feedbackService";
import { useCreateStarRatingMutation } from "../hooks/useCreateStarRatingMutation";
import Animation from "../component/Animation";
import TeamTab from "./TeamTab";
import { toast, Toaster } from "react-hot-toast";


const ReactStarsRating = require('react-awesome-stars-rating').default;

const makeStyles = (theme: Theme) => ({
  root: css({
    backgroundColor: theme.colors.backgroundColor,
    minHeight: '100vh',
    paddingBottom: 230,
    '@media (min-width: 600px)': css({
      paddingBottom: 90,
    }),
  }),
  footer: css({
    position: 'fixed',
    width: '100%',
    left: 0,
    bottom: 0,
    textAlign: 'center',
    backgroundColor: theme.colors.darkGray,
    paddingTop: 17,
    paddingBottom: 17,
    fontSize: 12,
    p: {
      color: theme.colors.gray,
      margin: 0,
    },
  }),
  howToButton: css({
    position: 'fixed',
    left: 0,
    bottom: 60,
    margin: 10,
    width: 140,
    cursor: 'pointer',
  }),
  goToFeedbackButtonWrapper: css({
    minWidth: 161,
    padding: 0,
  }),
  goToFeedbackButton: css({
    fontSize: 17,
    color: theme.colors.white,
    padding: 0,
    svg: {
      fontSize: 40,
    },
  }),
  announce: css({
    paddingRight: 20,
    paddingLeft: 20,
    fontWeight: 'bold',
    paddingTop: 30,
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    img: {
      height: 90,
    },
  }),
  jizouAndBalloonWrapper: css({
    textAlign: 'center',
  }),
  balloonRight: css({
    marginLeft: 30,
    width: 'calc(100% - 120px)',
    maxWidth: 500,

    position: 'relative',
    display: 'inline-block',
    padding: '25px 30px',
    color: 'black',
    fontSize: 18,
    background: 'white',
    borderRadius: 5,

    p: {
      margin: 0,
      padding: 0,
      textAlign: 'left',
    },

    '&:before': {
      content: "''",
      left: -42,
      border: '18px solid transparent',
      borderRight: '30px solid white',
      position: 'absolute',
      top: '50%',
      marginTop: -17,
    },
  }),
  ratingWrapper: css({
    width: 'calc(100% - 74px)',
    color: theme.colors.white,
    paddingBottom: 20,
    marginTop: 50,
    marginLeft: 37,
    marginRight: 37,
    borderRadius: 15,
    background: theme.colors.darkGray,
    textAlign: 'center',
  }),
  ratingMessage: css({
    paddingTop: 20,
    textAlign: 'center',
    color: theme.colors.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  feedbackContentWrapper: css({
    marginBottom: 50,
    alignItems: 'center',
    justifyContent: 'center',
    margin: 0,
    flexDirection: 'column',
  }),
  goToFeedbackResultButtonWrapper: css({
    textAlign: 'right',
  }),
  goToFeedbackResultButton: css({
    marginTop: 50,
    color: 'white',
    fontSize: 25,
    svg: {
      marginBottom: 5,
    },
  }),
  harassmentWrapper: css({
    marginTop: 30,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 20,
    width: '70%',
    margin: '20px auto',
    p: {
      color: theme.colors.white,
      textAlign: 'center',
    },
  }),
  reportHarassment: {
    width: 270,
  },
  ratingSummaryWrapper: css({
    marginBottom: 50,
    justifyContent: 'center',
    h3: {
      fontWeight: 500,
      fontSize: 22,
    },
  }),
  ratingStarsWrapper: css({
    marginTop: 30,
    marginBottom: 30,
    minWidth: 350,
    '@media (min-width: 600px)': css({
      marginBottom: 0,
    }),
  }),
  otherOpinionWrapper: css({
    justifyContent: 'center',
    backgroundColor: theme.colors.gray,
    borderRadius: 15,
    padding: 20,
  }),
  harassmentText: css({
    fontSize: 20,
    color: 'red',
    fontWeight: 'bold',
    paddingLeft: 40,
    marginBottom: 0,
    marginRight: 10,
    textAlign: 'center',
    svg: {
      fontSize: 25,
      marginRight: 10,
    },
  }),
  starRating: css({
    cursor: 'default',
  }),
  opinionList: css({
    margin: '40px 0',
    padding: '0 40px',
    maxHeight: 300,
    overflowY: 'auto',
  }),
  opinion: css({
    margin: '30px 0',
  }),
  opinionDate: css({
    color: theme.colors.darkGray,
    margin: 24,
    fontSize: 16,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    '&::before, &::after': {
      content: "''",
      backgroundColor: theme.colors.darkGray,
      height: 1,
      width: 70,
    },
    '&::before': {
      marginRight: 15,
    },
    '&::after': {
      marginLeft: 15,
    },
  }),
  divider: css({
    borderColor: theme.colors.white,
    borderBottom: 1,
    width: 'calc(100% - 40px)',
  }),
  datePickerText: css({
    '&:hover': {
      cursor: 'pointer',
    },
    fontSize: 20,
    margin: '0 16px',
    '& > svg': {
      marginLeft: 10,
    },
  }),
  arrow: css({
    height: 34,
    width: 30,
  }),
  calendarIcon: css({
    marginRight: 10,
  }),
  noFeedback: css({
    p: {
      marginTop:20,
      fontSize: 20,
      color: theme.colors.darkGray,
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      '&::before, &::after': {
        content: "''",
        backgroundColor: theme.colors.darkGray,
        height: 1,
        width: 70,
      },
      '&::before': {
        marginRight: 15,
      },
      '&::after': {
        marginLeft: 15,
      },
    }
  })
});

const DatePickerText = forwardRef(({ value, onClick, className }: any, ref) => (
  <p onClick={onClick} className={className}>
    { value }
    <FontAwesomeIcon icon={faCalendarDays}/>
  </p>
));

const ContentContainer = () => {
  const [state, setState] = useState({
    roomId: '',
    tenantId: '',
    isHarassmentModalOpened: false,
    isHelpModalOpened: false,
    hasSubscription: true,
    currentDate: new Date(),
  });
  const [isInitialFetchFeedbackComplete, setIsInitialFetchFeedbackComplete] =
    useState(false);
  const { context, teamsContextError } = useTeamsContext();
  const {
    allFeedback,
    allHarassment ,
    averageRating,
    myStarRating,
    includedDate
  } = useContentPage(
    state.roomId
  );
  const { mutate, isLoading, isSuccess, isError } = useCreateStarRatingMutation(state.roomId);

  const feedbackRefs = useRef<{
    [key in string]: HTMLLIElement;
  }>({});
  const listRef = useRef<HTMLDivElement>(null);

  const groupedFeedback = useMemo(() =>
      allFeedback?.data?.reduce((acc: any, feedback: Feedback) => {
        const dateKey = format(new Date(feedback.creatDate), 'yyyy年MM月dd日');
        if (!acc[dateKey]) {
          acc[dateKey] = {
            feedbacks: [feedback],
            refCallback:  (node: HTMLLIElement | null) => {
              if (node !== null && feedbackRefs.current[dateKey] === undefined) {
                feedbackRefs.current[dateKey] = node;
              } else {
                delete feedbackRefs.current[dateKey]
              }
            },
          };
        } else {
          acc[dateKey].feedbacks.push(feedback);
        }
        return acc;
      }, {})
    , [allFeedback?.data])

  const { subscriptionData, subscriptionError } = useSubscription(
    state.tenantId,
    state.roomId
  );
  let navigate = useNavigate();

  const isPhone = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    setState({
      ...state,
      roomId: context?.meeting?.id || '',
      tenantId: context?.user?.tenant?.id || '',
    });
  }, [context]);

  useEffect(() => {
    if (allFeedback.data) {
      setIsInitialFetchFeedbackComplete(true);
    }
  }, [allFeedback]);

  useEffect(() => {
    if ((!subscriptionData as Boolean) && subscriptionData !== undefined) {
      setState({ ...state, hasSubscription: false });
      navigate('/unsubscribe');
    }
  }, [subscriptionData]);

  useEffect(() => {
    if (subscriptionError?.response.status === 401) {
      navigate('/external-user');
    }
    if (subscriptionError?.response.status === 404) {
      navigate('/external-user');
    }
  }, [subscriptionError]);

  const theme = useTheme();
  const styles = makeStyles(theme);

  const successToast = () => toast.success('保存されました。')
  const errorToast = () => toast.success('保存に失敗しました。')

  useEffect(() => {
    if(isSuccess){
      successToast();
    }
  }, [isSuccess])

  useEffect(() => {
    if(isError){
      errorToast();
    }
  }, [isError])

  const changeCurrentDate = (date: Date) => {
    setState({ ...state, currentDate: date });
  };

  const scrollToFeedback = (date: Date) => {
    const feedbackRef = feedbackRefs.current[format(date, 'yyyy年MM月dd日')];
    const offsetTop = feedbackRef?.offsetTop || 0;
    if(listRef?.current){
      listRef.current.scrollTop = offsetTop;
    }
  }

  const handleClickArrow = (searchFuture: boolean) => {
    const nextDate = includedDate.filter(date =>
      searchFuture ? date > state.currentDate : date < state.currentDate
    ).reduce((prev, current) =>
      Math.abs(current.getTime() - state.currentDate.getTime() ) < Math.abs(prev.getTime() - state.currentDate.getTime() ) ? current : prev
    );
    changeCurrentDate(nextDate);
    scrollToFeedback(nextDate)
  }

  const handlePickDate = (date: Date) => {
    changeCurrentDate(date);
    scrollToFeedback(date)
  }

  const handleChangeStar = (value: number) => {
    if(isLoading)
      return;

    mutate({
      starRating: value,
      roomId: state.roomId,
      id: myStarRating?.id
    });
  }

  return (
    <div>
      {!isInitialFetchFeedbackComplete ? (
        <Loading />
      ) : (
        <div css={styles.root}>
          { state.roomId ? (<Row
            css={[
              styles.announce,
              css({ justifyContent: 'center' }),
            ]}
          >
            <Col md={8} sm={9} css={styles.jizouAndBalloonWrapper}>
              <div>
                <img
                  alt="喜び"
                  src={`${process.env.PUBLIC_URL}/images/tab/zizou_1.png`}
                />
                <div css={styles.balloonRight}>
                  <p>会議の評価をお知らせするよ!</p>
                </div>
              </div>
            </Col>
            <Col md={1} sm={0} />
          </Row>) : <></>}
          { state.roomId ? (
            <>
              <div css={styles.ratingWrapper}>
                <Row css={styles.feedbackContentWrapper}>
                  <Col md={12} css={styles.ratingMessage}>
                    <AiFillCaretLeft css={styles.arrow} onClick={() => handleClickArrow(false)}/>
                    <DatePicker
                      onChange={handlePickDate}
                      selected={state.currentDate}
                      dateFormat="yyyy年MM月dd日"
                      includeDates={includedDate}
                      customInput={<DatePickerText css={styles.datePickerText}/>}
                    />
                    <AiFillCaretRight css={styles.arrow} onClick={() => handleClickArrow(true)}/>
                  </Col>
                  <hr css={styles.divider}/>
                  <div css={styles.ratingWrapper}>
                    <Row css={styles.ratingSummaryWrapper}>
                      <Col md={6}>
                        <h3>みなさんの評価(平均)</h3>
                        <div css={styles.ratingStarsWrapper}>
                          <ReactStarsRating
                            id="everyoneRating"
                            isEdit={false}
                            value={averageRating}
                            selectedValue={averageRating}
                            size={isPhone ? 50 : 65}
                            isHalf={true}
                            primaryColor="#FF5E00"
                            secondaryColor="#D8D8D8"
                            css={styles.starRating}
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <h3>あなたの評価</h3>
                        <div css={styles.ratingStarsWrapper}>
                          <ReactStarsRating
                            id="myRating"
                            isEdit={true}
                            value={myStarRating?.starRating}
                            selectedValue={myStarRating?.starRating}
                            onChange={handleChangeStar}
                            size={isPhone ? 50 : 70}
                            isHalf={false}
                            primaryColor="#FF5E00"
                            secondaryColor="#D8D8D8"
                            css={styles.starRating}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row css={styles.otherOpinionWrapper}>
                      <Col md={12} sm={12}>
                        <ContentFeedbackForm
                          roomId={state.roomId}
                          starSize={70}
                          feedbackId={undefined}
                          feedbackText={''}
                          ratingValue={1}
                        />
                      </Col>
                      <Col
                        css={styles.opinionList}
                        md={12}
                        sm={12}
                        ref={listRef}
                      >
                        {allFeedback?.data?.length == 0 && (
                          <div css={styles.noFeedback}>
                            <Animation scale={0.4} animationEndEventHandler={() => {}} windowid={0}/>
                            <p>まだフィードバックがありません</p>
                          </div>
                        )}
                        {Object.entries(groupedFeedback).map(([key, value]) => {
                          return (
                            <div key={key} ref={(value as any).refCallback}>
                              <h4 css={styles.opinionDate}>{key}</h4>
                              {(value as any).feedbacks.map((data: any) => {
                                if (data.opinionText && data.opinionText.length) {
                                  return <Opinion
                                    message={data.opinionText}
                                    css={styles.opinion}
                                  />
                                } else {
                                  return '';
                                }
                              })}
                            </div>
                          )
                        })
                        }
                      </Col>
                      <Col md={12}>
                        <div>
                          {!!allHarassment?.data?.length && (
                            <p css={styles.harassmentText}>
                              <BsExclamationTriangleFill />
                              ハラスメント通報がありました。
                            </p>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Row>
              </div>
            </>
          ) : <TeamTab /> }
          <div>
            <div
              css={styles.howToButton}
              onClick={() => {
                setState({ ...state, isHelpModalOpened: true });
              }}
            >
              <img
                alt="ワカチエの使い方"
                src={`${process.env.PUBLIC_URL}/images/tab/howto_button.png`}
              />
            </div>
            <div css={styles.footer}>
              <p>Powered by ワカチエ</p>
            </div>
          </div>
          <HelpModal
            isOpen={state.isHelpModalOpened}
            onClose={() => {
              setState({ ...state, isHelpModalOpened: false });
            }}
          />
        </div>
      )}
      <Toaster/>
    </div>
  );
};

export default ContentContainer;
