import React, {useEffect} from "react";

// ページロード時にフロントエンドの管理者の同意フローを実行する処理

const FrontendAdminConsent = () => {
    
    useEffect(() =>  {
        const _ClientId = process.env.REACT_APP_CLIENT_ID;
        const _RedirectUri = process.env.REACT_APP_REDIRECT_URL;
        const _TodoListScope = process.env.REACT_APP_API_SCOPE;

        const adminConsent = "https://login.microsoftonline.com/organizations/v2.0/adminconsent?client_id=" 
        + _ClientId + "&redirect_uri=" + _RedirectUri+ "&state=123&scope=" + _ClientId + "/.default";
        
        window.location.href = adminConsent;
    });

    return(<><p>admin consent</p></>)
};

export default FrontendAdminConsent;