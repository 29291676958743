import Dexie from 'dexie';

import { compare } from '../utils/Compare';

export class CacheService {

    private database: any;
    constructor ( database?: any ){
        this.database = database ?? new Dexie('fisstdapp');
        this.database.version(3).stores({
            teams: '&id, expired, value.id, value.displayName, value.description, value.internalId, value.visibility, value.webUrl',
            channels: '&id, expired, values.id, values.displayName, values.webUrl',
            members: '&id, expired, values.displayName, values.userId, values.email',
            drives: '&id, expired, value.id, value.webUrl',
            icons: '&id, expired, value'
        })
    }

    async getTeams (keys: any) {
        return new Map(
            await Promise.all(
                await this.database.table('teams')
                    .filter((value: any) => keys.indexOf(value.id) >= 0)
                    .filter((value: any) => Number(value.expired) > Date.now())
                    .toArray()
                    .then((values: any) => values.map((value: any) => ([
                        value.id,
                        value.value
                    ])))
            )
        );
    }

    async setTeam (id: any, value: any) {
        await this.database.table('teams').put({
            id: id,
            expired: Date.now() + (3600 * 1000),
            value: value
        });
    }
    
    async getChannels (keys: any) {
        return new Map(
            await Promise.all(
                await this.database.table('channels')
                    .filter((value: any) => keys.indexOf(value.id) >= 0)
                    .filter((value: any) => Number(value.expired) > Date.now())
                    .toArray()
                    .then((values: any) => values.map((value: any) => ([
                        value.id,
                        value.values.sort((a: any, b: any) => compare(a.displayName, b.displayName))
                ])))
            )
        );
    }
    
    async setChannels (id: any, values: any) {
        await this.database.table('channels').put({
            id: id,
            expired: Date.now() + (3600 * 1000),
            values: values
        });
    }
    
    async getMembers (keys: any) {
        return new Map(
            await Promise.all(
                await this.database.table('members')
                    .filter((value: any) => keys.indexOf(value.id) >= 0)
                    .filter((value: any) => Number(value.expired) > Date.now())
                    .toArray()
                    .then((values: any) => values
                        .map((value: any) => ([
                            value.id,
                            value.values.sort((a: any, b: any) => compare(a.displayName, b.displayName))
                    ])))
            )
        );
    }
    
    async setMembers (id: any, values: any) {
        await this.database.table('members').put({
            id: id,
            expired: Date.now() + (3600 * 1000),
            values: values
        });
    }
    
    async getDrives (keys: any) {
        return new Map(
            await Promise.all(
                await this.database.table('drives')
                    .filter((value: any) => keys.indexOf(value.id) >= 0)
                    .filter((value: any) => Number(value.expired) > Date.now())
                    .toArray()
                    .then((values: any) => values
                        .map((value: any) => ([
                            value.id,
                            value.value
                    ])))
            ) 
        );
    }
    
    async setDrive (id: any, value: any) {
        await this.database.table('drives').put({
            id: id,
            expired: Date.now() + (3600 * 1000),
            value: value
        });
    }
    
    async getIcons (keys: any) {
        return new Map(
            await Promise.all(
                await this.database.table('icons')
                    .filter((value: any) => keys.indexOf(value.id) >= 0)
                    .filter((value: any) => Number(value.expired) > Date.now())
                    .toArray()
                    .then((values: any) => values
                    .map((value: any) => ([
                        value.id,
                        value.value
                    ])))
            )
        );
    }
    
    async setIcon (id: any, value: any) {
        await this.database.table('icons').put({
            id: id,
            expired: Date.now() + (3600 * 1000),
            value: value
        });
    }
    
}