import React, { ReactNode } from 'react';
import Animation from "./Animation";
import { SerializedStyles } from "@emotion/serialize";
import { css } from "@emotion/react";

interface Props {
  message?: string | ReactNode
  direction?: 'right' | 'left'| 'down'
  isBalloonOnly?: boolean
  children?: ReactNode
  styles?: Partial<{
    root: SerializedStyles,
    balloon: SerializedStyles
  }>
  className?: string
};

const jizoBalloonStyles = {
  balloonRight: css({
    marginLeft: 30,
    width: '100%',
    '&:before': {
      left: -42,
      borderRight: '30px solid white'
    }
  }),
  balloon: css({
    position: 'relative',
    display: 'inline-block',
    padding: '16px 20px',
    color: 'black',
    fontSize: 18,
    background: 'white',
    borderRadius: 15,

    'p': {
      margin: 0,
      padding: 0,
      textAlign: 'left',
    },

    '&:before': {
      content: "''",
      position: 'absolute',
      top: '50%',
      marginTop: -17,
      border: '18px solid transparent',
    }
  }),
  jizou: css({
    marginRight: 20,
  }),
  root: css({
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
  }),
}

export const Opinion = ({ message, styles, children, className}: Props) => {
  return (
    <div css={[styles?.root, jizoBalloonStyles.root ]} className={className} >
		  <Animation css={jizoBalloonStyles.jizou} scale={0.25} animationEndEventHandler={() => {}} windowid={0}/>
      <div css={[jizoBalloonStyles.balloon, jizoBalloonStyles.balloonRight, styles?.balloon]}>
        <p>{ message } {children} </p>
      </div>
    </div>
  )
};