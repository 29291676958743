import React from 'react';
import { VoteCountElement } from "../container/MeetingContainer";
import { css, Theme, useTheme } from "@emotion/react";

interface Props {
  currentVotes: VoteCountElement[];
  onClickVoteButton: (value: string) => void;
  currentColor?: string
}

const circleStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  cursor: 'pointer'
})

const makeStyles = (theme: Theme) => ({
  voteButtons: css({
    display: 'flex',
    justifyContent: 'space-between'
  }),
  voteSelected: css({
    height: 57,
  }, circleStyle),
  voteBackground: css({
    width: 54,
    height: 54,
    backgroundColor: theme.colors.gray,
    border: '5px solid',
  }, circleStyle),
  voteBackgroundNoBorder: css({
    border: 'unset',
  }),
  voteButton: css({
    width: 35,
    height: 35,
    backgroundColor: theme.colors.white,
    border: '8px solid',
    boxShadow: '0px 4px 5px #00000099',
  }, circleStyle)
})

const buttonColorMap = {
  BGBlue:  '#4480d9',
  BGRed: '#e23636',
  BGGreen: '#42c234',
  BGYellow: '#f6c334',
}

export const VoteButtons = ({ currentVotes, onClickVoteButton, currentColor }: Props) => {

  const handleClick = (value: string) => {
    onClickVoteButton(value);
  };

  const theme = useTheme()
  const styles = makeStyles(theme);

  return (
    <div css={styles.voteButtons}>
      {currentVotes.map((count) => (
        <div css={styles.voteSelected}>
          <div
            css={[
              styles.voteBackground,
              currentColor !== count.color && styles.voteBackgroundNoBorder,
              currentColor === count.color && css({
                borderColor: buttonColorMap[count.style],
              }),
            ]}
            className={currentColor === count.color ? count.style : ''}
            onClick={() => handleClick(count.color)}
          >
            <div
              css={[
                styles.voteButton,
                css({
                  borderColor: buttonColorMap[count.style],
                })
              ]}
              className={count.style}
            >
              <text>{count.count}</text>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
};