import { useMsal } from "@azure/msal-react";
import { useState } from "react";
import { useAsync } from "react-use";

export const useGraphTokenWithMsal = (): string | undefined => {
  const { instance,  accounts } = useMsal();
  const [graphToken, setGraphToken] = useState<string>();
  const userName = accounts[0]?.username;

  useAsync(async () => {
    if(userName == undefined) {
      return graphToken;
    }

    const res = await instance.acquireTokenSilent({
      scopes: ["openid", "profile", process.env.REACT_APP_AAD_SCOPE ?? ''] ,
      account: accounts[0]
    });
    setGraphToken(res.accessToken);
  }, [userName])

  return graphToken;
}