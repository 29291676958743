import { useMutation } from '@tanstack/react-query';
import { SubscriptionService } from '../services/subscriptionService';
import { useApiTokenForBrowser } from './browser/useApiTokenForBrowser';

export const useRegisterTrialMutation = (tenantId: string) => {
  const { token } = useApiTokenForBrowser();
  const subscriptionService = new SubscriptionService(tenantId, token);
  return useMutation(async () => {
    return subscriptionService.registerTrial(tenantId);
  });
};

export const useRegisterSubscriptionKeyMutation = (tenantId: string) => {
  const { token } = useApiTokenForBrowser();
  const subscriptionService = new SubscriptionService(tenantId, token);
  return useMutation(async (key: string) => {
    console.log(key);
    return subscriptionService.registerSubscriptionKey(key);
  });
};
