import React from 'react';
import { BrowserLayout } from "../component/BrowserLayout";
import { PageTitle } from "../component/PageTitle";
import { SubmitButton } from "../component/web/SubmitButton";
import { useNavigate } from "react-router";
import { css } from "@emotion/react";

const styles = {
  root: css({
    textAlign: 'center'
  }),
  message: css({
    fontSize: 24,
    textAlign: 'center',
    marginBottom: 50,
  })
}

export const InitializeCompleted = ({}) => {

  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/representative-page');
  }
  return (
    <BrowserLayout>
      <div css={styles.root}>
        <PageTitle
          withBackground={false}
          >
          初期設定が完了しました!
        </PageTitle>
        <p css={styles.message}>
          担当者様のメールアドレスに、登録完了メールを送信しました。<br/>
          また、テナント管理者様のメールアドレスに、作業依頼のメールを送信しました。<br/><br/>
          テナント担当者の作業が完了すると、サブスクリプションの有効化が可能になります。
        </p>
        <SubmitButton
          onClick={handleClick}
        >
          管理画面を開く
        </SubmitButton>
      </div>
    </BrowserLayout>
  )
};