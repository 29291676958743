import React, { useEffect, useState } from 'react';
import { MeetingModal } from "./MeetingModal";
import { VoteButtons } from "./VoteButtons";
import { VoteCountElement } from "../container/MeetingContainer";
import { css } from "@emotion/react";
import { Questionnaire, VoteTitleForm } from "./VoteTitleForm";

interface Props {
  onClose?: () => void;
  isOpen?: boolean;
  currentVotes: VoteCountElement[];
  currentQuestionnaire: Questionnaire;
  onClickVoteButton: (value: string) => void;
  onClickResetButton: () => void;
  onSubmitVoteTitle: (value: Questionnaire) => Promise<void>
}

const styles = {
  voteResetButtonWrapper: css({
    display: 'flex',
    justifyContent: 'end',
    p: {
      cursor: 'pointer',
      fontSize: 12,
      fontWeight: 'bold',
      marginBottom: 0,
      marginTop: 13,
      color: '#E23636',
    }
  }),
  voteButtons: css({
    marginTop: 5
  })
}

export const VoteModal = ({ currentVotes, currentQuestionnaire,onClickVoteButton, onClickResetButton, onSubmitVoteTitle,onClose, isOpen = false}: Props) => {
  const [radio, setRadio] = useState('');

  const handleReset = () => {
    setRadio('');
    onClickResetButton();
  }

  useEffect(() => {
    const totalCount = currentVotes.reduce((pv, current) => pv + current.count , 0);
    if(totalCount == 0){
      setRadio('');
    }
  }, [currentVotes])

  return (
    <MeetingModal isOpen={isOpen} onClose={onClose}>
      <div >
        <VoteTitleForm onSubmit={onSubmitVoteTitle} defaultValue={currentQuestionnaire}/>
      </div>
      <div css={styles.voteButtons}>
        <VoteButtons
          currentVotes={currentVotes}
          onClickVoteButton={(value) => {
            onClickVoteButton(value)
            setRadio(value)
          }}
          currentColor={radio}
        />
      </div>
      <div css={styles.voteResetButtonWrapper}>
        <p onClick={handleReset}>
          投票数をリセット
        </p>
      </div>
    </MeetingModal>
  )
};