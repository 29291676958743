import React from 'react';
import { Col, Row } from "react-bootstrap";
import { SerializedStyles } from "@emotion/serialize";
import { useTenant } from "../../hooks/browser/useTenant";
import { css } from "@emotion/react";
import { useGetSubscription } from "../../hooks/useGetSubscription";

interface DataRowProps {
  label: string;
  value: string;
  classes?: {
    label: SerializedStyles,
    value: SerializedStyles
  }
}

const styles = {
  root: css({
    width: 400
  }),
  label: css({
    textAlign: "right",
  }),
  value: css({
    textAlign: "left",
    flexGrow: 2,
  }),
  row: css({
    width: 700,
    marginBottom: 10,
  }),
  rowWrapper: css({
    marginTop:30,
    fontSize: 20,
    flexDirection: "column",
    alignItems: 'center'
  }),
  tenantName: css({
    fontSize: 30,
  })
}

const DataRow = ({label, value, classes}: DataRowProps) => (
  <Row css={styles.row}>
    <Col css={styles.label}>
      {label}
    </Col>
    <Col css={styles.value}>
      {value}
    </Col>
  </Row>
)

const formatDateString = (dateString: string | undefined) => {
  if (!dateString) {
    return '';
  }
  const date = new Date(dateString);

  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}/${month}/${day}`;
}

export const TenantData = () => {
  const [tenant] = useTenant();
  const { data: subscription } = useGetSubscription();

  let planName = '未契約';
  if (subscription?.subscriptionType == 1) {
    planName = '無料トライアル';
  }
  if (subscription?.subscriptionType == 2) {
    planName = 'エンタープライズプラン';
  }



  const data=[
    {
      label: 'テナントID',
      value: tenant?.tenantId
    },
    {
      label: 'ご契約種別',
      value: planName
    },
    {
      label: '期限',
      value: formatDateString(subscription?.expirationDate)
    },
  ]
  return (
    <div>
      <div>
        <h3 css={styles.tenantName}>{tenant?.tenantName}様</h3>
      </div>
      <Row css={styles.rowWrapper}>
        {data?.map((d) => (
          <DataRow label={d.label} value={d.value}/>
        ))}
      </Row>
    </div>
  )
};