import React from 'react';
import { Modal, ModalBody, ModalFooter } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader';
import { Form as FormikForm, Formik } from 'formik';
import { css } from '@emotion/react';
import { MdClose } from 'react-icons/md';
import { ModalCheckbox } from './ModalCheckbox';
import { SubmitButton } from './SubmitButton';
import { PageDescription } from './PageDescription';

export interface TrialModalValue {
  isSelected: boolean;
}

interface Props {
  show?: boolean;
  onClose?: () => void;
  onSubmit: (data: TrialModalValue) => void;
  initialValues?: TrialModalValue;
}

const styles = {
  root: css({
    '& > div': {
      maxWidth: 1000,
    },
    '& > div > div': {
      borderRadius: 20,
      maxWidth: 1000,
    },
  }),
  footer: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderTop: 'none',
    paddingTop: 30,
    paddingBottom: 50,
  }),
  header: css({
    border: 'none',
    justifyContent: 'flex-end',
  }),
  body: css({
    padding: '0 100px',
    paddingTop: 40,
  }),
  label: css({
    fontSize: 18,
    flexBasis: 280,
  }),
};

export const TrialModal = ({
  show,
  onClose = () => {},
  onSubmit,
  initialValues,
}: Props) => {
  return (
    <Modal show={show} onHide={onClose} css={styles.root}>
      <Formik
        enableReinitialize
        onSubmit={onSubmit}
        initialValues={
          initialValues ?? {
            isSelected: false,
          }
        }
      >
        {({
          values,
          handleChange,
          errors,
          handleBlur,
          touched,
          isSubmitting,
        }) => (
          <>
            <ModalHeader css={styles.header}>
              <MdClose
                size={36}
                onClick={onClose}
                style={{ cursor: 'pointer' }}
              />
            </ModalHeader>
            <FormikForm>
              <ModalBody css={styles.body}>
                <PageDescription>
                  トライアル登録には、利用規約への同意が必要です。
                  <br />
                  <a href="https://wakachie.com/term/">
                    ワカチエの利用規約はこちら。
                  </a>
                </PageDescription>

                <ModalCheckbox
                  label="利用規約に同意します。"
                  name="isSelected"
                  value={values.isSelected}
                  onChange={handleChange}
                  classes={{ label: styles.label }}
                />
              </ModalBody>
              <ModalFooter css={styles.footer}>
                <SubmitButton type="submit" disabled={isSubmitting}>
                  登録する
                </SubmitButton>
              </ModalFooter>
            </FormikForm>
          </>
        )}
      </Formik>
    </Modal>
  );
};
