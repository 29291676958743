import React from "react";
import { css } from "@emotion/react";
import { ContactButton } from "../component/ContactButton";

const styles = {
  root: css({
    background: "transparent linear-gradient(180deg, #FF711E 0%, #108CCB 100%) 0% 0% no-repeat padding-box",
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh'
  }),
  jizou: css({
    marginBottom: 58
  }),
  jizouWrapper: css({
    background: 'white',
    borderRadius: 20,
    textAlign: 'center',
    padding:' 82px 150px 73px 150px',
  }),
  message: css({
    fontSize: 24,
    fontWeight: 'bold',
    whiteSpace: 'pre-wrap'
  }),
  logo: css({
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    top: 21,
    left: 35
  }),
  logoImage: css({
    width: 113
  }),
}

const Unsupported = (props:any) => {
  const DEFAULT_MESSAGE="ワカチエ on Teams は Microsoft Teams でお楽しみいただけます。";
  return (
    <div css={styles.root}>
      <div css={styles.logo}>
        <div>
          <img
            src={process.env.PUBLIC_URL + "/images/unsupported/logo.png"}
            alt="ロゴ"
            css={styles.logoImage}
          />
        </div>
      </div>
      <div css={styles.jizouWrapper}>
        <div css={styles.jizou}>
          <img
            src={process.env.PUBLIC_URL + "/images/unsupported/zizou.png"}
            alt="あいづち"
            className=""
          />
        </div>
        <div><h3 css={styles.message}>{props.message || DEFAULT_MESSAGE}</h3></div>
        <ContactButton />
      </div>
    </div>
  );
};

export default Unsupported;
