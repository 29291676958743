import React from 'react';
import './App.css';
import './css/App.scss';

import MyRoutes from './container/Routes';
import Providers from './component/Providers';

function App() {
  return (
    <div>
      <Providers>
        <MyRoutes />
      </Providers>
    </div>
  );
}

export default App;
