import * as microsoftTeams from '@microsoft/teams-js';
import axios from 'axios';
import jwtDecode from 'jwt-decode';

const graphTokenKey = process.env.REACT_APP_CLIENT_ID + '/graph';
const apiTokenKey = process.env.REACT_APP_CLIENT_ID + '/api';

// teamsクライアントが持っているトークンを取得する関数
export const getClientToken = (): Promise<string> => {
  return new Promise((resolve, reject) => {
    microsoftTeams.authentication.getAuthToken({
      successCallback: (result) => {
        console.log('get clientToken success');
        const clientToken = result;
        resolve(clientToken);
      },
      failureCallback: (error) => {
        console.log(error);
      },
    });
  });
};

//セッションストレージに格納されたgraph Tokenを取得する関数
export const getCachedGraphToken = () => {
  const graphToken = sessionStorage.getItem(graphTokenKey);
  if (!graphToken || graphToken === 'undefined') {
    return null;
  }

  const decodedToken = jwtDecode<any>(graphToken);

  const now = Date.now();

  if (now >= decodedToken.exp * 1000) {
    return null;
  }

  return graphToken;
};

//セッションストレージに graphToken を格納する関数
export const setCachedGraphToken = (token: string) => {
  sessionStorage.setItem(graphTokenKey, token);
};

//バックエンドへgraphTokenを問い合わせる関数
export const getGraphToken = (clientToken: string) => {
  return new Promise((resolve, reject) => {
    const endPointUrl =
      process.env.REACT_APP_SIGNALR_SERVER_URL + '/api/token/GetGraphToken';
    const data = {
      Token: clientToken,
    };

    axios
      .post(endPointUrl, data)
      .then((resp) => {
        resolve(resp.data.access_token);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//バックエンドへapiTokenを問い合わせる関数
export const getApiToken = (clientToken: string) => {
  return new Promise((resolve, reject) => {
    const endPointUrl =
      process.env.REACT_APP_SIGNALR_SERVER_URL + '/api/token/GetServerToken';
    const data = {
      Token: clientToken,
    };

    axios
      .post(endPointUrl, data)
      .then((resp) => {
        resolve(resp.data.access_token);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//セッションストレージに格納されたapiTokenを取得する関数
export const getCachedApiToken = () => {
  const apiToken = sessionStorage.getItem(apiTokenKey);
  if (!apiToken || apiToken === 'undefined') {
    return null;
  }

  const decodedToken = jwtDecode<any>(apiToken);

  const now = Date.now();

  if (now >= decodedToken.exp * 1000) {
    return null;
  }

  return apiToken;
};

//セッションストレージに apiToken を格納する関数
export const setCachedApiToken = (token: string) => {
  sessionStorage.setItem(apiTokenKey, token);
};

export const isTokenExpired = (token: string) => {
  const decodedToken = jwtDecode<any>(token);
  const now = Date.now();
  return now >= decodedToken.exp * 1000;
};
