import React, { useState } from 'react';
import { BrowserLayout } from '../component/BrowserLayout';
import { PageTitle } from '../component/PageTitle';
import { PageDescription } from '../component/web/PageDescription';
import { css, Theme, useTheme } from '@emotion/react';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import { useTenantIdForBrowser } from '../hooks/useTenantIdForBrowser';
import {
  useRegisterSubscriptionKeyMutation,
  useRegisterTrialMutation,
} from '../hooks/useSubscriptionForBrowser';
import LoginAnnounce from '../component/LoginAnnounce';
import {
  SubscriptionKeyModal,
  SubscriptionKeyModalValue,
} from '../component/web/SubscriptionKeyModal';
import { SubmitButton } from '../component/web/SubmitButton';
import { TrialModal, TrialModalValue } from '../component/web/TrialModal';

const makeStyles = (theme: Theme) => ({
  root: css({
    textAlign: 'center',
  }),
  button: css({
    background: 'transparent',
    fontSize: 32,
    color: theme.colors.white,
    borderRadius: 45,
    padding: '15px 15px',
    width: 470,
    height: 'unset',
    '&:hover': {},
    '&:disabled, &:hover:disabled': {
      color: theme.colors.buttonLinkText,
      background: theme.colors.buttonLinkDisabledBackground,
      borderColor: theme.colors.darkGray,
    },
  }),
  trial: css({
    backgroundColor: theme.colors.webSecondary,
  }),
  subscribe: css({
    backgroundColor: theme.colors.primary,
  }),
  tableWrapper: css({
    justifyContent: 'center',
    marginBottom: 30,
  }),
  buttonWrapper: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    columnGap: 20,
  }),
});

const Subscribe = () => {
  const [tenantId] = useTenantIdForBrowser();
  const [modalState, setModalState] = useState<boolean>(false);
  const [trialModalState, setTrialModalState] = useState<boolean>(false);
  const [isSubscribeCompleat, setIsSubscribeCompleat] =
    useState<boolean>(false);
  const theme = useTheme();
  const styles = makeStyles(theme);
  const registerTrialMutation = useRegisterTrialMutation(tenantId ?? '');

  const registerSubscriptionKeyMutation = useRegisterSubscriptionKeyMutation(
    tenantId ?? ''
  );

  const handleRegisterTrial = async (value: TrialModalValue) => {
    if (value.isSelected) {
      const result = await registerTrialMutation
        .mutateAsync()
        .catch((result) => {
          return {
            message: result.response.data.detail,
          };
        });
      alert(result.message);
    } else {
      alert('「利用規約に同意します」にチェックを入れてください');
    }
  };

  const handleRegisterSubscriptionKey = async (
    value: SubscriptionKeyModalValue
  ) => {
    const result = await registerSubscriptionKeyMutation
      .mutateAsync(value.key)
      .catch((result) => {
        alert(result.response.data?.title);
        return result.response.data ?? {};
      });

    console.log(result);
    if (result.status === 'success') {
      setIsSubscribeCompleat(true);
    }
    setModalState(false);
  };

  const handleOpenModal = () => {
    setModalState(true);
  };

  const handleCloseModal = () => {
    setModalState(false);
    setTrialModalState(false);
  };

  const handleOpenTrialModal = () => {
    setTrialModalState(true);
  };

  return (
    <div>
      <BrowserLayout css={styles.root}>
        <AuthenticatedTemplate>
          <PageTitle>サブスクリプション有効化ページ</PageTitle>
          {!isSubscribeCompleat ? (
            <div>
              <PageDescription>
                サブスクリプションの有効化を行うことができます
                <br />
                サブスクリプションキーの入力または、トライアル開始ボタンを押してください
              </PageDescription>
              <div css={styles.buttonWrapper}>
                <SubmitButton
                  onClick={handleOpenModal}
                  css={[styles.button, styles.subscribe]}
                >
                  登録する
                </SubmitButton>
                <SubmitButton
                  onClick={handleOpenTrialModal}
                  css={[styles.button, styles.trial]}
                >
                  トライアル開始
                </SubmitButton>
              </div>
              <SubscriptionKeyModal
                onSubmit={handleRegisterSubscriptionKey}
                onClose={handleCloseModal}
                show={modalState}
              />
              <TrialModal
                onSubmit={handleRegisterTrial}
                onClose={handleCloseModal}
                show={trialModalState}
              />
            </div>
          ) : (
            <div>
              <PageDescription>
                サブスクリプションの有効化されました
              </PageDescription>
            </div>
          )}
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <LoginAnnounce usePopup={true} />
        </UnauthenticatedTemplate>
      </BrowserLayout>
    </div>
  );
};

export default Subscribe;
