import React, { ReactNode, useEffect, useState } from 'react';
import { Container, Navbar } from "react-bootstrap";
import { css } from "@emotion/react";
import { SerializedStyles } from "@emotion/serialize";
import { useIsRepresentative } from "../hooks/useIsRepresentative";
import { useIsTenantAdministrator } from "../hooks/useIsTenantAdministrator";

interface Props {
  children: ReactNode;
  classes?: {
    content?: SerializedStyles;
  }
};

const styles = {
  headerContent: css({
    margin: 0,
    boxSizing: 'border-box',
    width: '100%',
    maxWidth: '100%'
  }),
  header: css({
    padding: '30px 0 20px 0',
    borderBottom: '1px solid #aaaaaa'
  }),
  logo: css({
    width: 130,
    margin: '0 25px'
  }),
  content: css({
    paddingTop: 60,
    //maxWidth: '100%'
  })
}

export const BrowserLayout = ({ children, classes }: Props) => {

  const { isRepresentative } = useIsRepresentative();
  const isTenantAdministrator = useIsTenantAdministrator();

  const [ path, setState ] = useState('');

  useEffect(() => {
    if(isRepresentative){
      setState('/representative-page')
    }

    if(isTenantAdministrator){
      setState('/admin-page')
    }
  }, [isRepresentative, isTenantAdministrator]);

  return (
    <div>
      <header css={styles.header}>
        <Container css={styles.headerContent}>
          <Navbar expand="lg">
            <Navbar.Brand href={path}>
              <img
                src={`${process.env.PUBLIC_URL}/images/util/logo.png`}
                alt="ワカチエロゴ"
                css={styles.logo}
              />
            </Navbar.Brand>
          </Navbar>
        </Container>
      </header>
      <Container css={[styles.content, classes?.content]}>
        { children }
      </Container>
    </div>
  )
};