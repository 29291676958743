export const compare = (a: any, b: any): number => {
    if (a === b) {
        return 0;
    }
    if (!a) {
        return 1;
    }
    if (!b) {
        return -1;
    }
    return String.prototype.localeCompare.call(a,b);
} 