import React, { useEffect, useState } from 'react';
import { useMyFeedbackMutation } from "../hooks/useMyFeedbackMutation";
import { css, Theme, useTheme } from "@emotion/react";
import { Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-regular-svg-icons/faPaperPlane";

const makeStyles = (theme: Theme) => ({
  ratingStarWrapper: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    'p': {
      paddingTop: 10,
      fontWeight: 'bold',
      display: 'inline-block',
      marginTop: 'revert',
      color: theme.colors.darkGray,
    },
  }),
  ratingStars: css({
    display: 'block',
    margin: 10,
  }),
  feedbackFormWrapper: css({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    '& > div': css({
      marginTop: 16,
    }),
  }),
  feedbackInputWrapper: css({
    textAlign: 'right',
    padding: '0 24px',
    width: '100%',
  }),
  roomNameLabel: css({
    marginBottom: 0,
    width: '100%',
  }),
  roomNameInput: css({
    height: 48,
    background: '#ffffff 0% 0% no-repeat padding-box;',
    border: '1px solid #afafaf',
    padding: '0 10px',
    borderRadius: 6,
    opacity: 1,
    width: '100%',
  }),
  feedbackSendButton: css({
    position: "absolute",
    paddingTop: 10,
    right: 32,
    cursor: "pointer",
    border: "none",
    background: "none",
    }
  ),

})


interface Props {
  localAccountId?: string;
  roomId: string;
  onSuccess?: () => void;
  starSize?: number;
  feedbackText?: string;
  ratingValue?: number;
  feedbackId?: number;
}

export const ContentFeedbackForm = ({
                                             roomId,
                                             onSuccess = () => {},
                                             feedbackText,
                                             ratingValue,
                                             feedbackId,
                                           }: Props) => {
  const [state, setState] = useState({
    feedbackText: feedbackText,
    ratingValue: ratingValue,
    feedbackId: feedbackId,
  });

  useEffect(() => {
    setState({ ...state, feedbackText, ratingValue, feedbackId });
  }, [feedbackText]);

  const mutation = useMyFeedbackMutation(roomId);
  const theme = useTheme();
  const mergedStyles = makeStyles(theme);

  const onFeedbackSubmit = async (feedbackText: string | undefined) => {
    const postData = {
      RoomId: roomId,
      OpinionText: feedbackText && feedbackText.length ? feedbackText : null,
    };
    console.log(postData)
    await mutation.mutateAsync(postData)
      .then(onSuccess)
      .catch((err) => {
        console.log('send feedback error :', err);
        alert('フィードバックを保存できませんでした。');
      });
    setState({ ...state, feedbackText });
  };

  return (
    <>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          await onFeedbackSubmit(state.feedbackText);
          setState({ ...state, feedbackText: '' });
        }}
      >
        <Row css={mergedStyles.feedbackFormWrapper}>
          <div css={mergedStyles.feedbackInputWrapper}>
            <label css={mergedStyles.roomNameLabel}>
              <input
                type="text"
                value={state.feedbackText}
                css={mergedStyles.roomNameInput}
                placeholder="その他ご意見がありましたらご記入ください(匿名)"
                onChange={(e) =>
                  setState({ ...state, feedbackText: e.target.value })
                }
              />
              <button type="submit"  css={mergedStyles.feedbackSendButton}>
                <FontAwesomeIcon icon={faPaperPlane} />
              </button>
            </label>
          </div>
        </Row>
      </form>
    </>

  );
};
