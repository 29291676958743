import axios, { Axios } from 'axios';
import { getCachedApiToken } from '../utils/TokenManager';

export class MeetingService {
  private readonly axios: Axios;
  private readonly endpointBase: string;
  constructor(apiToken: string = '') {
    const token = apiToken !== '' ? apiToken : getCachedApiToken();
    this.axios = axios.create({
      headers: { Authorization: `Bearer ${token}` },
    });
    this.endpointBase = process.env.REACT_APP_SIGNALR_SERVER_URL + '/api';
  }

  private async post(endpoint: string, postData: any): Promise<any> {
    const ep = this.endpointBase + '/' + endpoint;
    const response = await this.axios.post(ep, postData).catch((error: any) => {
      console.log(error);
      throw error;
    });
    console.log(response);
    return response.data;
  }

  public async meetingRoomIsActive(roomId: string) {
    return this.post(`meeting/IsActive`, {
      meetingId: roomId,
    });
  }
}
