import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

interface NamePlateProps {
  displayName?: string;
}

const makeStyles = (theme: Theme) => ({
  namePlate: css({
    textAlign: 'center',
    marginBottom: 2,
    color: theme.colors.white,
    wordBreak: 'break-word',
    fontSize: '0.95rem',
    lineHeight: 1.1
  })
})

const Nameplate = (props: NamePlateProps) => {
  const theme = useTheme()
  const styles = makeStyles(theme)

  return (
    <p css={styles.namePlate}>
      {props.displayName ? parseName(props.displayName) : "audience"}
    </p>
  );
};

function parseName(displayName: string) {
  const names = displayName.toLowerCase().split(" ");
  const parseLength = 10;

  if (displayName.length <= parseLength) {
    return displayName;
  }

  for (let name of names) {
    if (name.length === 0) {
      continue;
    }

    if (name.length > parseLength) {
      const parsedName = displayName.substring(0, parseLength);
      return parsedName;
    } else {
      return name;
    }
  }
}

export default Nameplate;
