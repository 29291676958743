import React, { ReactNode } from 'react';
import { css, Theme, useTheme } from "@emotion/react";
import { SerializedStyles } from "@emotion/serialize";

interface Props {
  children: ReactNode,
  withBackground?: boolean,
  classes?: {
    root?: SerializedStyles
  },
}

const makeStyles = (theme: Theme, withBackground: boolean) => ({
  root: css({
    fontWeight: 'bold',
    fontSize: 47,
    marginBottom: 50,
    textAlign: 'center',
    backgroundColor: withBackground ? theme.colors.lightGray : 'unset',
    padding: '10px 0',
  })
})

export const PageTitle = ({children, classes, withBackground = true}: Props) => {
  const styles = makeStyles(useTheme(), withBackground);
  return (
    <h3 css={[styles.root, classes?.root]}>
      {children}
    </h3>
  )
};