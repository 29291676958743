import { useTeamsContext } from './useTeamsContext';
import { useEffect } from 'react';

export const useColorTheme = () => {
  const { context, teamsContextError } = useTeamsContext();

  useEffect(() => {
    if (context?.app.theme == 'dark') {
      document.documentElement.classList.add('dark');
      return;
    }
    document.documentElement.classList.remove('dark');
  }, [context?.app.theme]);
};
