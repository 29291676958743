import { useMutation, useQueryClient } from "@tanstack/react-query";
import { queryKey } from "../utils/QueryKey";
import { FeedbackService } from "../services/feedbackService";
import { useApiTokenForBrowser } from "./browser/useApiTokenForBrowser";

export const useCreateStarRatingMutation = (roomId: string) => {
  const { token } = useApiTokenForBrowser()
  const feedbackService = new FeedbackService({ roomId })
  const queryClient = useQueryClient();

  return  useMutation(async (data: any) => {
      return  feedbackService.createStarRating(data);
    }, {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKey.myStarRating])
        queryClient.invalidateQueries([queryKey.allStarRating])
      }}
  );
}