import React, { useState } from 'react';
import { FeedbackService } from "../services/feedbackService";
import { MeetingModal } from "./MeetingModal";
import { useAsync } from "react-use";
import { css } from "@emotion/react";
import { AppButton } from "./AppButton";

type Props = {
  isOpen: boolean;
  onClose?: () => void;
  defaultValues?: Partial<HarassmentFormValues>;
  localAccountId: string;
  roomId: string;
};

interface State extends HarassmentFormValues {}

export type HarassmentFormValues = {
  harassmentText?: string;
};

const styles = {
  root: css({
    textAlign: 'center'
  }),
  title: css({
    color: 'red',
    fontSize: 14,
    fontWeight: 'bold',
    img: {
      width: 26,
      marginRight: 5
    }
  }),
  caption: css({
    fontSize: 10
  }),
  roomNameInput: css({
    width: '100%',
    height: 48,
    background: '#ffffff 0% 0% no-repeat padding-box',
    border: '1px solid #afafaf',
    borderRadius: 6,
    opacity: 1,
    paddingLeft: 10,
  }),
  harassmentSend: css({
    display: 'block',
    margin: 'auto',
    marginTop: 17,
    fontWeight: 'bold'
  })
}

export const SidePanelHarassmentModal = ({
  isOpen,
  onClose,
  localAccountId,
  roomId
}: Props) => {

  const [state, setState] = useState<State>({
    harassmentText: '',
  });

  const feedbackService =  new FeedbackService({ roomId: roomId });

  useAsync( async () => {
    const accountId = '';

    const data = await feedbackService
      ?.fetchRegisteredHarassment(accountId)
      .catch((err) =>
        handleFailure(err, 'ハラスメント情報を取得できませんでした。')
      );
    if (data) {
      setState({ harassmentText: data.harassmentText });
    }
  })

  const onHarassmentSubmit = ({ harassmentText }: { harassmentText: string }) => {
    if (!localAccountId) {
      alert(
        'エラーが発生しました。申し訳ありませんが、最初からやり直してください。'
      );
      return;
    }

    const postData = {
      RoomId: roomId,
      UserId: localAccountId,
      HarassmentText:
        harassmentText && harassmentText.length ? harassmentText : null,
    };

    feedbackService
      ?.sendHarassment(postData)
      .then(onClose)
      .catch((err) =>
        handleFailure(err, 'ごめんね。ハラスメント保存できなかったよ')
      );
    setState({ harassmentText });
  }

  const onHarassmentDelete = async () => {
    const data = await feedbackService
      ?.deleteHarassment()
      .catch((err) =>
        handleFailure(err, 'ハラスメントの削除に失敗しました。')
      );
    if (data) {
      setState({ harassmentText: '' });
      onClose && onClose();
    }
  }

  const handleSubmit = ({ harassmentText }: { harassmentText?: string }) => {
    if(harassmentText) {
      onHarassmentSubmit({ harassmentText });
      return;
    }
    onHarassmentDelete();
  }

  const handleFailure = (err: any, message: string) => {
    console.log(err);
  }

  return (
    <MeetingModal
      onClose={onClose}
      isOpen={isOpen}
    >
      <div css={styles.root}>
        <div>
          <p css={styles.title}>
            <img alt="通報" src={`${process.env.PUBLIC_URL}/images/meetingContainer/report.png`}/>
            ハラスメント通報フォーム
          </p>
          <p css={styles.caption}>
            この会議で嫌な思いをしましたか？
            <br />
            このフォームよりハラスメントを通報できます。
          </p>
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(state);
          }}
        >
          <input
            type="text"
            value={state.harassmentText}
            placeholder="具体的な内容についてお書きください(匿名)"
            css={styles.roomNameInput}
            onChange={(e) => setState({ harassmentText: e.target.value })}
          />
          <AppButton color="red" type="submit" css={styles.harassmentSend}>
            ハラスメント通報(匿名)
          </AppButton>
        </form>
      </div>
    </MeetingModal>
  );
};
