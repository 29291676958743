import { useQuery } from "@tanstack/react-query";
import { FeedbackService } from "../services/feedbackService";
import { useClient } from "./useClient";
import { queryKey } from "../utils/QueryKey";

export const useMyStarRatings = (roomId: string) => {
    const feedbackService = new FeedbackService({
        roomId
    });

    const client = useClient()
    const { data, error } = useQuery(
        [queryKey.myStarRating],
        async () => feedbackService.fetchMyStarRatings(),
        { enabled: roomId != undefined && roomId != "" && client != undefined }
    )
    return { data, error };
}