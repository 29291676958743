import { v5 as uuidv5 } from 'uuid';

const NAME_SPACE = process.env.REACT_APP_AUTH_TENANT_ID!;
const name = 'wakachie';
export const DUMMY_GUID = '00000000-0000-0000-0000-000000000000';

export const generateUuid = () => {
  const uuid = uuidv5(name, NAME_SPACE);
  return uuid;
};
