import { useApiTokenForBrowser } from "./browser/useApiTokenForBrowser";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { TenantAdministratorService } from "../services/TenantAdministratorService";
import { queryKey } from "../utils/QueryKey";

export const useConfirmTenantAdministrator = () => {
  const { token: apiToken } = useApiTokenForBrowser();
  const tenantAdministratorService = new TenantAdministratorService(apiToken);
  const queryClient = useQueryClient();
  return useMutation(async (token: string) => {
    return tenantAdministratorService.confirm(token);
  },{
    onSuccess: () => {
      console.log('onSuccess')
      queryClient.invalidateQueries([queryKey.myTenantAdministrator])
    }
  })
}