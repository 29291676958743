import React, { ReactNode } from 'react';
import Animation from "./Animation";
import { SerializedStyles } from "@emotion/serialize";
import { css } from "@emotion/react";

interface Props {
  message?: string | ReactNode
  direction?: 'right' | 'left'| 'down'
  isBalloonOnly?: boolean
  children?: ReactNode
  styles?: Partial<{
    root: SerializedStyles,
    balloon: SerializedStyles
  }>
};

const jizoBalloonStyles = {
  balloonRight: css({
    marginLeft: 30,
    width: 'calc(100% - 120px)',
    maxWidth: 500,
    '&:before': {
      left: -42,
      borderRight: '30px solid white'
    }
  }),
  balloonLeft: css({
    marginRight: 30,
    maxWidth: '100%',
    '&:before': {
      right: -42,
      borderLeft: '30px solid white'
    }
  }),
  balloonDown: css({
    marginBottom: 30,
    '&:before': {
      bottom: -42,
      borderTop: '30px solid white',
      marginTop: 0,
      top: '100%',
      right: 'calc(50% - 18px)',
    }
  }),
  balloon: css({
    position: 'relative',
    display: 'inline-block',
    padding: '25px 30px',
    color: 'black',
    fontSize: 18,
    background: 'white',
    borderRadius: 5,

    'p': {
      margin: 0,
      padding: 0,
      textAlign: 'left',
    },

    '&:before': {
      content: "''",
      position: 'absolute',
      top: '50%',
      marginTop: -17,
      border: '18px solid transparent',
    }
  }),
  jizou: css({
    marginTop: -150,
  }),
  root: css({
    display: 'flex',
  }),
  leftRoot: css({
    flexDirection: 'row-reverse',
  })
}

export const JizoBalloon = ({ message, styles, direction = 'right', isBalloonOnly, children }: Props) => {
  let balloonClass;
  switch (direction) {
    case 'right':
      balloonClass = jizoBalloonStyles.balloonRight;
      break;
    case 'left':
      balloonClass = jizoBalloonStyles.balloonLeft;
      break;
    case 'down':
      balloonClass = jizoBalloonStyles.balloonDown;
      break;
  }
  const rootStyle = direction == 'left' ? jizoBalloonStyles.leftRoot : undefined;

  return (
    <div css={[styles?.root, jizoBalloonStyles.root, rootStyle]} >
      {!isBalloonOnly &&
        <Animation css={jizoBalloonStyles.jizou} scale={0.25} animationEndEventHandler={() => {}} windowid={0}/>
      }
      <div css={[jizoBalloonStyles.balloon, balloonClass, styles?.balloon]}>
        <p>{ message } {children} </p>
      </div>
    </div>
  )
};