import { Route, Routes } from 'react-router-dom';

import Content from './Content';
import Unsupported from './Unsupproted';
import SidePanel from './SidePanel';
import Loading from './Loading';
import Configuration from './Configuration';
import { ExternalUserPage } from '../component/ExternalUserPage';
import FrontendAdminConsent from '../component/FrontendAdminConsent';
import { AdminConsent } from '../component/AdminConsent';
import { AccessDenied } from './AccessDenied';
import { RepresentativePage } from './RepresentativePage';
import { AdminPage } from './AdminPage';
import { RepresentativeManagementPage } from './RepresentativeManagementPage';
import { TenantAdministratorManagementPage } from './TenantAdministratorManagementPage';
import { HarassmentManagerMailAddressPage } from './HarassmentManagerMailAddressPage';
import UnSubscribe from './UnSubscribe';
import Subscribe from './Subscribe';
import { RepresentativeAuthLayout } from "../component/web/RepresentativeAuthLayout";
import { LoginLayout } from "../component/web/LoginLayout";
import { ConfirmWithToken } from "./ConfirmWithToken";
import { InitialSetting } from "./InitialSetting";
import { InitializeCompleted } from "./InitializeCompleted";

const MyRoutes = () => {
  return (
    <Routes>
      <Route path="/content" element={<Content />} />
      <Route path="/sidepanel" element={<SidePanel />} />
      <Route path="/unavailable" element={<AccessDenied />} />
      <Route path="/" element={<Loading />} />
      <Route path="/unsupported" element={<Unsupported />} />
      <Route path="/consent" element={<FrontendAdminConsent />} />
      <Route path="/configuration" element={<Configuration />} />
      <Route path="/external-user" element={<ExternalUserPage />} />
      <Route path="/initialize-completed" element={<InitializeCompleted />} />
      <Route path="/unsubscribe" element={<UnSubscribe />} />
      <Route element={<LoginLayout />}>
        <Route path="/admin-consent" element={<AdminConsent />} />
        <Route path="/admin-page" element={<AdminPage />} />
        <Route path="/initial-setting" element={<InitialSetting />} />
        <Route path="/confirm-with-token" element={<ConfirmWithToken />} />
        <Route element={<RepresentativeAuthLayout />}>
          <Route path="/representative-page" element={<RepresentativePage />} />
          <Route
            path="/representative-management-page"
            element={<RepresentativeManagementPage />}
          />
          <Route
            path="/tenant-administrator-management-page"
            element={<TenantAdministratorManagementPage />}
          />
          <Route
            path="/harassment-manager-email-address-management-page"
            element={<HarassmentManagerMailAddressPage />}
          />
          <Route path="/subscribe" element={<Subscribe />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default MyRoutes;
