import { useQuery } from "@tanstack/react-query";
import { queryKey } from "../utils/QueryKey";
import { RepresentativeService } from "../services/RepresentativeService";
import { useApiTokenForBrowser } from "./browser/useApiTokenForBrowser";

type State = {
  data?: any,
  error?: any,
}

export const useAllRepresentatives = () => {
  const { token } = useApiTokenForBrowser()
  const representativeService = new RepresentativeService(token);
  const { data, error, } = useQuery(
    [queryKey.representative],
    async () => representativeService.fetchAllRepresentative(),
    { enabled: token != undefined }
  )

  return { data, error}
}