import React, { useEffect, useState } from 'react';
import { css } from "@emotion/react";

interface Props {
  onSubmit: (values: Questionnaire) => Promise<void>;
  defaultValue?: Questionnaire;
  disabled?: boolean
}

export type Questionnaire = {
  title: string;
  id: number;
}

const styles = {
  root: css({
    paddingTop: 5,
  }),
  input: css({
    width: '100%',
    fontSize: 14,
    fontWeight: 'bold',
    border: '#AFAFAF 1px solid',
    borderRadius: 5,
    '&::placeholder': css({
      textAlign: "center"
    })
  })
}

export const VoteTitleForm = ({ onSubmit, defaultValue, disabled = false }: Props) => {

  const [state, setState] = useState({
    title: '',
    id: 0
  });

  useEffect(() => {
    setState({ ...state, ...defaultValue});
  }, [defaultValue])

  return (
    <div css={styles.root}>
      <form
        onSubmit={async (e) => {
          if (disabled){
            return;
          }
          e.preventDefault();
          await onSubmit(state)
        }}
      >
        <input
          type="text"
          disabled={disabled}
          css={styles.input}
          value={state.title}
          placeholder="ここに質問文を入力できます"
          onChange={(e) =>
            setState({ ...state, title: e.target.value})
          }
        />
      </form>
    </div>
  )
};