import { useState, useEffect } from 'react';
import { DevContent } from './DevContent';
import ContentContainer from './ContentContainer';
import { useColorTheme } from '../hooks/useColorTheme';
import { usePersonalMutation, usePersonal } from '../hooks/usePersonal';
import { Loading } from '../component/Loading';
import { useClientToken } from '../hooks/useClientToken';
import { useNavigate } from 'react-router';
import { useTeamsContext } from '../hooks/useTeamsContext';
import { USER_ROLE } from './SidePanel';

const Content = () => {
  const [userPrincipalName, setUserPrincipalName] = useState('');
  const [account, setAccount] = useState({
    localAccountId: '',
    name: '',
    userRole: USER_ROLE.initial,
  });
  const personalMutation = usePersonalMutation(userPrincipalName);
  const { personalData, personalError } = usePersonal(userPrincipalName);
  const navigate = useNavigate();
  const { context, teamsContextError } = useTeamsContext();
  const { decodedToken } = useClientToken();
  const isDev = process.env.REACT_APP_ENVIROMENT === 'DEVELOPMENT';
  useColorTheme();

  //contextに変更があったときに実行
  useEffect(() => {
    if (context === undefined) {
      return;
    }
    setUserPrincipalName(context?.user?.userPrincipalName || '');
  }, context as any);

  useEffect(() => {
    if (personalData) {
      const _account = {
        localAccountId: personalData.id,
        name: personalData.displayName,
        userRole: personalData.userType,
      };
      setAccount(_account);
    }
  }, [personalData]);

  return (
    <div>
      {!account?.localAccountId && <Loading />}
      {account?.localAccountId &&
        (isDev ? <DevContent /> : <ContentContainer />)}
    </div>
  );
};

export default Content;
