export const queryKey = {
  myFeedback: 'MyFeedback',
  feedbacks: 'Feedbacks',
  myHarassment: 'MyHarassment',
  allHarassment: 'AllHarassment',
  tenant: 'Tenant',
  representative: 'representative',
  myRepresentative: 'myRepresentative',
  tenantAdministrator: 'TenantAdministrator',
  harassmentManagerEmailAddress: 'HarassmentManagerEmailAddress',
  expired: 'expired',
  myTenantAdministrator: 'myTenantAdministrator',
  tenantId: 'tenantId',
  apiToken: 'apiToken',
  graphApiToken: 'graphApiToken',
  subscription: 'subscription',
  roomStatus: 'roomStatus',
  personal: 'Personal',
  myStarRating: 'MyStarRating',
  allStarRating: 'AllStarRating',
};
