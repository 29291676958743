import { TenantInitializeData, TenantService } from "../services/TenantService";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { queryKey } from "../utils/QueryKey";
import { useApiTokenForBrowser } from "./browser/useApiTokenForBrowser";

export const useInitializeTenant = () => {
  const { token: apiToken } = useApiTokenForBrowser();
  const tenantService = new TenantService(apiToken);
  const queryClient = useQueryClient();
  return useMutation(async (data: TenantInitializeData) => {
      return tenantService.initialize(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKey.tenant])
      }
    })
}
