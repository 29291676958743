export const themeDark = {
  colors: {
    primary: '#F15A24',
    webPrimary: '#0D81CF',
    webSecondary: '#58C2EF',
    buttonLinkText: '#707070',
    buttonLinkDisabledBackground: '#CECECE',
    danger: '#E80000',
    darkGray: '#545454',
    gray: '#DCDCDC',
    lightGray: '#F1F1F1',
    backgroundColor: '#3E3E3E',
    sidePanelBackgroundColor: '#1f1f1f',
    white: '#ffffff',
    black: '#000000',
    unazukiBack: '#007ce9',
    clapBack: '#ffbb00',
    proposalBack: '#67d13b',
    thinkingBack: '#4c5c87',
    loadingBackground: '#1F1F1F',
    emoteCoolingBack: '#efefef',
    blueGray: '#AAC7DC',
    editButton: '#4DB9E6',
    deleteButton: '#E23636',
    backButtonBackground: '#4A4A4A',
    stepperDefault: '#BCBCBC',
  }
}