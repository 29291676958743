import * as React from 'react';
import { RefObject } from 'react';
import { css } from '@emotion/react';

const headImagePath = process.env.PUBLIC_URL + '/images/reactions/head.png';
const handImagePath = process.env.PUBLIC_URL + '/images/reactions/hand.png';
const normalBodyImagePath =
  process.env.PUBLIC_URL + '/images/reactions/body1.png';
const clapBodyImagePath =
  process.env.PUBLIC_URL + '/images/reactions/body2.png';
const proposalBodyImagePath =
  process.env.PUBLIC_URL + '/images/reactions/body_proposal.png';
const thinkingBodyImagePath =
  process.env.PUBLIC_URL + '/images/reactions/body_thinking.png';
const nodBodyImagePath =
  process.env.PUBLIC_URL + '/images/reactions/body_nod.png';
const normalHeadImagePath =
  process.env.PUBLIC_URL + '/images/reactions/head_normal.png';
const clapHeadImagePath =
  process.env.PUBLIC_URL + '/images/reactions/head_clap.png';
const proposalHeadImagePath =
  process.env.PUBLIC_URL + '/images/reactions/head_proposal.png';
const thinkingHeadImagePath =
  process.env.PUBLIC_URL + '/images/reactions/head_thinking.png';
const nodHeadImagePath =
  process.env.PUBLIC_URL + '/images/reactions/head_nod.png';

const canvasWidth = 300;
const canvasHeight = 300;
const bodyImageWidth = 300;
const bodyImageHeight = 300;
const headImageWidth = 220;
const headImageHeight = 220;
const handImageWidth = 130;
const handImageHeight = 130;
const actionTypes: ActionType[] = [
  'normal',
  'clap',
  'proposal',
  'thinking',
  'nod',
];
const loopPerSec = 6;
const nodloopPerSec = 5;

type AnimationProps = {
  scale: number;
  wrapperType?: string;
  animationEndEventHandler: (id: number) => void;
  windowid: number;
  interruptFlag?: boolean;
  className?: string;
};

type ActionType = 'normal' | 'clap' | 'proposal' | 'thinking' | 'nod' | any;

const styles = {
  canvasWrapper: css({
    display: 'inline-block',
    float: 'none',
  }),
};

class Animation extends React.Component<AnimationProps> {
  private headImage: HTMLImageElement;
  private readonly leftHandImage: HTMLImageElement;
  private readonly rightHandImage: HTMLImageElement;
  private readonly normalBodyImage: HTMLImageElement;
  private readonly clapBodyImage: HTMLImageElement;
  private readonly proposalBodyImage: HTMLImageElement;
  private readonly thinkingBodyImage: HTMLImageElement;
  private readonly normalHeadImage: HTMLImageElement;
  private readonly clapHeadImage: HTMLImageElement;
  private readonly proposalHeadImage: HTMLImageElement;
  private readonly thinkingHeadImage: HTMLImageElement;
  private readonly nodHeadImage: HTMLImageElement;
  private readonly nodBodyImage: HTMLImageElement;
  private scale: number;
  private distBodyImageWidth: number;
  private distBodyImageHeight: number;
  private distHeadImageWidth: number;
  private distHeadImageHeight: number;
  private distHandImageWidth: number;
  private distHandImageHeight: number;
  private actionType: ActionType;
  private actionFlag: boolean;
  private readonly windowId: number;
  private readonly interruptFlag: boolean;
  private currentTime: number;
  private startTime: number;
  private canvasRef: RefObject<any>;
  private animationIds: any;
  private imageContext?: CanvasRenderingContext2D;
  private bodyImageContext?: CanvasRenderingContext2D;

  constructor(props: AnimationProps) {
    super(props);
    this.headImage = new Image();
    this.leftHandImage = new Image();
    this.rightHandImage = new Image();
    this.normalBodyImage = new Image();
    this.clapBodyImage = new Image();
    this.proposalBodyImage = new Image();
    this.thinkingBodyImage = new Image();
    this.normalHeadImage = new Image();
    this.clapHeadImage = new Image();
    this.proposalHeadImage = new Image();
    this.thinkingHeadImage = new Image();
    this.nodHeadImage = new Image();
    this.nodBodyImage = new Image();

    this.scale = this.props.scale;
    this.distBodyImageWidth = bodyImageWidth * this.scale;
    this.distBodyImageHeight = bodyImageHeight * this.scale;
    this.distHeadImageWidth = headImageWidth * this.scale;
    this.distHeadImageHeight = headImageHeight * this.scale;
    this.distHandImageWidth = handImageWidth * this.scale;
    this.distHandImageHeight = handImageHeight * this.scale;
    this.actionType = actionTypes[0];
    this.actionFlag = false;
    this.windowId = props.windowid;
    this.interruptFlag = props.interruptFlag || false;
    this.currentTime = Date.now();
    this.startTime = this.currentTime;

    this.canvasRef = React.createRef();
  }

  async componentDidMount() {
    await Promise.all([
      this.loadImage(this.headImage, headImagePath),
      this.loadImage(this.leftHandImage, handImagePath),
      this.loadImage(this.rightHandImage, handImagePath),
      this.loadImage(this.normalBodyImage, normalBodyImagePath),
      this.loadImage(this.clapBodyImage, clapBodyImagePath),
      this.loadImage(this.proposalBodyImage, proposalBodyImagePath),
      this.loadImage(this.thinkingBodyImage, thinkingBodyImagePath),
      this.loadImage(this.normalHeadImage, normalHeadImagePath),
      this.loadImage(this.clapHeadImage, clapHeadImagePath),
      this.loadImage(this.proposalHeadImage, proposalHeadImagePath),
      this.loadImage(this.thinkingHeadImage, thinkingHeadImagePath),
      this.loadImage(this.nodHeadImage, nodHeadImagePath),
      this.loadImage(this.nodBodyImage, nodBodyImagePath),
    ]);

    this.initCanvas();
  }

  componentWillUnmount() {
    cancelAnimationFrame(this.animationIds);
  }

  render() {
    if (!(this.scale != this.props.scale)) {
      this.scale = this.props.scale;
      this.distBodyImageWidth = bodyImageWidth * this.scale;
      this.distBodyImageHeight = bodyImageHeight * this.scale;
      this.distHeadImageWidth = headImageWidth * this.scale;
      this.distHeadImageHeight = headImageHeight * this.scale;
      this.distHandImageWidth = handImageWidth * this.scale;
      this.distHandImageHeight = handImageHeight * this.scale;
    }
    return (
      <div
        className={this.props.className}
        css={[this.props.wrapperType !== 'mini' && styles.canvasWrapper]}
      >
        <canvas
          ref="canvas"
          width={canvasWidth * this.scale}
          height={canvasHeight * this.scale}
        />
      </div>
    );
  }

  initCanvas() {
    this.currentTime = Date.now();
    this.startTime = this.currentTime;
    this.renderBackground();
    this.imageContext = (this.refs.canvas as any).getContext('2d');
    this.renderHeadAndHand(this.imageContext, 0);
  }

  renderBackground() {
    var _image;
    switch (this.actionType) {
      case actionTypes[0]:
        _image = this.normalBodyImage;
        break;
      case actionTypes[1]:
        _image = this.clapBodyImage;
        break;
      case actionTypes[2]:
        _image = this.proposalBodyImage;
        break;
      case actionTypes[3]:
        _image = this.thinkingBodyImage;
        break;
      case actionTypes[4]:
        _image = this.nodBodyImage;
        break;
      default:
        _image = this.normalBodyImage;
    }

    this.bodyImageContext = (this.refs.canvas as any).getContext('2d');
    this.bodyImageContext?.drawImage(
      _image,
      0,
      0,
      bodyImageWidth,
      bodyImageHeight,
      0,
      0,
      this.distBodyImageWidth,
      this.distBodyImageHeight
    );
  }

  renderHeadAndHand(imageContext: any, loop: any) {
    this.animationIds = requestAnimationFrame(() => {
      this.renderHeadAndHand(imageContext, loop);
    });

    imageContext.clearRect(0, 0, canvasWidth, canvasHeight);
    this.renderBackground();

    var headVariation_x = 0;
    var headVariation_y = 0;
    var lefthandvarition_x = 0;
    var lefthandvarition_y = 0;
    var righthandvarition_x = 0;
    var righthandvarition_y = 0;

    switch (this.actionType) {
      case actionTypes[1]:
        var clapMoveVarietion = Math.abs(
          Math.sin((10 * ((loop / 2) * Math.PI)) / 180)
        );
        var clapHandMoveWidth = 50;
        this.headImage = this.clapHeadImage;
        headVariation_x = 0;
        headVariation_y = 0;
        lefthandvarition_x = clapHandMoveWidth * clapMoveVarietion;
        lefthandvarition_y = 0;
        righthandvarition_x = -clapHandMoveWidth * clapMoveVarietion;
        righthandvarition_y = 0;
        break;
      case actionTypes[2]:
        var proposalMoveVarietion = Math.abs(
          Math.sin((4 * ((loop / 2) * Math.PI)) / 180)
        );
        var proposalLeftHandMoveWidth = -20;
        var proposalLeftHandMoveHeight = -120;
        this.headImage = this.proposalHeadImage;
        if (loop < 45 || 315 < loop) {
          headVariation_x = 0;
          headVariation_y = 0;
          lefthandvarition_x =
            proposalLeftHandMoveWidth * proposalMoveVarietion;
          lefthandvarition_y =
            proposalLeftHandMoveHeight * proposalMoveVarietion;
          righthandvarition_x = 0;
          righthandvarition_y = 0;
        } else {
          headVariation_x = 0;
          headVariation_y = 0;
          lefthandvarition_x = proposalLeftHandMoveWidth;
          lefthandvarition_y = proposalLeftHandMoveHeight;
          righthandvarition_x = 0;
          righthandvarition_y = 0;
        }
        break;
      case actionTypes[3]:
        var thinkingMoveVarietion = Math.abs(
          Math.sin((4 * ((loop / 2) * Math.PI)) / 180)
        );
        var thinkingLeftHandMoveWidth = 30;
        var thinkingLeftHandMoveHeight = -40;
        this.headImage = this.thinkingHeadImage;

        if (loop < 45 || 315 < loop) {
          lefthandvarition_x =
            thinkingLeftHandMoveWidth * thinkingMoveVarietion;
          lefthandvarition_y =
            thinkingLeftHandMoveHeight * thinkingMoveVarietion;
        } else {
          lefthandvarition_x = thinkingLeftHandMoveWidth;
          lefthandvarition_y = thinkingLeftHandMoveHeight;
        }
        break;
      case actionTypes[4]:
        var nodMoveVariation = Math.abs(
          Math.sin((6 * ((loop / 2) * Math.PI)) / 180)
        );
        var nodMoveHeight = 40;
        var headImageChangeThreshold = 0.3;
        if (nodMoveVariation < headImageChangeThreshold) {
          this.headImage = this.normalHeadImage;
        } else {
          this.headImage = this.nodHeadImage;
        }
        headVariation_y = nodMoveHeight * nodMoveVariation;
        break;
      default:
        this.headImage = this.normalHeadImage;
    }

    // head
    imageContext.drawImage(
      this.headImage,
      0,
      0,
      headImageWidth,
      headImageHeight,
      ((canvasWidth - headImageWidth) / 2 + headVariation_x) * this.scale,
      headVariation_y * this.scale,
      this.distHeadImageWidth,
      this.distHeadImageHeight
    );

    // leftHand
    imageContext.drawImage(
      this.leftHandImage,
      0,
      0,
      handImageWidth,
      handImageHeight,
      lefthandvarition_x * this.scale,
      (canvasHeight - handImageHeight + lefthandvarition_y) * this.scale,
      this.distHandImageWidth,
      this.distHandImageHeight
    );

    // rightHand
    imageContext.drawImage(
      this.rightHandImage,
      0,
      0,
      handImageWidth,
      handImageHeight,
      (canvasWidth - handImageWidth + righthandvarition_x) * this.scale,
      (canvasHeight - handImageHeight + righthandvarition_y) * this.scale,
      this.distHandImageWidth,
      this.distHandImageHeight
    );

    if (this.actionFlag) {
      var now = Date.now();
      var sinsStart = now - this.startTime;
      if (this.actionType != actionTypes[4]) {
        loop = (sinsStart / (loopPerSec * 1000)) * 360;
      } else {
        loop = (sinsStart / (nodloopPerSec * 1000)) * 360;
      }

      if (this.actionType == actionTypes[0]) {
        loop = 360;
      }
    }

    if (loop >= 360) {
      loop = 0;
      this.actionType = actionTypes[0];
      this.actionFlag = false;
      this.animationEndEvent(this.windowId);
    }
  }

  interrupt() {
    cancelAnimationFrame(this.animationIds);
    this.initCanvas();
  }

  loadImage(img: HTMLImageElement, src: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      img.onload = () => resolve(img);
      img.onerror = (e) => reject(e);
      img.src = src;
    });
  }

  reactionHandler(reactionId: any) {
    if (!this.interruptFlag) {
      if (this.actionType === actionTypes[0]) {
        this.actionType = actionTypes[reactionId];
        this.actionFlag = true;
        this.startTime = Date.now();
      }
    } else {
      this.actionType = actionTypes[reactionId];
      this.actionFlag = true;
      this.interrupt();
    }
  }

  getActionState() {
    return this.actionFlag;
  }

  getActionType() {
    return this.actionType;
  }

  animationEndEvent(windowId: number) {
    this.actionFlag = false;
    this.props.animationEndEventHandler(windowId);
  }
}

export default Animation;
