import React from 'react';
import { css, Theme, useTheme } from '@emotion/react';
import { ContactButton } from '../component/ContactButton';

const makeStyles = (theme: Theme) => ({
  root: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: theme.colors.backgroundColor,
    textAlign: 'center',
    height: '100vh',
    '@media (max-width: 400px)': css({
      backgroundColor: theme.colors.sidePanelBackgroundColor,
    }),
    img: css({
      '@media (max-width: 400px)': css({
        width: 200,
      }),
    }),
  }),
  caption: css({
    marginTop: '1rem',
    marginBottom: '1rem',
    color: theme.colors.blueGray,
    fontWeight: 'bold',
    '@media (max-width: 400px)': css({
      fontSize: 22,
    }),
  }),
  description: css({
    color: theme.colors.white,
    '@media (max-width: 400px)': css({
      fontSize: 13,
    }),
  }),
});

const UnSubscribe = () => {
  const theme = useTheme();
  const style = makeStyles(theme);

  return (
    <div css={style.root}>
      <h3 css={style.caption}>ワカチエのご利用にはお申し込みが必要です</h3>
      <div>
        <img
          alt="zizou"
          src={`${process.env.PUBLIC_URL}/images/unsupported/zizou.png`}
        />
      </div>
      <p css={style.description}>
        3ヶ月無料にてお試しいただけます。
        <br />
        こちらよりお問い合わせください。
        <br />
        <ContactButton />
      </p>
    </div>
  );
};

export default UnSubscribe;
