import React from 'react';
import { css, keyframes } from "@emotion/react";
import { useFadeInFadeOut } from "../hooks/useFadeInFadeOut";
import Stamps from "../data/Stamps.json";

interface Props {
  stampId: number,
  stampScale: number,
  isOwnStamp: boolean,
  displayTime: number,
  onFadeOutComplete?: (stamp: any) => void,
  visible?: boolean
  props?: any
}


const stampFadeInKeyFrames = keyframes({
  '0%': {
    opacity: 0,
    transform: 'translateX(10px)'
  },
  '100%': {
    opacity: 1,
    transform: 'translateX(0)'
  }
})

const stampFadeOutKeyFrames = keyframes({
  '0%': {
    opacity: 1,
    transform: 'translateX(0)'
  },
  '100%': {
    opacity: 0,
    transform: 'translateX(-10px)'
  }
})

const styles = {
  ownStampDescription: css({
    position: 'absolute',
    pointerEvents: 'none',
    width: '100%',
    img: {
      width: '100%',
      height: 'auto',
    }
  }),
  stamp: css({
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > img': {
      width: '85%',
      height: 'auto'
    }
  }),
  stampFadeIn: css({
    animation: `${stampFadeInKeyFrames} 0.2s ease-in-out forwards`
  }),
  stampFadeOut: css({
    animation: `${stampFadeOutKeyFrames} 1s ease-in-out forwards`
  }),
  invisible: css({
    display: 'none'
  })
}


export const FadeInFadeOutStamp = ({
  stampId,
  stampScale,
  isOwnStamp,
  displayTime,
  onFadeOutComplete,
  visible,
  props
}: Props) => {
  const stampList = Stamps.stamps;
  const stamp = stampList[stampId - 1];

  const ownLayerPath = '/images/stamps/overlay_you.svg';
  const stampStyle = {
    width: stamp.size * stampScale,
    height: stamp.size * stampScale,
  };

  const { isFadeIn, isFadeOut } = useFadeInFadeOut({
    displayTime,
    fadeInTime: 200,
    fadeOutTime: 1000,
    onComplete: () => onFadeOutComplete && onFadeOutComplete(stamp)
  })

  return (
    <div
      css={[
        styles.stamp,
        isFadeIn && styles.stampFadeIn,
        isFadeOut && styles.stampFadeOut,
        !visible && styles.invisible
      ]}
      style={stampStyle}
    >
      <img
        src={`${process.env.PUBLIC_URL}` + stamp.imagePath}
        alt={stamp.discription}
        onClick={() => stampClickHandler(isOwnStamp)}
      />
      {isOwnStamp && (
        <div
          css={styles.ownStampDescription}
          onClick={() => stampClickHandler(isOwnStamp)}
        >
          <img src={`${process.env.PUBLIC_URL}` + ownLayerPath} alt="own" />
        </div>
      )}
    </div>
  );
  function stampClickHandler(isOwnStamp: any) {
    if (isOwnStamp) {
      props.sendStamp(0);
    }
  }};