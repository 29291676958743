import React from 'react';
import { Form as FormikForm, Formik } from "formik"
import { User } from "./UserManagementTable";
import * as yup from "yup"
import { css, Theme, useTheme } from "@emotion/react";
import { SubmitButton } from "./SubmitButton";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { SerializedStyles } from "@emotion/serialize";

interface Props {
  show?: boolean,
  onClose?: () => void,
  onNext: (data: User) => void,
  onBack: () => void,
  initialValues?: User,
  classes?: {
    root?: SerializedStyles,
    formWrapper?: SerializedStyles,
  }
}

const schema = yup.object().shape({
  displayName: yup.string().required(),
  email: yup.string().email().required(),
  id: yup.number(),
});

const makeStyles = (theme: Theme) => ({
  root: css({
    textAlign: 'center'
  }),
  label: css({
    textAlign: 'right',
  }),
  input: css({
    maxWidth: 700,
  }),
  tenantId : css({
    textAlign: 'left',
  }),
  button: css({
    maxWidth: 320,
    margin: '0 50px'
  }),
  buttonWrapper: css({
    margin: '50px 0 30px 0'
  }),
  formWrapper: css({
    backgroundColor: theme.colors.lightGray,
  }),
  backButton: css({
    backgroundColor: theme.colors.backButtonBackground
  })
})

type TextInputProps = {
  label: string,
  value: string,
  onChange: (e: any) => void,
  isInvalid: boolean | undefined,
  onBlur: (e: any) => void,
  name: string,
  error: string | undefined,
  classes?: {
    label: SerializedStyles,
    input: SerializedStyles
  }
}

const TextInput = ({label, value, onChange, isInvalid, onBlur, name, error, classes}: TextInputProps) => (
  <Form.Group as={Row}>
    <Col css={classes?.label} sm={4}>{label}</Col>
    <Col css={classes?.input}>
      <InputGroup hasValidation>
        <Form.Control
          type="text"
          name={name}
          value={value}
          onChange={onChange}
          isInvalid={isInvalid}
          onBlur={onBlur}
        />
        <Form.Control.Feedback type="invalid">
          {error}
        </Form.Control.Feedback>
      </InputGroup>
    </Col>
  </Form.Group>
)


export const TenantAdministratorFormStep = ({ onNext, initialValues, onBack, classes }: Props) => {
  const styles = makeStyles(useTheme());

  return (
    <div css={[styles.root, classes?.root]}>
      <Formik
        enableReinitialize
        onSubmit={onNext}
        initialValues={initialValues ?? {
          id: 0,
          displayName: '',
          email: '',
        }}
        validationSchema={schema}
      >
        {
          ({
             values,
             handleChange,
             errors,
             handleBlur,
             touched,
             isSubmitting
           }) => (
            <>
              <FormikForm>
                <div css={[styles.formWrapper, classes?.formWrapper]}>
                  <TextInput
                    label="テナント管理者様お名前"
                    name="displayName"
                    value={values.displayName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!!errors.displayName && !!touched.displayName}
                    classes={styles}
                    error={errors.displayName}
                  />
                  <TextInput
                    label="管理者様メールアドレス"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!!errors.email && !!touched.email}
                    classes={styles}
                    error={errors.email}
                  />
                </div>

                <div css={styles.buttonWrapper}>
                  <SubmitButton
                    disabled={isSubmitting}
                    onClick={onBack}
                    css={[styles.button, styles.backButton]}
                  >
                    戻る
                  </SubmitButton>
                  <SubmitButton
                    type="submit"
                    disabled={isSubmitting}
                    css={styles.button}
                  >
                    次へ
                  </SubmitButton>
                </div>
              </FormikForm>
            </>
          )}
      </Formik>
    </div>
  )
};