import React, { ReactNode } from 'react';
import Modal from "react-modal";
import { BsXLg } from "react-icons/bs";
import { css, Theme, useTheme } from "@emotion/react";
import { TextButton } from "./TextButton";

interface Props {
  isOpen: boolean;
  onClose?: () => void;
  children?: ReactNode
};

const makeStyles = (theme: Theme) => ({
  meetingModal: css({
    position: 'absolute',
    width: '100%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.colors.gray,
    padding: '40px 5px 20px 5px',
    transition: '800ms',
    fontSize: 11,
    zIndex: 9999
  }),
  closeModal: css({
    border: 'none',
    display: 'block',
    margin: 'auto',
    backgroundColor: 'unset',
    cursor: 'pointer',
    fontSize: '1.5rem',
    position: 'absolute',
    zIndex: 10,
    top: -5,
    right: 0
  }),
  contentWrapper: css({
    backgroundColor: theme.colors.white,
    borderRadius: 5,
    width: '100%',
    padding: '10px 15px 10px 15px'
  })
})

export const MeetingModal = ({ isOpen, onClose, children }: Props) => {
  const theme = useTheme();
  const styles = makeStyles(theme)
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="ハラスメント通報"
      css={styles.meetingModal}
      overlayClassName="modalOverlay"
    >
      <TextButton
        css={styles.closeModal}
        onClick={() => onClose && onClose()}
      >
        <BsXLg/>
      </TextButton>
      <div css={styles.contentWrapper}>
        {children}
      </div>
    </Modal>
  )
};