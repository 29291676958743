import React, { useEffect } from 'react';
import { useConfirmTenantAdministrator } from "../hooks/useConfirmTenantAdministrator";
import { useAsync } from "react-use";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useApiTokenForBrowser } from "../hooks/browser/useApiTokenForBrowser";
import { BrowserLayout } from "../component/BrowserLayout";

export const ConfirmWithToken = ({}) => {
  const [ searchParams] = useSearchParams();
  const confirmationToken = searchParams.get('confirmationToken');
  const { mutateAsync, isSuccess} = useConfirmTenantAdministrator();
  const { token: apiToken } = useApiTokenForBrowser();
  const navigate = useNavigate();

  useAsync(async () => {
    if(confirmationToken == undefined){
      alert('トークンが存在しません');
      return
    }

    if(apiToken == undefined){
      return
    }
    await mutateAsync(confirmationToken);
  }, [apiToken])

  useEffect(() => {
    if(isSuccess){
      navigate('/admin-page');
    }
  }, [isSuccess])
  return (
    <BrowserLayout>
      <div/>
    </BrowserLayout>
  )
};