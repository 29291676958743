import React, { ReactNode } from 'react';
import { css, Theme, useTheme } from "@emotion/react";

interface Props {
  children: ReactNode;
  type?: 'button' | 'submit' | 'reset';
  onClick?: () => void | Promise<void>
  disabled?: boolean
};

const makeStyles = (theme: Theme) => ({
  root: css({
    backgroundColor: theme.colors.primary,
    borderColor: theme.colors.primary,
    border: 'none',
    color: theme.colors.white,
    width: 373,
    fontSize: 32,
    borderRadius: 40,
    fontWeight: 'bold',
    padding: '10px 0',
  })
})

export const SubmitButton = ({ children, ...props}: Props) => {
  const theme = useTheme();
  const styles = makeStyles(theme);
  return (
      <button css={styles.root} {...props}>
        {children}
      </button>
  )
};