import { useQuery } from "@tanstack/react-query";
import { queryKey } from "../utils/QueryKey";
import { TenantSettingService } from "../services/TenantSettingService";
import { useApiTokenForBrowser } from "./browser/useApiTokenForBrowser";

export const useAllHarassmentManagerEmailAddress = () => {
  const { token } = useApiTokenForBrowser();
  const tenantSettingService = new TenantSettingService(token);
  const { data, error, } = useQuery(
    [queryKey.harassmentManagerEmailAddress],
    async () => tenantSettingService.fetchAllHarassmentManagerMailAddress(),
    { enabled: token != undefined}
  )

  return { data, error}
}