import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { SerializedStyles } from '@emotion/serialize';
import { css } from '@emotion/react';

interface Props {
  label: string;
  value: boolean;
  onChange: (value: any) => void;
  error?: string;
  name: string;
  onBler?: (e: any) => void;
  classes?: {
    label?: SerializedStyles;
    input?: SerializedStyles;
    root?: SerializedStyles;
  };
}

const styles = {
  root: css({
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    marginBottom: '0rem',
  }),
  label: css({
    flexBasis: 250,
    fontSize: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginRight: 20,
  }),
  input: css({
    height: 50,
    scale: '1.8',
  }),
};

export const ModalCheckbox = ({
  label,
  value,
  onChange,
  name,
  error,
  classes,
}: Props) => {
  return (
    <Form.Group css={[styles.root, classes?.root]}>
      <Form.Check
        type="checkbox"
        name={name}
        label={label}
        checked={value}
        onChange={onChange}
        css={[styles.input, classes?.input]}
        className="custom-checkbox"
      />
    </Form.Group>
  );
};
