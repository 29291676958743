import { useQuery } from "@tanstack/react-query";
import { getClientToken } from "../utils/TokenManager";
import jwtDecode from "jwt-decode";

export const useClientToken = () => {
  const { data, error } = useQuery(
    ['clientToken'],
    () => getClientToken()
    )

  let decodedToken = undefined;
  if(data){
    decodedToken = jwtDecode<any>(data)
  }


  return { token: data, error, decodedToken }
}