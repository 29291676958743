import axios, { Axios } from 'axios';
import { getCachedApiToken } from '../utils/TokenManager';

type SendResultPageUrlData = { roomId: string; pageUrl: string };
export type SendFeedbackData = {
  RoomId: string;
  UserId?: string;
  OpinionText: string | null;
};
export type SendHarassmentData = {
  RoomId: string;
  UserId?: string;
  HarassmentText: string | null;
};

export type StarRating = {
  starRating: number;
  creatDate: Date;
  id: number;
  roomId: string;
  userId?: string;
};

export type Feedback = {
  id: string;
  opinionText: string;
  creatDate: string;
};

export class FeedbackService {
  private readonly axios: Axios;
  private readonly endpointBase: string;
  private readonly roomId: string;

  constructor({
    roomId,
  }: {
    apiToken?: string | null;
    endpointBase?: string;
    roomId: string;
  }) {
    this.axios = axios.create({
      headers: { Authorization: `Bearer ${getCachedApiToken()}` },
    });
    this.endpointBase =
      process.env.REACT_APP_SIGNALR_SERVER_URL + '/api/feedback';
    this.roomId = roomId;
  }

  private async get(endpoint: string): Promise<any> {
    const ep = this.endpointBase + '/' + endpoint;
    const response = await this.axios.get(ep).catch((error: any) => {
      throw error;
    });
    return response.data;
  }

  private async delete(endpoint: string): Promise<any> {
    const ep = this.endpointBase + '/' + endpoint;
    const response = await this.axios.delete(ep).catch((error: any) => {
      throw error;
    });
    return response.data;
  }

  private async post(endpoint: string, postData: any): Promise<any> {
    const ep = this.endpointBase + '/' + endpoint;
    const response = await this.axios.post(ep, postData).catch((error: any) => {
      throw error;
    });
    return response.data;
  }

  public async fetchRegisteredMyFeedback(): Promise<number | undefined> {
    const endpoint = this.roomId + '/myItem';
    const data = await this.get(endpoint);
    return data?.starRating;
  }

  public fetchRegisteredAllFeedback(): Promise<Feedback[]> {
    const endpoint = this.roomId + '/allItems';
    return this.get(endpoint);
  }

  public fetchRegisteredAllHarassment(): Promise<any> {
    const endpoint = `${this.roomId}/harassment/allItems`;
    return this.get(endpoint);
  }

  public sendResultPageUrl(data: SendResultPageUrlData): Promise<any> {
    return this.post('SendUrl', data);
  }

  public sendFeedback(data: SendFeedbackData): Promise<any> {
    return this.post('', data);
  }

  public sendHarassment(data: SendHarassmentData): Promise<any> {
    return this.post('harassment', data);
  }

  public fetchRegisteredFeedback(accountId?: string): Promise<any> {
    const endpoint = `${this.roomId}/myItem/${accountId ?? ''}`;
    return this.get(endpoint);
  }

  public fetchRegisteredHarassment(accountId?: string): Promise<any> {
    const endpoint = `${this.roomId}/harassment/myItem/${accountId ?? ''}`;
    return this.get(endpoint);
  }

  public deleteHarassment(): Promise<any> {
    const endpoint = `${this.roomId}/harassment/myItem`;
    return this.delete(endpoint);
  }

  public fetchMyStarRatings(): Promise<StarRating[]> {
    const endpoint = `${this.roomId}/myStarRatings`;
    return this.get(endpoint);
  }

  public fetchAllStarRatings(): Promise<StarRating[]> {
    const endpoint = `${this.roomId}/allStarRatings`;
    return this.get(endpoint);
  }

  public createStarRating(data: any): Promise<any> {
    const endpoint = `starRating`;
    return this.post(endpoint, data);
  }

  public createFeedbackAndStarRating(data: any): Promise<any> {
    const endpoint = `FeedbackAndStarRating`;
    return this.post(endpoint, data);
  }
}
