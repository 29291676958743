import { useAsync } from "react-use";
import { useState } from "react";
import { delay } from "../utils/delay";

type Arg ={
  fadeInTime: number,
  fadeOutTime: number,
  displayTime: number,
  onComplete?: () => void
}

export const useFadeInFadeOut = ({displayTime, fadeInTime,fadeOutTime, onComplete}: Arg) => {
  const [isFadeIn, setIsFadeIn] = useState(true);
  const [isFadeOut, setIsFadeOut] = useState(false);
  useAsync(async () => {
    await delay(fadeInTime);
    setIsFadeIn(false);
    await delay(displayTime);
    setIsFadeOut(true);
    await delay(fadeOutTime);
    setIsFadeOut(false);
    if (onComplete) {
      onComplete();
    }
  })

  return { isFadeIn, isFadeOut }
}