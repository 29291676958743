import axios, { Axios } from 'axios';
import { getCachedApiToken } from '../utils/TokenManager';

export type TenantRegistrationData = {
  Tenant: {
    TenantName: string;
    TenantId: string;
  };
  Representative: {
    DisplayName: string;
    Email: string;
  }
};

export type TenantInitializeData = {
  tenant: {
    tenantName: string;
    tenantId: string;
  };
  representative: {
    displayName: string;
    email: string;
  },
  tenantAdministrator: {
    displayName: string;
    email: string;
  }
}

export class TenantService {
  private readonly axios: Axios;
  private readonly endpointBase: string;

  constructor(apiToken: string = '') {
    const token = apiToken !== '' ? apiToken : getCachedApiToken();

    this.axios = axios.create({
      headers: { Authorization: `Bearer ${token}` },
    });
    this.endpointBase =
      process.env.REACT_APP_SIGNALR_SERVER_URL + '/api';
  }

  private async get(endpoint: string): Promise<any> {
    const ep = this.endpointBase + '/' + endpoint;
    const response = await this.axios.get(ep).catch((error: any) => {
      throw error;
    });
    return response.data;
  }

  private async delete(endpoint: string): Promise<any> {
    const ep = this.endpointBase + '/' + endpoint;
    const response = await this.axios.delete(ep).catch((error: any) => {
      throw error;
    });
    return response.data;
  }

  private async post(endpoint: string, postData: any): Promise<any> {
    const ep = this.endpointBase + '/' + endpoint;
    const response = await this.axios.post(ep, postData).catch((error: any) => {
      throw error;
    });
    return response.data;
  }

  public async tenantRegistration(data: TenantRegistrationData): Promise<any> {
    return this.post('tenant', data);
  }

  public async getTenant(tenantId: string): Promise<any> {
    return this.get(`tenant/${tenantId}`);
  }

  public async initialize(data: TenantInitializeData): Promise<any> {
    return this.post(`tenant/initialize`, data);
  }
}
