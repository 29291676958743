import { useApiTokenForBrowser } from "./browser/useApiTokenForBrowser";
import { RepresentativeService } from "../services/RepresentativeService";
import { useQuery } from "@tanstack/react-query";
import { queryKey } from "../utils/QueryKey";
import { useEffect, useState } from "react";

export const useIsRepresentative = (): {
  isRepresentative: boolean | undefined,
  isFetching: boolean
} => {
  const { token } = useApiTokenForBrowser();
  const representativeService = new RepresentativeService(token);
  const [isRepresentative, setIsRepresentative] = useState<boolean>();
  const { data, error, isFetching } = useQuery(
    [queryKey.myRepresentative],
    async () => representativeService.fetchMe(),
    { enabled: token != undefined, retry: false }
  )
  useEffect(() => {
    if (data) {
      setIsRepresentative(true);
    } else if (error) {
      setIsRepresentative(false);
    }
  },[data, error]);

  return {
    isRepresentative,
    isFetching
  }
}