import React, { useEffect } from 'react';
import { BrowserLayout } from "../component/BrowserLayout";
import { PageTitle } from "../component/PageTitle";
import { Step, StepButton, Stepper } from "@mui/material";
import { RepresentativeAndTenantFormStep } from "../component/web/RepresentativeAndTenantFormStep";
import { TenantAdministratorFormStep } from "../component/web/TenantAdministratorFormStep";
import { css, Theme, useTheme } from "@emotion/react";
import { SubmitButton } from "../component/web/SubmitButton";
import { useInitializeTenant } from "../hooks/useInitializeTenant";
import { useNavigate } from "react-router";

type State = {
  formValue: {
    tenant: {
      tenantId: string,
      tenantName: string,
    },
    representative: {
      displayName: string,
      email: string,
    },
    tenantAdministrator: {
      displayName: string,
      email: string,
    },
  },
  activeStep: number,
}

const makeStyles = (theme: Theme) => ({
  formWrapper: css({
    fontSize: 24,
    padding: '40px 0 20px 0'
  }),
  stepperContainer: css({
    padding: '0 300px',
    marginBottom: 30
  }),
  caption: css({
    textAlign: 'center',
    fontSize: 24,
    marginBottom: 54
  }),
  detail : css({
    fontSize: 20
  }),
  inputted: css({
    backgroundColor: theme.colors.white,
    border: `1px solid ${theme.colors.darkGray}`,
    padding: 40,
    fontSize: 19
  }),
  inputtedWrapper: css({
    backgroundColor: theme.colors.lightGray,
    padding: '40px 80px'
  }),
  button: css({
    maxWidth: 320,
    margin: '0 50px'
  }),
  buttonWrapper: css({
    margin: '50px 0 30px 0',
    display: 'flex',
    justifyContent: 'center'
  }),
  backButton: css({
    backgroundColor: theme.colors.backButtonBackground
  }),
  border: css({
    background: theme.colors.darkGray
  }),
  stepLabel: css({
    color: theme.colors.darkGray,
    cursor: 'default',
    '& .MuiStepButton-root': css({
      cursor: 'default',
    }),
    '& .MuiStepIcon-root' : css({
      width: 38,
      height: 38,
      color: theme.colors.stepperDefault
    }),
    '& .MuiStepIcon-root.Mui-active' : css({
      color: theme.colors.primary
    }),
    '& .MuiStepIcon-root.Mui-completed' : css({
      color: theme.colors.backButtonBackground
    }),
    '& .MuiStepLabel-iconContainer' : css({
      paddingRight: 0
    }),
  }),
  stepper: css({
    '& span': css({
      borderTopWidth: 10,
      borderColor: theme.colors.stepperDefault
    }),
    '& .MuiStep-root': css({
      padding: 0
    })
  })
})

const labelAndValueStyles = {
  wrapper: css({
    display: 'flex',
    alignItems: 'center',
    marginBottom: 5,
  }),
  label: css({
    width: '50%',
    textAlign: 'right',
  }),
  value: css({
    width: '50%',
    paddingLeft: 10,
    fontWeight: 'bold',
  })
}

const steps = [1,2,3];

const LabelAndValue = ({label, value}: {label: string, value: string}) => {
  return(
  <div css={labelAndValueStyles.wrapper}>
    <div css={labelAndValueStyles.label}>{label}:</div>
    <div css={labelAndValueStyles.value}>{value}</div>
  </div>
)}

export const InitialSetting = ({}) => {
  const [state, setState] = React.useState<State>({
    formValue: {
      tenant: {
        tenantId: '',
        tenantName: '',
      },
      representative: {
        displayName: '',
        email: '',
      },
      tenantAdministrator: {
        displayName: '',
        email: '',
      }
    },
    activeStep: 0,
  })

  const styles = makeStyles(useTheme());
  const initializeTenant = useInitializeTenant();
  const navigate = useNavigate();

  useEffect(() => {
    if(initializeTenant.isSuccess){
      navigate('/initialize-completed');
    }
  }, [initializeTenant.isSuccess])


  const handleBack = () => {
    setState({ ...state, activeStep: state.activeStep - 1 });
  }

  const handleChangeTenantAndRepresentative = (value: Omit<State["formValue"], "tenantAdministrator">) => {
    setState({ ...state, formValue: { ...state.formValue, ...value }, activeStep: state.activeStep + 1 });
  }

  const handleChangeTenantAdministrator = (value: State["formValue"]["tenantAdministrator"]) => {
    setState({ ...state, formValue: { ...state.formValue, tenantAdministrator: value }, activeStep: state.activeStep + 1 });
  }

  const handleSubmit = async () => {
    const result = await initializeTenant.mutateAsync(state.formValue).catch((e) => {
      if(e.response?.data?.detail){
        alert(e.response?.data?.detail);
      } else {
        alert("エラーが発生しました");
        console.log(e)
      }
    });
    if(result){
      navigate('/initialize-completed');
    }
  }

  const isComplete = (index: number) => {
    return state.activeStep > index;
  }

  return (
    <BrowserLayout>
      <PageTitle>
        初期設定
      </PageTitle>
      <div css={styles.stepperContainer}>
        <Stepper nonLinear activeStep={state.activeStep} css={styles.stepper}>
          {steps.map((label, index) => (
            <Step key={label} completed={isComplete(index)}>
              <StepButton
                color="inherit"
                css={styles.stepLabel}
                disabled={true}
              >
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </div>
      <div>
        {state.activeStep === 0 && (
          <div>
            <div css={styles.caption}>
              <p>
                テナント情報とご担当者様の情報を入力してください
              </p>
            </div>
            <div>
              <RepresentativeAndTenantFormStep
                onNext={handleChangeTenantAndRepresentative}
                classes={{
                  formWrapper: styles.formWrapper
                }}
              />
            </div>
          </div>
        )}
        {state.activeStep === 1 && (
          <div>
            <div css={styles.caption}>
              <p>
                ワカチエを利用するテナントの管理者様の情報を入力してください。
              </p>
              <p css={styles.detail}>
                テナント管理者様の情報は「管理者の同意フロー」を実行依頼で使用します。<br />
                「管理者の同意フロー」はワカチエのAPIに対してアクセス許可を付与していただく手続きです。
              </p>
            </div>
            <div>
              <TenantAdministratorFormStep
                onNext={handleChangeTenantAdministrator}
                onBack={handleBack}
                classes={{
                  formWrapper: styles.formWrapper
                }}
              />
            </div>
          </div>
        )}
        {state.activeStep === 2 && (
          <div>
            <div css={styles.caption}>
              <p>
                以下の情報で登録いたします。<br/>
                「登録」が完了すると、入力頂いたメールアドレスに登録完了メールが送られます。
              </p>
            </div>
            <div css={styles.inputtedWrapper}>
              <div css={styles.inputted}>
                <LabelAndValue label="テナントID" value={state.formValue.tenant.tenantId}/>
                <LabelAndValue label="会社名" value={state.formValue.tenant.tenantName}/>
                <LabelAndValue label="ワカチエ担当者様お名前" value={state.formValue.representative.displayName}/>
                <LabelAndValue label="ワカチエ担当者様メールアドレス" value={state.formValue.representative.email}/>
                <hr css={styles.border}/>
                <LabelAndValue label="テナント管理者様お名前" value={state.formValue.tenantAdministrator.displayName}/>
                <LabelAndValue label="テナント管理者様メールアドレス" value={state.formValue.tenantAdministrator.email}/>
              </div>
            </div>
            <div css={styles.buttonWrapper}>
              <SubmitButton
                onClick={handleBack}
                css={[styles.button, styles.backButton]}
              >
                戻る
              </SubmitButton>
              <SubmitButton
                type="submit"
                disabled={initializeTenant.isLoading}
                css={styles.button}
                onClick={handleSubmit}
              >
                登録
              </SubmitButton>
            </div>
          </div>
        )}
      </div>
    </BrowserLayout>
  )
};