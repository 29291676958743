import React from 'react';
import { css, Theme, useTheme } from "@emotion/react";

interface Props {
  children: React.ReactNode;
  color?: 'red' | 'blue' | 'black';
  onClick?: () => Promise<void> | void;
  disabled?: boolean;
}

const makeStyles = (theme: Theme) => ({
  text: css({
    borderBottom: '1px solid',
    fontWeight: 'bold',
    display: 'inline-block',
    fontSize: 24,
    cursor: 'pointer',
  }),
  red: css({
    borderColor: theme.colors.deleteButton,
    color: theme.colors.deleteButton,
  }),
  blue: css({
    borderColor: theme.colors.editButton,
    color: theme.colors.editButton,
  }),
  black: css({
    borderColor: theme.colors.black,
    color: theme.colors.black,
  }),
  disabled: css({
    opacity: 0.5,
    cursor: 'default',
  })
})

export const ManagementPageTextButton = ({ children, color = 'black', onClick, disabled }: Props) => {
  const styles = makeStyles(useTheme());
  return (
    <div onClick={() => {
      if (!disabled && onClick) {
        onClick();
      }
    }}>
      <p css={[styles.text, styles[color], disabled && styles.disabled]}>
        {children}
      </p>
    </div>
  )
};