import { SubscriptionService } from "../services/subscriptionService";
import { queryKey } from "../utils/QueryKey";
import { useQuery } from "@tanstack/react-query";
import { useApiTokenForBrowser } from "./browser/useApiTokenForBrowser";

export const useGetSubscription = () => {
  const { token } = useApiTokenForBrowser()
  const subscriptionService = new SubscriptionService('', token);
  const { data, error } = useQuery([queryKey.subscription], async () =>
    subscriptionService.getSubscription(),
    { enabled: token != undefined }
  );

  return { data, error }
}