import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { useClient } from '../hooks/useClient';
import { CacheService } from '../services/cacheService';
import { GraphService } from '../services/graphService';
import { useNavigate } from 'react-router';

interface Props {
  children: ReactNode;
}

export interface ServiceContext {
  services: {
    cache?: CacheService;
    graph?: GraphService;
  };
  servicesError: Error | null;
}

const ServiceContext = React.createContext<ServiceContext>({
  services: {},
  servicesError: null,
});

export const ServiceContextProvider = ({ children }: Props) => {
  const [client, error] = useClient();
  const [services, setServices] = useState<ServiceContext['services']>({});
  const [servicesError, setServicesError] = useState<any>();
  const navigate = useNavigate();

  useEffect(() => {
    if (client == undefined) {
      return;
    }

    setServices({
      cache: new CacheService(),
      graph: new GraphService(client),
    });
  }, [client]);

  useEffect(() => {
    console.log(error);
    if (error?.response.status === 400) {
      //navigate('/external-user');
    }
    setServicesError(error);
  }, [error]);

  const value = { services, servicesError };
  return (
    <ServiceContext.Provider value={value}>{children}</ServiceContext.Provider>
  );
};

export const useServiceContext = (): ServiceContext => {
  const value = useContext(ServiceContext);
  if (!value) {
    throw new Error('The context is not initialized: servicecontext');
  }
  return value;
};
