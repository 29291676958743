import React from 'react';
import { css, Theme, useTheme } from '@emotion/react';
import { FiExternalLink } from 'react-icons/fi';

const makeStyles = (theme: Theme) => ({
  contactButton: css({
    display: 'inline-flex',
    alignItems: 'center',
    borderRadius: 4,
    height: 36,
    paddingLeft: 12,
    paddingRight: 12,
    backgroundColor: theme.colors.gray,
    marginTop: '1rem',
  }),
  ExternalLinkIcon: css({
    marginRight: 8,
  }),
});

export const ContactButton = () => {
  const theme = useTheme();
  const styles = makeStyles(theme);

  const buttonHandler = () => {
    window.open('https://wakachie.com/contact/');
  };

  return (
    <button onClick={() => buttonHandler()} css={styles.contactButton}>
      <FiExternalLink css={styles.ExternalLinkIcon} />
      お問い合わせ
    </button>
  );
};
