import React, { useEffect, useState } from 'react';
import { FeedbackForm, FeedbackFormValues } from './FeedbackForm';
import { MeetingModal } from './MeetingModal';
import { css } from '@emotion/react';
import { useMyFeedback } from '../hooks/useMyFeedback';
import { useCreateFeedbackAndStarRating } from '../hooks/useCreateFeedbackAndStarRatingMutation';
import { useMyStarRatings } from '../hooks/useMyStarRatings';
import { StarRating } from '../services/feedbackService';

interface Props {
  roomId: string;
  localAccountId: string;
  onClose?: () => void;
  isOpen?: boolean;
}

interface State {
  feedbackText: string;
  ratingValue: number;
  feedbackId: number;
  selectedRatingValue: number;
}

const styles = {
  feedbackSendButton: css({
    width: 126,
    marginBottom: 0,
    marginLeft: 10,
    marginTop: 10,
  }),
  rationStarWrapper: css({
    p: {
      fontSize: 15,
      paddingTop: 0,
    },
  }),
  feedbackFormWrapper: css({
    marginRight: 0,
    marginLeft: 0,
  }),
  feedbackInputWrapper: css({
    width: '100%',
  }),
  roomNameLabel: css({
    width: '100%',
  }),
  roomNameInput: css({
    width: '100%',
    fontSize: 15,
    paddingLeft: 10,
    '&::placeholder': {
      fontSize: 10,
    },
  }),
};

export const ReviewModal = ({
  roomId,
  localAccountId,
  onClose,
  isOpen = false,
}: Props) => {
  const { data: starRatings, error: starRatingError } =
    useMyStarRatings(roomId);
  const [defaultStarRatingValue, setDefaultStarRatingValue] = useState(0);
  const mutation = useCreateFeedbackAndStarRating(roomId);

  const today = new Date();
  const todayDateOnly = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );

  useEffect(() => {
    if (starRatings) {
      if (starRatings.length > 0) {
        const sortedData = [...starRatings].sort(
          (a: StarRating, b: StarRating) => {
            const dateA = new Date(a.creatDate);
            const dateB = new Date(b.creatDate);
            return dateB.getTime() - dateA.getTime();
          }
        );
        const latestData = sortedData[0];
        const createDate = new Date(latestData.creatDate);
        const createDateOnly = new Date(
          createDate.getFullYear(),
          createDate.getMonth(),
          createDate.getDate()
        );

        if (todayDateOnly.getTime() === createDateOnly.getTime()) {
          setDefaultStarRatingValue(latestData.starRating || 0);
        }
      }
    }
  }, [starRatings]);

  useEffect(() => {
    if (starRatingError) {
      handleFailure(starRatingError, '評価の取得ができませんでした。');
    }
  }, [starRatingError]);

  const onFeedbackSubmit = async ({
    feedbackText,
    ratingValue,
  }: FeedbackFormValues) => {
    if (!localAccountId) {
      alert(
        'エラーが発生しました。申し訳ありませんが、最初からやり直してください。'
      );
      return;
    }
    const postData = {
      Feedback: {
        RoomId: roomId,
        UserId: localAccountId,
        OpinionText: feedbackText && feedbackText.length ? feedbackText : null,
      },
      StarRating: {
        RoomId: roomId,
        UserId: localAccountId,
        StarRating: ratingValue,
      },
    };

    await mutation
      .mutateAsync(postData)
      .then(() => onClose && onClose())
      .catch((err) =>
        handleFailure(
          err,
          '申し訳ありません。フィードバックを保存できませんでした。'
        )
      );
  };

  const handleFailure = (err: any, message: string) => {
    console.log(err);
    alert(message);
  };

  return (
    <MeetingModal onClose={onClose} isOpen={isOpen}>
      <div>
        <FeedbackForm
          defaultValue={{
            feedbackText: '',
            ratingValue: defaultStarRatingValue,
          }}
          starSize={30}
          styles={styles}
          onFeedbackSubmit={(values) => onFeedbackSubmit(values)}
        />
      </div>
    </MeetingModal>
  );
};
