import { FeedbackService } from "../services/feedbackService";
import { useClient } from "./useClient";
import { useQuery } from "@tanstack/react-query";
import { queryKey } from "../utils/QueryKey";

type State = {
  data?: any,
  error?: any,
}

export const useAllHarassment = (roomId: string): State => {
  const feedbackService = new FeedbackService({
    roomId,
  })
  const client = useClient();
  const { data, error, } = useQuery(
    [queryKey.allHarassment],
    async () => feedbackService.fetchRegisteredAllHarassment(),
    { enabled: roomId != undefined && roomId != "" && client != undefined }
    )

  return { data, error }
}