import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { queryKey } from "../utils/QueryKey";
import { useApiTokenForBrowser } from "./browser/useApiTokenForBrowser";
import { TenantAdministratorService } from "../services/TenantAdministratorService";

export const useIsTenantAdministrator = (): boolean | undefined => {
const { token } = useApiTokenForBrowser();
  const tenantAdministratorService = new TenantAdministratorService(token);
  const [isTenantAdministrator, setIsTenantAdministrator] = useState<boolean>();
  const { data, error } = useQuery(
    [queryKey.myTenantAdministrator],
    async () => tenantAdministratorService.fetchMe(),
    { enabled: token != undefined, retry: false }
  )
  useEffect(() => {
    if (data) {
      setIsTenantAdministrator(true);
    } else if (error) {
      setIsTenantAdministrator(false);
    }
  },[data, error]);

  return isTenantAdministrator
}