import { useQuery } from "@tanstack/react-query";
import { queryKey } from "../utils/QueryKey";
import { TenantAdministratorService } from "../services/TenantAdministratorService";
import { useApiTokenForBrowser } from "./browser/useApiTokenForBrowser";

export const useAllTenantAdministrator = () => {
  const { token } = useApiTokenForBrowser()
  const tenantAdministratorService = new TenantAdministratorService(token);
  const { data, error, } = useQuery(
    [queryKey.tenantAdministrator],
    async () => tenantAdministratorService.fetchAllTenantAdministrator(),
    { enabled: token != undefined }
  )

  return { data, error}
}