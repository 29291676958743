import { useServiceContext } from '../contexts/serviceContext';
import { useMutation, useQuery } from '@tanstack/react-query';
import { queryKey } from '../utils/QueryKey';
import { getCachedApiToken } from '../utils/TokenManager';

export const usePersonalMutation = (userPrincipalName: string): any => {
  const { services } = useServiceContext();
  return useMutation(async (userPrincipalName: string) => {
    return services?.graph?.getPersonal(userPrincipalName);
  });
};

export const usePersonal = (userPrincipalName: string): any => {
  const { services } = useServiceContext();
  const { data, error, fetchStatus } = useQuery(
    [queryKey.personal],
    async () => {
      const result = await services?.graph?.getPersonal(userPrincipalName);
      if (!result) {
        throw new Error('Result is undefined');
      }
      return result;
    },
    {
      enabled:
        !!services &&
        !!services.graph &&
        getCachedApiToken() != undefined &&
        userPrincipalName !== '',
      retry: (failureCount, error: any) => {
        // resultがundefinedでエラーをスローした場合のみリトライする
        return failureCount < 3 && error.message === 'Result is undefined';
      },
    }
  );

  const personalData: any = data;
  const personalError: any = error;
  const personalFetchStatus: any = fetchStatus;

  return {
    personalData,
    personalError,
    personalFetchStatus,
  };
};
