import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { SerializedStyles } from "@emotion/serialize";
import { css } from "@emotion/react";

interface Props {
  label: string;
  value: string;
  onChange: (value: any) => void;
  isInvalid?: boolean;
  error?: string;
  name: string;
  onBlur?: (e: any) => void;
  classes?: {
    label?: SerializedStyles;
    input?: SerializedStyles;
    root?: SerializedStyles;
  }
};

const styles = {
  root: css({
    display: 'flex',
  }),
  label: css({
    flexBasis: 250,
    fontSize: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginRight: 20,
  }),
  input: css({
    height: 50,
  })
}

export const ModalTextInput = ({ label, value, onChange, isInvalid, error, name, onBlur, classes }: Props) => {
  return (
    <Form.Group css={[styles.root, classes?.root]}>
      <Form.Label css={[styles.label, classes?.label]}>{label}</Form.Label>
      <InputGroup hasValidation>
        <Form.Control
          type="text"
          name={name}
          value={value}
          onChange={onChange}
          isInvalid={isInvalid}
          onBlur={onBlur}
          css={[styles.input, classes?.input]}
          />
        <Form.Control.Feedback type="invalid">
          {error}
        </Form.Control.Feedback>
      </InputGroup>
    </Form.Group>
  )}