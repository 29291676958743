import { useTenantIdForBrowser } from "../useTenantIdForBrowser";
import { useQuery } from "@tanstack/react-query";
import { queryKey } from "../../utils/QueryKey";
import { TenantService } from "../../services/TenantService";

export const useTenant = () => {
  const tenantService = new TenantService();
  const [tenantId] = useTenantIdForBrowser();
  const { data } = useQuery(
    [queryKey.tenant],
    async () => tenantService?.getTenant(tenantId as string),
    { enabled: tenantId != undefined }
  )

  return [ data ]
}