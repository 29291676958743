import { useMsal } from "@azure/msal-react";
import { useQuery } from "@tanstack/react-query";
import { isTokenExpired } from "../../utils/TokenManager";
import { queryKey } from "../../utils/QueryKey";
import jwtDecode from "jwt-decode";

export const useGraphApiToken = (): { token: string | undefined, error: any } => {
  const { instance, accounts } = useMsal();
  const userName = accounts[0]?.username

  const { data, error } = useQuery(
    [queryKey.graphApiToken],
    async () => instance.acquireTokenSilent({
      scopes: ['openid', 'profile', process.env.REACT_APP_BROWSER_SCOPE ?? ''],
      account: accounts[0],
    }),
    {
      enabled: userName != undefined,
      refetchIntervalInBackground: true,
      refetchInterval: (data) => {
        if(!data){
          return 1000 * 30;
        }
        const decodedToken = jwtDecode<any>(data.accessToken);
        const now = Date.now();
        const exp = decodedToken.exp * 1000;
        const offset = 1000 * 60 * 5;
        return exp - now - offset;
      }
    }
  )

  const token = data?.accessToken && !isTokenExpired(data.accessToken) ? data.accessToken : undefined;

  return {
    token,
    error,
  }
}