import React, { ReactNode } from 'react';
import { css } from "@emotion/react";

interface Props {
  children?: ReactNode;
  onClick?: () => void | Promise<void>;
  className?: string;
  color?: 'red' | 'blue' | 'orange',
  type?: 'button' | 'submit' | 'reset' | undefined
}

const styles = {
  root: css({
    background: '0% 0% no-repeat padding-box',
    boxShadow: '0px 4px 8px #00000091',
    borderRadius: 35,
    opacity: 1,
    color: '#ffffff',
    padding: '6px 20px',
    marginBottom: '1rem',
    border: 'none'
  })
}

const buttonColors = {
  'blue': '#0EB4FB',
  'orange': '#f15a24',
  'red': '#E80000',
}

export const AppButton = ({onClick, children, className, type, color = 'red'}: Props) => {
  return (
    <button
      onClick={onClick}
      css={[styles.root, css({ backgroundColor: buttonColors[color] }) ]}
      className={className}
      type={type}
    >
      {children}
    </button>
  )
};