import axios, { Axios } from 'axios';
import { getCachedApiToken } from '../utils/TokenManager';

export type SubscriptionData = {};

export class SubscriptionService {
  private readonly axios: Axios;
  private readonly endpointBase: string;
  constructor(tenantId: string, apiToken: string = '') {
    const token = apiToken !== '' ? apiToken : getCachedApiToken();
    this.axios = axios.create({
      headers: { Authorization: `Bearer ${token}` },
    });
    this.endpointBase = process.env.REACT_APP_SIGNALR_SERVER_URL + '/api';
  }

  private async get(endpoint: string): Promise<any> {
    const ep = this.endpointBase + '/' + endpoint;
    const response = await this.axios.get(ep).catch((error: any) => {
      throw error;
    });
    return response.data;
  }

  private async delete(endpoint: string): Promise<any> {
    const ep = this.endpointBase + '/' + endpoint;
    const response = await this.axios.delete(ep).catch((error: any) => {
      throw error;
    });
    return response.data;
  }

  private async post(endpoint: string, postData: any): Promise<any> {
    const ep = this.endpointBase + '/' + endpoint;
    const response = await this.axios.post(ep, postData).catch((error: any) => {
      throw error;
    });
    return response.data;
  }

  public async registerTrial(tenantId: string) {
    return this.post(`subscription/startTrial`, tenantId);
  }

  public async checkSubscription(tenantId: string) {
    return this.post(`subscription/checkSubscription`, tenantId);
  }

  public async registerSubscriptionKey(subscriptionKey: string) {
    return this.post(`subscription/registerSubscription`, {
      subscriptionKey: subscriptionKey,
    });
  }

  public async getSubscription() {
    return this.get(`subscription/getSubscription`);
  }
}
