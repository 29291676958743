import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { queryKey } from '../utils/QueryKey';
import { SubscriptionService } from '../services/subscriptionService';
import { getCachedApiToken } from '../utils/TokenManager';
import { MeetingService } from '../services/MeetingService';

export const useSubscription = (tenantId: string, roomId: string) => {
  const subscriptionService = new SubscriptionService(tenantId, '');
  const meetingService = new MeetingService();
  const [roomData, setRoomData] = useState();
  const { data, error, fetchStatus } = useQuery(
    [queryKey.expired],
    async () => subscriptionService.checkSubscription(tenantId),
    {
      enabled: getCachedApiToken() != undefined,
      onError: async (error) => {
        console.log(error);
        await meetingService
          .meetingRoomIsActive(roomId)
          .then((_data) => setRoomData(_data));
      },
    }
  );

  const subscriptionData: any = data;
  const subscriptionError: any = error;
  const subscriptionFetchStatus: any = fetchStatus;


  return {
    subscriptionData,
    subscriptionError,
    subscriptionFetchStatus,
    roomData,
  };
};
