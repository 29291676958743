import React from 'react';
import { Modal, ModalBody, ModalFooter } from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import { Form as FormikForm, Formik } from "formik"
import { User } from "./UserManagementTable";
import * as yup from "yup"
import { ModalTextInput } from "./ModalTextInput";
import { css } from "@emotion/react";
import { SubmitButton } from "./SubmitButton";
import { MdClose } from "react-icons/md";

interface Props {
  show?: boolean,
  onClose?: () => void,
  onSubmit: (data: User) => void,
  initialValues?: User
}

const schema = yup.object().shape({
  displayName: yup.string().required(),
  email: yup.string().email().required(),
  id: yup.number(),
});

const styles = {
  root: css({
    // modalの一番上の要素にスタイルを適用する
    '& > div': {
      maxWidth: 1000,
    },
    '& > div > div': {
      borderRadius: 20,
      maxWidth: 1000,
    }
  }),
  footer: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderTop: 'none',
    paddingTop: 30,
    paddingBottom: 50,
  }),
  header: css({
    border: 'none',
    justifyContent: 'flex-end',
  }),
  body: css({
    padding: '0 150px',
    paddingTop: 40,
  }),
  firstTextInput: css({
    marginBottom: 30,
  })
}

export const UserManagementModal = ({ show, onClose = () => {}, onSubmit, initialValues }: Props) => {
  return (
    <Modal show={show} onHide={onClose} css={styles.root}>
      <Formik
        enableReinitialize
        onSubmit={onSubmit}
        initialValues={initialValues ?? {
          id: 0,
          displayName: '',
          email: '',
        }}
        validationSchema={schema}
      >
        {
          ({
             values,
             handleChange,
             errors,
             handleBlur,
             touched,
             isSubmitting
           }) => (
             <>
               <ModalHeader css={styles.header}>
                 <MdClose size={36} onClick={onClose} style={{cursor: 'pointer'}} />
               </ModalHeader>
               <FormikForm>
                 <ModalBody css={styles.body}>
                   <ModalTextInput
                     label="名前"
                     name="displayName"
                     value={values.displayName}
                     onChange={handleChange}
                     onBlur={handleBlur}
                     isInvalid={!!errors.displayName && !!touched.displayName}
                     error={errors.displayName}
                     classes={{root: styles.firstTextInput}}
                   />
                   <ModalTextInput
                     label="メールアドレス"
                     name="email"
                     value={values.email}
                     onChange={handleChange}
                     onBlur={handleBlur}
                     isInvalid={!!errors.email && !!touched.email}
                     error={errors.email}
                   />
                 </ModalBody>
                 <ModalFooter css={styles.footer}>
                   <SubmitButton type="submit" disabled={isSubmitting}>登録する</SubmitButton>
                 </ModalFooter>
               </FormikForm>
             </>
          )}
      </Formik>
    </Modal>
  )
};