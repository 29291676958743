import { Feedback, FeedbackService } from "../services/feedbackService";
import { useClient } from "./useClient";
import { useQuery } from "@tanstack/react-query";
import { queryKey } from "../utils/QueryKey";

type State = {
  data: Feedback[],
  error?: any,
}

export const useAllFeedbacks = (roomId: string): State => {
  const feedbackService = new FeedbackService({
    roomId,
  })
  const client = useClient()
  const { data, error, } = useQuery(
    [queryKey.feedbacks],
    async () => feedbackService.fetchRegisteredAllFeedback(),
    { enabled: roomId != undefined && roomId != "" && client != undefined }
  )

  return { data: data ?? [], error }
}