import { useMutation, useQueryClient } from "@tanstack/react-query";
import { queryKey } from "../utils/QueryKey";
import { CreateRepresentativeData, RepresentativeService } from "../services/RepresentativeService";
import { useApiTokenForBrowser } from "./browser/useApiTokenForBrowser";

export const useCreateRepresentativeMutation = () => {
  const { token } = useApiTokenForBrowser()
  const representativeService = new RepresentativeService(token)
  const queryClient = useQueryClient();
  return  useMutation(async (data: CreateRepresentativeData) => {
      return  representativeService.createRepresentative(data);
    }, {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKey.representative])
      }}
  );
}