import * as React from 'react';
import { ReactElement, RefObject } from 'react';
import Animation from './Animation';
import { Carousel } from 'react-bootstrap';
import { AiFillCaretLeft, AiFillCaretRight } from 'react-icons/ai';
import EventListener from 'react-event-listener';
import Nameplate from './Nameplate';
import { Audience } from '../container/MeetingContainer';
import { css, Theme, withTheme } from '@emotion/react';

interface Props {
  animationWindows: number;
  currentAudience: Audience[];
  isSidePanel: Boolean;
  theme?: Theme;
  ref?: any;
}

interface State {
  nextIcon: ReactElement;
  prevIcon: ReactElement;
  nextIconHide: ReactElement;
  prevIconHide: ReactElement;
  pageWindowLimit: number;
  useCarousel: boolean;
  width: number | undefined;
  index: number;
  stampImageLimit: number;
  attachedAudience: Audience[];
}

type window = {
  id: number;
  ref: RefObject<any>;
  isMovie: boolean;
  actionType: string;
  scale: number;
  wrapperType: string;
  page: number;
};

const makeStyles = (theme?: Theme) => ({
  carouselControlIcon: css({
    width: 20,
    height: 40,
    color: '#999999',
  }),
  carouselIconHide: css({
    display: 'none',
  }),
  animationContainer: css({
    minHeight: 270,
    width: '100%',
    marginTop: 10,
    display: 'flex',
    '& .carousel-inner': {
      left: 0,
      right: 0,
    },
    '& .carousel-control-prev': {
      left: -13,
    },
    '& .carousel-control-next': {
      right: -10,
    },
  }),
  myCarouselItem: css({
    display: 'flex',
    flexWrap: 'wrap',
    border: theme?.colors.white,
  }),
  animationWrapper: css({
    marginLeft: 8,
    marginRight: 8,
    lineHeight: '12px',
    marginBottom: 10,
    width: 65,
    textAlign: 'center',
  }),
  myCarousel: css({
    position: 'unset',
  }),
  carouselWrapper: css({
    width: '100%',
  }),
});

class AnimationManager extends React.Component<Props, State> {
  private readonly wrapperType: string;
  private readonly anonymousMode: boolean;
  private readonly scale: number;
  private readonly AnimationWidth: number;
  private readonly minimumStampImage: number;
  private readonly maxStampImage: number;
  private maxReactionRow: number;
  private maxReactionCol: number;
  private windows: window[];
  private reactionIdQueue: number[];
  private linkedReactionQueue: number[][];
  private animationContainer: any;
  private recomposedAudience: Audience[];
  private multiWindows: window[][];

  constructor(props: Props) {
    super(props);
    this.windows = [];
    this.wrapperType = 'normal';
    this.anonymousMode = false;

    this.scale = 0.2;
    this.AnimationWidth = 300 * this.scale;

    this.minimumStampImage = 1;
    this.maxStampImage = 3;
    this.maxReactionRow = 3;
    this.maxReactionCol = 3;

    this.reactionIdQueue = [];
    this.linkedReactionQueue = [];
    this.recomposedAudience = [];
    this.multiWindows = [[]];

    const styles = makeStyles(this.props.theme);

    this.state = {
      width: 0,
      useCarousel: false,
      index: 0,
      pageWindowLimit: this.maxReactionCol * this.maxReactionRow,
      stampImageLimit: this.minimumStampImage,
      nextIcon: <AiFillCaretRight css={styles.carouselControlIcon} />,
      prevIcon: <AiFillCaretLeft css={styles.carouselControlIcon} />,
      nextIconHide: <AiFillCaretRight css={styles.carouselIconHide} />,
      prevIconHide: <AiFillCaretLeft css={styles.carouselIconHide} />,
      attachedAudience: [],
    };
  }

  componentDidMount() {
    const width = this.animationContainer?.clientWidth;
    this.setState({ width: width }, () => {
      this.getContainerWidth();
      this.CheckUseCarousel();
      this.CountDisplayWindow();
      this.updatePage();
      this.parseWindows();
    });
    for (let i = 0; i < this.props.animationWindows; i++) {
      this.linkedReactionQueue.push([]);
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.animationWindows !== prevProps.animationWindows) {
      this.getContainerWidth();
      this.CheckUseCarousel();
      this.audienceRecompose();
      this.CountDisplayWindow();
      this.CountDisplayWindow();
      this.updatePage();
      this.parseWindows();
    }
  }

  render() {
    const { nextIcon, prevIcon, nextIconHide, prevIconHide } = this.state;
    if (this.windows.length != this.props.animationWindows) {
      this.windows = [];
      this.multiWindows = [];

      for (let i = 0; i < this.props.animationWindows; i++) {
        this.windows.push({
          id: i,
          ref: React.createRef(),
          isMovie: false,
          actionType: 'normal',
          scale: this.scale,
          wrapperType: this.wrapperType,
          page: Math.floor(i / this.state.pageWindowLimit),
        });
        this.linkedReactionQueue.push([]);
      }

      if (!this.state.useCarousel) {
        for (var i = 0; i < this.state.attachedAudience.length; i++) {
          var page = i / this.state.pageWindowLimit;
          this.multiWindows[i].push({
            id: this.multiWindows[i].length + 1,
            ref: React.createRef(),
            isMovie: false,
            actionType: 'normal',
            scale: this.scale,
            wrapperType: this.wrapperType,
            page: page,
          });
        }
        console.log(this.multiWindows);
      }
    }

    const styles = makeStyles(this.props.theme);
    return (
      <div
        css={styles.animationContainer}
        ref={(animationContainer) => {
          this.animationContainer = animationContainer;
        }}
      >
        <EventListener target="window" onResize={this.handleResize} />
        <div css={styles.carouselWrapper}>
          {this.state.useCarousel ? (
            <Carousel
              indicators={false}
              interval={null}
              prevIcon={prevIcon}
              nextIcon={nextIcon}
            >
              {this.multiWindows.map((windows) => (
                <Carousel.Item>
                  <div css={styles.myCarouselItem}>
                    {windows.map((window) => (
                      <div key={window.id} css={styles.animationWrapper}>
                        <Animation
                          windowid={window.id}
                          key={window.id}
                          ref={window.ref}
                          scale={window.scale}
                          interruptFlag={window.id !== 0 ? false : true}
                          animationEndEventHandler={(id) =>
                            this.animationEndEventHandler(id)
                          }
                          wrapperType={window.wrapperType}
                        />
                        {this.anonymousMode ? (
                          ''
                        ) : (
                          <Nameplate
                            displayName={
                              this.props.currentAudience[window.id].displayName
                            }
                          />
                        )}
                      </div>
                    ))}
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
          ) : (
            <Carousel
              css={styles.myCarousel}
              prevIcon={prevIconHide}
              nextIcon={nextIconHide}
              indicators={false}
            >
              <Carousel.Item>
                <div css={styles.myCarouselItem}>
                  {this.windows.map((window) => (
                    <div key={window.id} css={styles.animationWrapper}>
                      <Animation
                        windowid={window.id}
                        key={window.id}
                        ref={window.ref}
                        scale={window.scale}
                        interruptFlag={window.id !== 0 ? false : true}
                        animationEndEventHandler={(id) =>
                          this.animationEndEventHandler(id)
                        }
                        wrapperType={window.wrapperType}
                      />
                      {this.anonymousMode ? (
                        ''
                      ) : (
                        <Nameplate
                          displayName={
                            this.props.currentAudience[window.id].displayName
                          }
                        />
                      )}
                    </div>
                  ))}
                </div>
              </Carousel.Item>
            </Carousel>
          )}
        </div>
      </div>
    );
  }

  reactionStarter(reactionId: number, clientIds: string[]) {
    if (this.anonymousMode) {
      this.updateMovableWindows();
      let targetWindow = this.pickUpMovableWindow();
      if (targetWindow) {
        this.windows[targetWindow.id].ref.current.reactionHandler(reactionId);
      } else {
        this.enqueue(reactionId);
      }
    } else {
      clientIds.forEach((_clientId) => {
        let audienceIndex = this.props.currentAudience.findIndex(
          (c) => c.clientId === _clientId
        );

        this.enqueueLinkedQueue(audienceIndex, reactionId);

        if (this.state.useCarousel) {
          this.multiWindows.forEach((windows) => {
            let index = windows.findIndex((c) => c.id === audienceIndex);
            if (index >= 0) {
              windows[index].ref.current.reactionHandler(reactionId);
            }
          });
        } else {
          this.windows[audienceIndex].ref.current.reactionHandler(reactionId);
        }
      });
    }
  }

  ownReactionStarter(reactionId: number) {
    if (!this.state.useCarousel) {
      this.windows[0].ref.current.reactionHandler(reactionId);
    } else {
      this.multiWindows.forEach((windows) => {
        windows[0].ref.current.reactionHandler(reactionId);
      });
    }
  }

  updateMovableWindows() {
    this.windows.forEach((window) => {
      window.isMovie = window.ref.current.getActionState();
      window.actionType = window.ref.current.getActionType();
    });
  }

  pickUpMovableWindow() {
    var callableWindows = this.windows.filter(
      (window) => window.actionType === 'normal'
    );
    return callableWindows[Math.floor(Math.random() * callableWindows.length)];
  }

  executableAnimation(index: number) {
    if (this.windows[index].actionType === 'normal') {
      return true;
    } else {
      return false;
    }
  }

  animationEndEventHandler(windowId: number) {
    let _reactionId: number | undefined;
    if (this.anonymousMode) {
      _reactionId = this.dequeue() || 0;
      this.windows[windowId].ref.current.reactionHandler(_reactionId);
    } else {
      _reactionId = this.dequeueLinkedQueue(windowId);

      if (this.state.useCarousel) {
        var senderPage = 0;
        var senderIndex = 0;

        for (var i = 0; i < this.multiWindows.length; i++) {
          if (this.multiWindows[i].some((window) => window.id === windowId)) {
            senderPage = i;
            senderIndex = this.multiWindows[i].findIndex(
              (window) => window.id === windowId
            );
          } else {
            continue;
          }
        }
        this.multiWindows[senderPage][senderIndex].ref.current.reactionHandler(
          _reactionId
        );
      } else {
        this.windows[windowId].ref.current.reactionHandler(_reactionId);
      }
    }
  }

  handleResize = () => {
    this.getContainerWidth();
    this.CheckUseCarousel();
    this.CountDisplayWindow();
    this.updatePage();
    this.parseWindows();
  };

  handleSelect = (selectedIndex: number) => {
    this.setState({ index: selectedIndex });
  };

  //リアクション表示領域の画面幅をstateに格納する関数
  getContainerWidth = () => {
    const width = this.animationContainer?.clientWidth;
    this.setState({ width: width });
  };

  //複数ページに分割するか算出する関数
  CheckUseCarousel = () => {
    const _useCarousel = this.useCarousels();
    this.setState({ useCarousel: _useCarousel });
  };

  //画面幅からリアクション表示数を算出する
  CountDisplayWindow = () => {
    const width = this.animationContainer.clientWidth;
    var count = 0;
    if (this.props.isSidePanel) {
      return;
    }
    if (width > this.AnimationWidth * this.maxReactionCol) {
      count = Math.floor(width / this.AnimationWidth) * this.maxReactionCol;
    } else {
      count = this.maxStampImage * 2;
    }
    this.setState({ pageWindowLimit: count });
  };

  useCarousels = () => {
    if (this.props.animationWindows <= this.state.pageWindowLimit) {
      return false;
    } else {
      return true;
    }
  };

  //window.pageに何ページ目のカルーセルに表示するかを設定する関数
  updatePage = () => {
    this.windows.forEach((window, index) => {
      window.page = Math.floor(index / this.state.pageWindowLimit);
    });
  };

  //multiWindowsを作る関数
  parseWindows = () => {
    this.multiWindows = [];
    var pageWindows = [];
    for (var i = 0; i < this.recomposedAudience.length; i++) {
      var page: number = Math.trunc(i / this.state.pageWindowLimit);
      var clientId = this.recomposedAudience[i].clientId;

      pageWindows.push({
        id: this.props.currentAudience.findIndex(
          (audience) => audience.clientId === clientId
        ),
        ref: React.createRef(),
        isMovie: false,
        actionType: 'normal',
        scale: this.scale,
        wrapperType: this.wrapperType,
        page: page,
      });
      if (
        Math.trunc((i + 1) / this.state.pageWindowLimit) !== page &&
        i !== 0
      ) {
        this.multiWindows.push(pageWindows);
        pageWindows = [];
      }
      if (i === this.recomposedAudience.length - 1) {
        this.multiWindows.push(pageWindows);
      }
    }
  };

  //複数ページ表示する場合にページの先頭位置に自分の地蔵を表示できるように修正をした
  audienceRecompose = () => {
    if (this.props.currentAudience.length > this.state.pageWindowLimit) {
      this.recomposedAudience = this.props.currentAudience.slice();
      for (
        var i = 1;
        i <= this.props.currentAudience.length / this.state.pageWindowLimit;
        i++
      ) {
        this.recomposedAudience.splice(
          i * this.state.pageWindowLimit,
          0,
          this.props.currentAudience[0]
        );
      }
    }
  };

  enqueue(reactionId: number) {
    this.reactionIdQueue.push(reactionId);
    // optimize queue
  }

  dequeue() {
    if (this.reactionIdQueue.length > 0) return this.reactionIdQueue.shift();
  }

  enqueueLinkedQueue(index: number, reactionId: number) {
    this.linkedReactionQueue[index].push(reactionId);
  }

  dequeueLinkedQueue(index: number) {
    if (this.linkedReactionQueue.length > 0) {
      if (this.linkedReactionQueue[index].length > 0)
        return this.linkedReactionQueue[index].shift();
    }
  }
}

export default withTheme(AnimationManager);
