import React from 'react';
import { css, Theme, useTheme } from '@emotion/react';

interface EmoteAreaProps {
  sendReaction: (reactionId: number) => void;
}

const emoteBackground = css({
  borderRadius: '50%',
});

const makeStyles = (theme: Theme) => ({
  emoteAreaContainer: css({
    textAlign: 'center',
    borderBottom: `${theme.colors.gray} solid 1px`,
    marginRight: '-15px',
    marginLeft: '-15px',
  }),
  emoteAreaTitle: css({
    marginTop: 8,
    marginBottom: 3,
    color: theme.colors.white,
  }),
  emoteButtons: css({
    textAlign: 'center',
    display: 'inline-flex',
  }),
  emoteButton: css({
    marginRight: 10,
    cursor: 'pointer',
  }),
  unazukiBackground: css({
    background: theme.colors.unazukiBack,
  }),
  emoteBackground: css({
    borderRadius: '50%',
  }),
  emoteButtonWrapper: css({
    position: 'static',
    width: 55,
    height: 55,
  }),
  emoteImage: css({
    marginTop: 7,
    width: '95%',
    '&:active': css({
      transform: 'translateY(4px)',
    }),
  }),
  emoteCaption: css({
    fontSize: 10,
    marginTop: 5,
    marginBottom: 10,
    color: theme.colors.white,
  }),
  clapBackground: css({
    background: theme.colors.clapBack,
  }),
  proposalBackGround: css({
    background: theme.colors.proposalBack,
  }),
  thinkingBackground: css({
    background: theme.colors.thinkingBack,
  }),
});

const EmoteArea = (props: EmoteAreaProps) => {
  const theme = useTheme();
  const styles = makeStyles(theme);
  return (
    <div css={styles.emoteAreaContainer}>
      <p css={styles.emoteAreaTitle}>リアクションを送ってみよう!</p>
      <div css={styles.emoteButtons}>
        <div css={styles.emoteButton}>
          <div css={[styles.unazukiBackground, emoteBackground]}>
            <div css={styles.emoteButtonWrapper}>
              <img
                src={process.env.PUBLIC_URL + '/images/reactions/head_nod.png'}
                alt="あいづち"
                css={styles.emoteImage}
                onClick={() => props.sendReaction(4)}
              />
            </div>
          </div>
          <p css={styles.emoteCaption}>うんうん</p>
        </div>

        <div css={styles.emoteButton}>
          <div css={[styles.emoteBackground, styles.clapBackground]}>
            <div css={styles.emoteButtonWrapper}>
              <img
                src={process.env.PUBLIC_URL + '/images/reactions/head_clap.png'}
                alt="ぱちぱち"
                css={[styles.emoteImage]}
                onClick={() => props.sendReaction(1)}
              />
            </div>
          </div>
          <p css={styles.emoteCaption}>ぱちぱち</p>
        </div>

        <div css={styles.emoteButton}>
          <div css={[styles.emoteBackground, styles.proposalBackGround]}>
            <div css={styles.emoteButtonWrapper}>
              <img
                src={
                  process.env.PUBLIC_URL + '/images/reactions/head_proposal.png'
                }
                alt="ぎもん"
                css={styles.emoteImage}
                onClick={() => props.sendReaction(2)}
              />
            </div>
          </div>
          <p css={styles.emoteCaption}>喋りたい</p>
        </div>

        <div css={styles.emoteButton}>
          <div css={[styles.emoteBackground, styles.thinkingBackground]}>
            <div css={styles.emoteButtonWrapper}>
              <img
                src={
                  process.env.PUBLIC_URL + '/images/reactions/head_thinking.png'
                }
                alt="かんがえちゅう"
                css={styles.emoteImage}
                onClick={() => props.sendReaction(3)}
              />
            </div>
          </div>
          <p css={styles.emoteCaption}>考え中</p>
        </div>
      </div>
    </div>
  );
};

export default EmoteArea;
