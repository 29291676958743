import React from 'react';
import { Form, Table } from "react-bootstrap";
import { css, Theme, useTheme } from "@emotion/react";

interface Props {
  mailAddresses?: string[];
  onClickRadio: (data: string) => void;
  selectedEmailAddress?: string;
}
interface TableRowProps {
  mail: string,
  onClickRadio: Props["onClickRadio"],
  checked: boolean,
}


const makeStyles = (theme: Theme) => ({
  root: css({
    fontSize: 24,
    width: '100%',
    textAlign: 'center',
    border: 'none',
    '& th,& td ': css({
      borderTop: 'none',
      borderRight: 'none',
      borderLeft: 'none',
      fontWeight: 'normal',
    }),
    '& tr th': css({
      borderBottomWidth: 1
    }),
    '& #radio': css({
      border: 'none',
    })
  }),
  form: css({
    width: '100%',
    paddingTop:10,
    paddingBottom: 20,
    paddingLeft: 100,
    paddingRight: 100,
    border: '1px solid',
    borderColor: theme.colors.gray,
  }),
  cell: css({
    border: 'none'
  }),
  radioWrapper: css({
    border: 'none',
  }),
})

const TableRow = ({mail, onClickRadio, checked}: TableRowProps) =>(
  <tr>
    <th
      id="radio"
      onClick={() => onClickRadio(mail)}
    >
      <Form.Check
        name="group1"
        type="radio"
        id={mail}
        checked={checked}
      />
    </th>
    <th>
      {mail}
    </th>
  </tr>
)

export const HarassmentManagerManagementTable = ({ mailAddresses, onClickRadio, selectedEmailAddress }: Props) => {
  const styles = makeStyles(useTheme());
  return (
    <Form
      css={styles.form}
    >
      <Table
        bordered
        css={styles.root}
      >
        <thead>
        <tr>
          <th id="radio"/>
          <th>メールアドレス</th>
        </tr>
        </thead>
        <tbody>
        {
          mailAddresses?.map((m) => (
            <TableRow
              mail={m}
              onClickRadio={onClickRadio}
              checked={m === selectedEmailAddress}
            />
          ))
        }
        </tbody>
      </Table>
    </Form>
  )
};