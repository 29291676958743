import { Col, Row } from 'react-bootstrap';
import { css } from '@emotion/react';

const styles = {
  configuration: css({
    border: '1px solid #9e9e9e',
    borderRadius: '8px',
    fontSize: 16,
  }),
  row: css({
    marginLeft: 0,
    marginRight: 0,
    textAlign: 'center',
  }),
  logo: css({
    img: { width: 204, height: 106, marginTop: 25 },
  }),
  mainMessage: css({
    marginTop: 25,
    marginBottom: 25,
  }),
  stampWrapper: css({
    img: {
      width: 75,
      height: 75,
      marginRight: 5,
      marginLeft: 5,
    },
  }),
  caption: css({
    marginTop: 18,
    marginBottom: 60,
  }),
};

const Configuration = () => {
  return (
    <div css={styles.configuration}>
      <Row css={styles.row}>
        <Col sm={12}>
          <div css={styles.logo}>
            <img
              alt="wakachie logo"
              src={`${process.env.PUBLIC_URL}/images/util/logo.png`}
            />
          </div>
        </Col>
        <Col sm={12}>
          <p css={styles.mainMessage}>
            顔を出さないで感情の「見える化」をカンタンに。
            <br />
            Teams会議サポートツール「ワカチエ」
          </p>
        </Col>
        <Col sm={12} css={styles.stampWrapper}>
          <img
            alt="喜び"
            src={`${process.env.PUBLIC_URL}/images/tab/zizou_1.png`}
          />
          <img
            alt="しゃべりたい"
            src={`${process.env.PUBLIC_URL}/images/tab/zizou_2.png`}
          />
          <img
            alt="ナイス"
            src={`${process.env.PUBLIC_URL}/images/stamps/1_nice.png`}
          />
          <img
            alt="もやもや"
            src={`${process.env.PUBLIC_URL}/images/stamps/2_moyamoya.png`}
          />
        </Col>
        <Col sm={12}>
          <p css={styles.caption}>
            Teams会議中に自分の感情をかわいいキャラで表現できます。
          </p>
        </Col>
      </Row>
    </div>
  );
};

export default Configuration;
