import { useAsync } from "react-use";
import { Client } from "@microsoft/microsoft-graph-client";
import { useState } from "react";

export const useClientForBrowser = (token: string | undefined)  => {
  const [client, setClient] = useState<Client>();

  useAsync(async () => {
    if(token == undefined){
      return
    }
    const getAccessToken = async () => token

    const client = Client.initWithMiddleware({
      authProvider: {
        getAccessToken: getAccessToken
      }
    })

    setClient(client)
  }, [token])

  return [client]
}