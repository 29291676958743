import React from 'react';
import { Form, Table } from "react-bootstrap";
import { css, Theme, useTheme } from "@emotion/react";

interface Props {
  users?: User[];
  onClickRadio: (user: User) => void;
  selectedUserId?: number;
}

export interface User {
  id:  number,
  displayName: string,
  email: string,
}

interface TableRowProps {
  user: User,
  onClickRadio: Props["onClickRadio"],
  checked: boolean,
}

const makeStyles = (theme: Theme) => ({
  root: css({
    fontSize: 24,
    width: '100%',
    textAlign: 'center',
    border: 'none',
    '& th,& td ': css({
      borderTop: 'none',
      borderRight: 'none',
      borderLeft: 'none',
      fontWeight: 'normal',
    }),
    '& tr th': css({
      borderBottomWidth: 1
    }),
    '& #radio': css({
      border: 'none',
    })
  }),
  form: css({
    width: '100%',
    paddingTop:10,
    paddingBottom: 20,
    paddingLeft: 100,
    paddingRight: 100,
    border: '1px solid',
    borderColor: theme.colors.gray,
  }),
  cell: css({
    border: 'none'
  }),
  radioWrapper: css({
    border: 'none',
  }),
})

const TableRow = ({user, onClickRadio, checked}: TableRowProps) =>(
  <tr>
    <th
      id="radio"
      onClick={() => onClickRadio(user)}
    >
      <Form.Check
        name="group1"
        type="radio"
        id={user.id.toString()}
        checked={checked}
      />
    </th>
    <th>
      {user.id}
    </th>
    <th>
      {user.displayName}
    </th>
    <th>
      {user.email}
    </th>
  </tr>
)

export const UserManagementTable = ({ users, onClickRadio, selectedUserId }: Props) => {
  const styles = makeStyles(useTheme());
  return (
    <Form
      css={styles.form}
    >
      <Table
        bordered
        css={styles.root}
      >
        <thead>
          <tr>
            <th id="radio"/>
            <th>ID</th>
            <th>名前</th>
            <th>メールアドレス</th>
          </tr>
        </thead>
        <tbody>
        {
          users?.map((u) => (
            <TableRow
              user={u}
              onClickRadio={onClickRadio}
              checked={u.id === selectedUserId}
            />
          ))
        }
        </tbody>
      </Table>
    </Form>

  )
};