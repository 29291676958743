import React from 'react';
import Stamps from '../data/Stamps.json';
import { css, Theme, useTheme } from '@emotion/react';

interface StampAreaProps {
  isCooling: boolean;
  sendStamp: (arg: number) => void;
}

const makeStyles = (theme: Theme) => ({
  emoteButtons: css({
    textAlign: 'center',
    display: 'inline-flex',
  }),
  emoteButton: css({
    marginRight: 10,
    cursor: 'pointer',
  }),
  emoteButtonWrapper: css({
    position: 'static',
    width: 55,
    height: 55,
  }),
  stampImage: css({
    marginTop: 5,
    width: '100%',
    cursor: 'pointer',
    '&:active': {
      transform: 'translateY(4px)',
    },
  }),
  stampSelected: css({
    transform: 'translateY(4px)',
  }),
  emoteBackGround: css({
    borderRadius: '50%',
  }),
  emoteBackgroundCooling: css({
    background: theme.colors.emoteCoolingBack,
  }),
  emoteCaptionCooling: css({
    fontSize: 10,
    marginTop: 5,
    marginBottom: 10,
    color: theme.colors.gray,
  }),
  emoteCaption: css({
    fontSize: 10,
    marginTop: 5,
    marginBottom: 10,
    color: theme.colors.white,
  }),
  emoteButtonsCooling: css({
    backgroundColor: theme.colors.gray,
  }),
  emoteAreaTitle: css({
    marginTop: 8,
    marginBottom: 3,
    color: theme.colors.white,
  }),
  emoteAreaContainer: css({
    textAlign: 'center',
    borderBottom: `${theme.colors.gray} solid 1px`,
    marginRight: '-15px',
    marginLeft: '-15px',
  }),
});

const StampArea = (props: StampAreaProps) => {
  const theme = useTheme();
  const styles = makeStyles(theme);

  const _stamps = Stamps.stamps;

  const EmoteButtons = () => {
    return (
      <div css={styles.emoteButtons}>
        {_stamps.map((s) => {
          return (
            <div css={styles.emoteButton}>
              <div
                css={[
                  styles.emoteBackGround,
                  props.isCooling && styles.emoteBackgroundCooling,
                ]}
                className={!props.isCooling ? s.backGroundCssClass : ''}
              >
                <div css={styles.emoteButtonWrapper}>
                  <img
                    src={process.env.PUBLIC_URL + s.iconImagePath}
                    alt={s.alterlationText}
                    css={styles.stampImage}
                    style={{ display: !props.isCooling ? 'inline' : 'none' }}
                    onClick={() => sendStamp(s.id)}
                  />
                  <img
                    src={process.env.PUBLIC_URL + s.iconCooltimeImagePath}
                    alt={s.alterlationText}
                    style={{ display: !props.isCooling ? 'none' : 'inline' }}
                    css={[styles.stampImage, styles.stampSelected]}
                    onClick={() => sendStamp(s.id)}
                  />
                </div>
              </div>

              <p
                css={[
                  styles.emoteCaption,
                  props.isCooling && styles.emoteCaptionCooling,
                ]}
              >
                {s.discription}
              </p>
            </div>
          );
        })}
      </div>
    );
  };
  return (
    <div css={styles.emoteAreaContainer}>
      <p css={styles.emoteAreaTitle}>スタンプを送ってみよう!</p>

      <div className="EmoteButtons">
        <EmoteButtons />
      </div>
    </div>
  );

  function sendStamp(stampId: number) {
    if (!props.isCooling) {
      props.sendStamp(stampId);
    }
  }
};

export default StampArea;
