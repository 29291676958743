import { FeedbackService } from '../services/feedbackService';
import { useClient } from './useClient';
import { useQuery } from '@tanstack/react-query';
import { queryKey } from '../utils/QueryKey';
import { getCachedApiToken } from '../utils/TokenManager';
import { get } from 'underscore';

type State = {
  data?: any;
  error?: any;
};

export const useMyFeedback = (roomId: string): State => {
  const feedbackService = new FeedbackService({
    roomId,
  });

  const { data, error } = useQuery(
    [queryKey.myFeedback],
    async () => feedbackService.fetchRegisteredFeedback(),
    {
      enabled:
        roomId != undefined && roomId != '' && getCachedApiToken() !== null,
    }
  );

  return { data, error };
};
