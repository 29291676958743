import React from 'react';
import { css } from "@emotion/react";
import { TopPage } from "../component/web/TopPage";

const styles = {
  root: css({
    textAlign: "center",
    marginBottom: 60
  }),
  row: css({
    margin: '25px 10px',
    justifyContent: "center"
  }),
  tenantData: css({
    marginBottom: 20
  }),
  button: css({
    margin: '0 10px'
  })
}

export const AdminPage = ({}) => {
  return (
    <TopPage isTenantAdministrator={true} />
  )
};