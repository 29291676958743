import React from 'react';
import { useAllStarRatings } from './useAllStarRatings'; // added useAllStarRating

export const useAverageRating = (roomId: string) => {
  const { data: starRatings } = useAllStarRatings(roomId);

  return React.useMemo(() => {
    if (starRatings) {
      return (
        starRatings.reduce(
          (total, starRating) => total + starRating.starRating,
          0
        ) / starRatings.length
      );
    } else {
      return undefined;
    }
  }, [starRatings]);
};
