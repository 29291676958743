import {
  BatchRequestContent,
  BatchResponseContent,
  Client,
  PageIterator,
} from '@microsoft/microsoft-graph-client';
import { compare } from '../utils/Compare';

export class GraphService {
  constructor(private client: Client) {}

  async getKeys() {
    const values: any = [];
    const response = await this.client
      .api('/me/memberOf/microsoft.graph.group')
      .version('beta')
      .count(true)
      .select('id')
      .filter("resourceProvisioningOptions/any(x:x eq 'Team')")
      .orderby('displayName')
      .header('ConsistencyLevel', 'eventual')
      .get();
    const callback = (value: any) => Boolean(value.id && values.push(value.id));
    const iterator = new PageIterator(this.client, response, callback);
    await iterator.iterate();
    return values;
  }

  async getPersonal(userPrincipalName: string) {
    const query = '?$select=id,displayName,userType';
    try {
      const response = await this.client
        .api('/users/' + userPrincipalName + query)
        .get();
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async getTeams(keys: any) {
    const values = new Map();
    for (let chunk = 0; chunk < keys.length; chunk += 20) {
      const requestContent = new BatchRequestContent(
        keys.slice(chunk, chunk + 20).map((id: any) => ({
          id: `${id}`,
          request: new Request(
            `/teams/${id}` +
              '?$select=id,displayName,description,internalId,visibility,webUrl',
            {
              method: 'GET',
            }
          ),
        }))
      );
      const requestBody = await requestContent.getContent();
      const responseBody = await this.client
        .api('/$batch')
        .version('v1.0')
        .post(requestBody);
      const responseContent = new BatchResponseContent(responseBody);
      for (const [id, response] of responseContent.getResponses() as any) {
        if (response.ok) {
          const json = await response.json();
          const value = json;
          values.set(id, value);
        }
      }
    }
    return values;
  }

  async getTeamIcons(keys: any) {
    const values = new Map();
    for (let chunk = 0; chunk < keys.length; chunk += 20) {
      const requestContent = new BatchRequestContent(
        keys.slice(chunk, chunk + 20).map((id: any) => ({
          id: `${id}`,
          request: new Request(`/groups/${id}/photo/$value`, {
            method: 'GET',
          }),
        }))
      );
      const requestBody = await requestContent.getContent();
      const responseBody = await this.client
        .api('/$batch')
        .version('v1.0')
        .post(requestBody);
      const responseContent = new BatchResponseContent(responseBody);
      for (const [id, response] of Array.from(responseContent.getResponses())) {
        if (response.ok) {
          values.set(id, await response.text());
        } else {
          values.set(id, null);
        }
      }
    }
    return values;
  }

  async getChannels(keys: any) {
    return new Map(
      await Promise.all(
        keys.map(async (id: any) => {
          try {
            const response = await this.client
              .api(`/teams/${id}/channels`)
              .version('v1.0')
              .select(['id', 'displayName', 'webUrl', 'membershipType'])
              .get();
            const items: any = [];
            const callback = (value: any) => Boolean(items.push(value));
            const iterator = new PageIterator(this.client, response, callback);
            await iterator.iterate();
            return [
              id,
              items.sort((a: any, b: any) =>
                compare(a.displayName, b.displayName)
              ),
            ];
          } catch {
            return [id, []];
          }
        })
      )
    );
  }

  async getMembers(keys: any) {
    return new Map(
      await Promise.all(
        keys.map(async (id: any) => {
          try {
            const response = await this.client
              .api(`/teams/${id}/members`)
              .version('v1.0')
              .select([
                'id',
                'displayName',
                'microsoft.graph.aadUserConversationMember/userId',
                'microsoft.graph.aadUserConversationMember/email',
              ])
              .get();
            const items: any = [];
            const callback = (value: any) => Boolean(items.push(value));
            const iterator = new PageIterator(this.client, response, callback);
            await iterator.iterate();
            return [
              id,
              items.sort((a: any, b: any) =>
                compare(a.displayName, b.displayName)
              ),
            ];
          } catch {
            return [id, []];
          }
        })
      )
    );
  }

  async getMemberIcons(keys: any) {
    const values = new Map();
    for (let chunk = 0; chunk < keys.length; chunk += 20) {
      const requestContent = new BatchRequestContent(
        keys.slice(chunk, chunk + 20).map((id: any) => ({
          id: `${id}`,
          request: new Request(`/users/${id}/photo/$value`, {
            method: 'GET',
          }),
        }))
      );
      const requestBody = await requestContent.getContent();
      const responseBody = await this.client
        .api('/$batch')
        .version('v1.0')
        .post(requestBody);
      const responseContent = new BatchResponseContent(responseBody);
      for (const [id, response] of Array.from(responseContent.getResponses())) {
        if (response.ok) {
          values.set(id, await response.text());
        } else {
          values.set(id, null);
        }
      }
    }
    return values;
  }

  async getDrives(keys: any) {
    const values = new Map();
    for (let chunk = 0; chunk < keys.length; chunk += 20) {
      const requestContent = new BatchRequestContent(
        keys.slice(chunk, chunk + 20).map((id: any) => ({
          id: `${id}`,
          request: new Request(`/groups/${id}/drive` + '?$select=id,webUrl', {
            method: 'GET',
          }),
        }))
      );
      const requestBody = await requestContent.getContent();
      const responseBody = await this.client
        .api('/$batch')
        .version('v1.0')
        .post(requestBody);
      const responseContent = new BatchResponseContent(responseBody);
      for (const [id, response] of responseContent.getResponses() as any) {
        if (response.ok) {
          const json = await response.json();
          const value = json;
          values.set(id, value);
        }
      }
    }
    return values;
  }

  async getChat(chatId: string) {
    return this.client.api(`/chats/${chatId}`).get();
  }

  async getTenantId() {
    const res = await this.client.api('organization').select('id').get();
    return res?.value[0]?.id;
  }
}
