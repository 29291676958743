import React, { useEffect } from 'react';
import { useIsRepresentative } from "../../hooks/useIsRepresentative";
import { Outlet, useNavigate } from "react-router";

interface Props {
}

export const RepresentativeAuthLayout = ({}: Props) => {
  const { isRepresentative, isFetching } = useIsRepresentative();
  const navigate = useNavigate();

  useEffect(() => {
    if (isRepresentative || isRepresentative === undefined || isFetching) {
      return;
    }
    navigate('/unavailable');
  }, [isRepresentative]);

  return (<Outlet />)
};