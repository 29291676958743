import axios, { Axios } from 'axios';
import { getCachedApiToken } from '../utils/TokenManager';

export class ApiService {
  private readonly axios: Axios;
  private readonly endpointBase: string;

  constructor(apiToken: string = '') {
    const token = apiToken !== '' ? apiToken : getCachedApiToken();
    this.axios = axios.create({
      headers: { Authorization: `Bearer ${token}` },
    });
    this.endpointBase =
      process.env.REACT_APP_SIGNALR_SERVER_URL + '/api';
  }

  protected async get(endpoint: string): Promise<any> {
    const ep = this.endpointBase + '/' + endpoint;
    const response = await this.axios.get(ep).catch((error: any) => {
      throw error;
    });
    return response.data;
  }

  protected async delete(endpoint: string): Promise<any> {
    const ep = this.endpointBase + '/' + endpoint;
    const response = await this.axios.delete(ep).catch((error: any) => {
      throw error;
    });
    return response.data;
  }

  protected async post(endpoint: string, postData: any): Promise<any> {
    const ep = this.endpointBase + '/' + endpoint;
    const response = await this.axios.post(ep, postData).catch((error: any) => {
      throw error;
    });
    return response.data;
  }

  protected async put(endpoint: string, postData: any): Promise<any> {
    const ep = this.endpointBase + '/' + endpoint;
    const response = await this.axios.put(ep, postData).catch((error: any) => {
      throw error;
    });
    return response.data;
  }

}
