import * as microsoftTeams from '@microsoft/teams-js';
import { app } from '@microsoft/teams-js';
import { useState } from 'react';
import { useAsync } from 'react-use';

interface TeamsContextResult {
  context?: app.Context;
  teamsContextError?: any;
}

export const useTeamsContext = (): TeamsContextResult => {
  const [context, setContext] = useState<app.Context>();
  const [teamsContextError, setTeamsContextError] = useState<any>();

  const handleGetContext = (c: app.Context) => {
    setContext(c);
  };

  useAsync(async () => {
    try {
      const context = await app.getContext();
      handleGetContext(context);
    } catch (err) {
      setTeamsContextError(err);
    }
  }, []);

  return { context, teamsContextError };
};
