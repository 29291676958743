import React from 'react';
import * as yup from "yup"
import { setLocale } from "yup"
import { suggestive } from "yup-locale-ja"
import { Form as FormikForm, Formik } from "formik"
import { Alert, Col, Form, InputGroup, Row } from "react-bootstrap";
import { css, Theme, useTheme } from "@emotion/react";
import { SerializedStyles } from "@emotion/serialize";
import { useMsal } from "@azure/msal-react";
import { useTenantIdForBrowser } from "../../hooks/useTenantIdForBrowser";
import { SubmitButton } from "./SubmitButton";

setLocale(suggestive)

type Formvalues = {
  tenant: {
    tenantName: string,
    tenantId: string,
  },
  representative: {
    displayName: string,
    email: string,
    tenantId: string,
    userId: string,
  }
}
interface Props {
  onNext: (value: Formvalues) => Promise<void> | void;
  classes?: {
    root?: SerializedStyles,
    formWrapper?: SerializedStyles,
  }
};

const makeStyles = (theme: Theme) => ({
  root: css({
    textAlign: 'center'
  }),
  label: css({
    textAlign: 'right',
  }),
  input: css({
    maxWidth: 600,
  }),
  tenantId : css({
    textAlign: 'left',
  }),
  button: css({
    margin: '50px 0 30px 0',
    maxWidth: 320,
  }),
  formWrapper: css({
    backgroundColor: theme.colors.lightGray,
    padding: '20px 0',
  }),
})

const schema = yup.object().shape({
  tenant: yup.object().shape({
    tenantName: yup.string().required(),
  }),
  representative: yup.object().shape({
    displayName: yup.string().required(),
    email: yup.string().email().required(),
  })
})

type TextInputProps = {
  label: string,
  value: string,
  onChange: (e: any) => void,
  isInvalid: boolean | undefined,
  onBlur: (e: any) => void,
  name: string,
  error: string | undefined,
  classes?: {
    label: SerializedStyles,
    input: SerializedStyles
  }
}

const TextInput = ({label, value, onChange, isInvalid, onBlur, name, error, classes}: TextInputProps) => (
  <Form.Group as={Row}>
    <Col css={classes?.label} sm={4}>{label}</Col>
    <Col css={classes?.input}>
      <InputGroup hasValidation>
        <Form.Control
          type="text"
          name={name}
          value={value}
          onChange={onChange}
          isInvalid={isInvalid}
          onBlur={onBlur}
        />
        <Form.Control.Feedback type="invalid">
          {error}
        </Form.Control.Feedback>
      </InputGroup>
    </Col>
  </Form.Group>
)

export const RepresentativeAndTenantFormStep = ({onNext, classes}: Props) => {
  const [ tenantId ] = useTenantIdForBrowser();
  const theme = useTheme();
  const [formError, setFormError] = React.useState<string | undefined>(undefined);

  const { accounts } = useMsal();
  const styles = makeStyles(theme);
  return (
    <div css={[styles.root, classes?.root]}>
      <Formik
        enableReinitialize
        initialValues={{
          tenant: {
            tenantId: tenantId ?? '',
            tenantName: ''
          },
          representative: {
            tenantId: tenantId ?? '',
            userId: accounts[0]?.localAccountId ?? '',
            displayName: '',
            email: '',
          }
        }}
        validationSchema={schema}
        onSubmit={async (data) => {
          await onNext(data)
        }}
      >
        {
          ({
             values,
             handleChange,
             errors,
             handleBlur,
             touched,
             isSubmitting
           }) => (
            <FormikForm>
              {formError && (
                <Alert variant="danger">
                  {formError}
                </Alert>
              )}
              <div css={[styles.formWrapper, classes?.formWrapper]}>
                <Form.Group as={Row}>
                  <Col css={styles?.label} sm={4}>テナントID</Col>
                  <Col css={[styles?.input, styles.tenantId]}>
                    {tenantId}
                  </Col>
                </Form.Group>
                <TextInput
                  label="会社名"
                  name="tenant.tenantName"
                  value={values.tenant.tenantName}
                  onChange={handleChange}
                  error={errors.tenant?.tenantName}
                  onBlur={handleBlur}
                  classes={styles}
                  isInvalid={!!errors.tenant?.tenantName && touched.tenant?.tenantName}
                />
                <TextInput
                  label="ご担当者名"
                  name="representative.displayName"
                  value={values.representative.displayName}
                  onChange={handleChange}
                  error={errors.representative?.displayName}
                  onBlur={handleBlur}
                  classes={styles}
                  isInvalid={!!errors.representative?.displayName && touched.representative?.displayName}
                />
                <TextInput
                  label="ご担当者メールアドレス"
                  name="representative.email"
                  value={values.representative.email}
                  onChange={handleChange}
                  error={errors.representative?.email}
                  onBlur={handleBlur}
                  classes={styles}
                  isInvalid={!!errors.representative?.email && touched.representative?.email}
                />
              </div>
              <SubmitButton css={styles.button} type="submit" disabled={isSubmitting}>次へ</SubmitButton>
            </FormikForm>
          )
        }
      </Formik>
    </div>
  )
};