import React from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig';
import { PageTitle } from './PageTitle';
import { css, Theme, useTheme } from '@emotion/react';
import { SubmitButton } from "./web/SubmitButton";

interface Props {
  usePopup: boolean;
}

const makeStyles = (theme: Theme) => ({
  root: css({
    textAlign: 'center',
  }),
  button: css({
    background: 'transparent',
    fontSize: 28,
    borderColor: theme.colors.webPrimary,
    borderRadius: 30,
    border: '1px solid',
    padding: '20px 15px',
    width: 320,
    '&:hover': {
      background: 'transparent',
      borderColor: theme.colors.black,
    },
    '&:disabled, &:hover:disabled': {
      background: theme.colors.buttonLinkDisabledBackground,
      borderColor: theme.colors.darkGray,
    },
  }),
  buttonWrapper: css({
    width: '100%',
    textAlign: 'center',
  }),
  message: css({
    fontSize: 24,
    textAlign: 'center',
    marginBottom: 50,
  })
});

const LoginAnnounce = (props: Props) => {
  const { instance } = useMsal();
  const theme = useTheme();
  const styles = makeStyles(theme);

  const handleLogin = () => {
    if (!props.usePopup) {
      instance.loginRedirect();
    } else {
      instance.loginPopup(loginRequest as any).then((response) => {
        instance.setActiveAccount(response.account);
      });
    }
  };

  return (
    <div className="TopPageContainer">
      <PageTitle withBackground={false}>ワカチエにようこそ!</PageTitle>
      <p css={styles.message}>
        ワカチエのセットアップや利用状況の管理を行うことが出来ます。<br/>
        所属組織のMicrosoftアカウントを用いてログインしてください。
      </p>
      <div css={styles.buttonWrapper}>
        <SubmitButton onClick={handleLogin}>
          ログイン
        </SubmitButton>
      </div>
      {process.env.REACT_APP_ENVIROMENT === 'PRESENTATION' && (
        <div className="TopPageToolchip">
          <div className="TopPageToolchipText">
            ワカチエを使用するには、Microsoftアカウントが必要です。
          </div>
          <div className="TopPageToolchipDescription">
            ワカチエで使用可能なMicrosoftアカウントは、職場で使用するMicrosoftアカウント、学校で使用するアカウント及び個人用のMicrosoftアカウントです。
          </div>
        </div>
      )}
    </div>
  );
};

export default LoginAnnounce;
