import { ApiService } from "./ApiService";

export type CreateRepresentativeData = {
  displayName: string;
  email: string;
  token?: string;
}

export type EditRepresentativeData = {
  id: number
  displayName: string;
  email: string;
}

export type FetchMeData = {
  id: number;
  displayName: string;
  email: string;
}

export class RepresentativeService extends ApiService {
  public fetchAllRepresentative(): Promise<any> {
    const endpoint = `representative/`;
    return this.get(endpoint);
  }

  public createRepresentative(data: CreateRepresentativeData): Promise<any> {
    const endpoint = 'representative/'
    return this.post(endpoint, data);
  }

  public editRepresentative(data: EditRepresentativeData): Promise<any> {
    const endpoint = 'representative/'+ data.id;
    return this.put(endpoint, data);
  }

  public deleteRepresentative(id: number): Promise<any> {
    const endpoint = 'representative/'+ id;
    return this.delete(endpoint);
  }

  public fetchMe(): Promise<FetchMeData> {
    const endpoint = 'representative/me';
    return this.get(endpoint);
  }
}
