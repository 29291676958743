import React, { useEffect, useState } from 'react';
import { MeetingModal } from './MeetingModal';
import { css, Theme, useTheme } from '@emotion/react';
import { AppButton } from './AppButton';

interface Props {
  onSubmit?: () => void;
  onClose?: () => void;
  isOpen?: boolean;
}

const makeStyles = (theme: Theme) => ({
  ratingStarWrapper: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    p: {
      paddingTop: 10,
      fontWeight: 'bold',
      display: 'inline-block',
      marginTop: 'revert',
      color: theme.colors.darkGray,
    },
  }),
  ratingStars: css({
    display: 'block',
    margin: 10,
  }),
  feedbackFormWrapper: css({
    marginRight: 0,
    marginLeft: 0,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    '& > div': css({
      marginTop: 16,
    }),
  }),
  feedbackInputWrapper: css({
    textAlign: 'center',
  }),
  roomNameLabel: css({
    marginBottom: 0,
    width: '100%',
  }),
  roomNameInput: css({
    height: 36,
    background: '#ffffff 0% 0% no-repeat padding-box;',
    border: '1px solid #afafaf',
    borderRadius: 6,
    opacity: 1,
    width: '100%',

    '@media (min-width: 600px)': css({
      width: 352,
    }),
  }),
  feedbackSendButton: css({
    width: 126,
    marginBottom: 0,
    marginLeft: 10,
    marginTop: 10,
  }),
});

export const LOCAL_STORAGE_KEY = 'wakachieDisplayName';

export const DisplayNameModal = ({
  onSubmit,
  onClose,
  isOpen = false,
}: Props) => {
  const [displayName, setDisplayName] = useState<string>('');
  const theme = useTheme();
  const styles = makeStyles(theme);

  useEffect(() => {
    const storedName = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (storedName) {
      setDisplayName(storedName);
    }
  }, []);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    localStorage.setItem(LOCAL_STORAGE_KEY, displayName);
    onSubmit && onSubmit();
  };

  return (
    <MeetingModal onClose={onClose} isOpen={isOpen}>
      <div css={styles.feedbackFormWrapper}>
        <form
          onSubmit={(e) => handleSubmit(e)}
          css={styles.feedbackInputWrapper}
        >
          <label css={styles.roomNameLabel}>
            お名前を入力してください。
            <input
              type="text"
              value={displayName}
              onChange={(e) => setDisplayName(e.target.value)}
              required
              css={styles.roomNameInput}
            ></input>
          </label>
          <AppButton
            color={'orange'}
            type={'submit'}
            css={styles.feedbackSendButton}
          >
            保存して入室
          </AppButton>
        </form>
      </div>
    </MeetingModal>
  );
};
