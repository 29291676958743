import { useMutation, useQueryClient } from "@tanstack/react-query";
import { queryKey } from "../utils/QueryKey";
import { EditTenantAdministratorData, TenantAdministratorService } from "../services/TenantAdministratorService";
import { useApiTokenForBrowser } from "./browser/useApiTokenForBrowser";

export const useEditTenantAdministratorMutation = () => {
  const { token } = useApiTokenForBrowser()
  const tenantAdministratorService = new TenantAdministratorService(token)
  const queryClient = useQueryClient();
  return  useMutation(async (data: EditTenantAdministratorData) => {
      return  tenantAdministratorService.editTenantAdministrator(data);
    }, {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKey.tenantAdministrator])
      }}
  );
}